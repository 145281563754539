
export enum ReportTypeEnum {
  assemplyUsageReport = 'Assemply Usage Report',
  topSpendItem = 'Top Spend Item and Show Risks',
  savingByBU = 'Saving By BU',
  savingByBUConsideringBaseLine = 'Saving By BU Considering Base Line',
  internalCrossCostComparison = 'Internal Cross Cost Comparison',
  historicalData = 'Historical data by Assembly Level',
  tariffImpact = 'Tariff Impact',
  BestPrice = 'Best Price',
  BestLT = 'Best LT',
  SupplierScore = 'Supplier Score',
  UsersScore = 'Users Score',
  BestPriceSupExchRate = 'Export Best Price report with supplier exchange rate',
  BestPriceCMTExchRate = "Export Best Price report with CMT exchange rate"
}
export enum ReportEnum {
  cmt = 'cmt',
  rfq = 'rfq',

}
export interface IReport {
  reportName: string;
  reportId: number;
}
export class CMTReports {
  report: IReport[] = [
    {
      reportName: ReportTypeEnum.assemplyUsageReport,
      reportId: 7
    },
    {
      reportName: ReportTypeEnum.topSpendItem,
      reportId: 4
    },
    {
      reportName: ReportTypeEnum.savingByBU,
      reportId: 2
    },
    {
      reportName: ReportTypeEnum.savingByBUConsideringBaseLine,
      reportId: 1
    },
    {
      reportName: ReportTypeEnum.internalCrossCostComparison,
      reportId: 5
    },
    {
      reportName: ReportTypeEnum.historicalData,
      reportId: 6
    },
    {
      reportName: ReportTypeEnum.tariffImpact,
      reportId: 3
    },
  ];
}
export class RFQReports {
  report: any[] = [

    {
      reportName: ReportTypeEnum.BestPrice,
      items: [
        {
          reportName: ReportTypeEnum.BestPriceSupExchRate,
          reportId: 11
        },
        {
          reportName: ReportTypeEnum.BestPriceCMTExchRate,
          reportId: 15
        },

      ]
    },
    {
      reportName: ReportTypeEnum.BestLT,
      reportId: 12
    },
    {
      reportName: ReportTypeEnum.SupplierScore,
      reportId: 13
    },
    {
      reportName: ReportTypeEnum.UsersScore,
      reportId: 14
    },

  ];
}
export class RFQReportObj {
  FileName: string;
  ReportTypeID: number;
  YearId: number;
}
export class PivoteReportObj{
  Column:string;
  Row:string;
  Filter:string;
  AggregateColumnDataType:string;
  AggregateColumn:string;
  Aggregate:string;
  TableName:string;
   YearId:number;
}
export class DataObj{
  ColName:string;
  Value:string;
}
export var allReports: string[] = [
  ReportTypeEnum.assemplyUsageReport,
  ReportTypeEnum.topSpendItem,
  ReportTypeEnum.savingByBU,
  ReportTypeEnum.savingByBUConsideringBaseLine,
  ReportTypeEnum.internalCrossCostComparison,
  ReportTypeEnum.historicalData,
  ReportTypeEnum.tariffImpact,
  ReportTypeEnum.BestPrice,
  ReportTypeEnum.BestLT,
  ReportTypeEnum.SupplierScore,
  ReportTypeEnum.UsersScore,

];

export interface ISource {
  source: string;
  sourceId: number;
}

export interface SourceColumn {
  id: number;
  displayName: string;
  dataType: string;
}
export interface IExportPivoteTable {
  pivoteColumnsDTO: PivotData;
  FileName: string;
}
export interface PivotData {
  columnId: number;
  rowId: number;
  yearId: number;
  filterId: number;
  filterValue: string;
  aggregateColumnId: number;
  aggregate: string;
}

export interface IGraphData{
  column: string;
  value: number;
  row: string;
}

export interface InputGraphData {
  headers: any[];
  data: any[];
}
