 
       import { SortDescriptor } from '@progress/kendo-data-query';
      import * as _ from 'lodash';
import { ICMsites, ICMsitesGrid } from './cm-interface';
      
      export function flatCMSiteData(data: ICMsites[]): ICMsitesGrid[] {
        let flattedData: ICMsitesGrid[] = [];
        data?.forEach((element) => {
          var elem: ICMsitesGrid = {
            contractManufacturerId: element.contractManufacturer.contractManufacturerId,
            cmSiteName: element.cmSiteName,
            contractManufacturerName:element.contractManufacturer.contractManufacturerName,
            address:element.address,
            contactName:element.contactName,
            email:element.email,
            contactTitle:element.contactTitle,
            status:element.status,
            country:element.country
          };
          
          flattedData.push(elem);
        });
        return flattedData;
      }