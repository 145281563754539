<form [formGroup]="registeredUserForm">

<div class="form-group row">
  <label class="col-3 col-form-label"
    >First Name <span class="astric">*</span></label
  >
  <kendo-textbox
    class="col-9"
    placeholder="FirstName"
    name="firstName"
    [(ngModel)]="firstName"
    formControlName="firstName"
    required
  >
  </kendo-textbox>
  <app-error-message message="First Name is required" *ngIf="registeredUserForm.touched && registeredUserForm.controls.firstName.errors?.required"  [isError]="true"></app-error-message>

  <app-error-message message="Max length is 128 chars"
  *ngIf="!registeredUserForm?.controls?.firstName?.errors?.required && registeredUserForm?.controls?.firstName?.errors?.maxlength"
  [isError]="true"></app-error-message>
<app-error-message message="Min length is 1 char"
  *ngIf="!registeredUserForm?.controls?.firstName?.errors?.required && registeredUserForm?.controls?.firstName?.errors?.minlength"
  [isError]="true"></app-error-message>
<app-error-message message="First Name can't contain space only"
  *ngIf="!registeredUserForm?.controls?.firstName?.errors?.required && registeredUserForm?.controls?.firstName?.errors?.cannotContainSpaceOnly"
  [isError]="true"></app-error-message>
<app-error-message message="First Name can't start with white space"
  *ngIf="!registeredUserForm?.controls?.firstName?.errors?.required && registeredUserForm?.controls?.firstName?.errors?.cannotStartWithWhiteSpace"
  [isError]="true"></app-error-message>
<app-error-message message="First Name can't end with white space"
  *ngIf="!registeredUserForm?.controls?.firstName?.errors?.required && registeredUserForm?.controls?.firstName?.errors?.cannotEndWithWhiteSpace"
  [isError]="true"></app-error-message>
<app-error-message message="First Name can't be numbers only"
  *ngIf="!registeredUserForm?.controls?.firstName?.errors?.required && registeredUserForm?.controls?.firstName?.errors?.cannotBeNumbersOnly"
  [isError]="true"></app-error-message>
<app-error-message message="First Name can't be special chars only"
  *ngIf="!registeredUserForm?.controls?.firstName?.errors?.required && registeredUserForm?.controls?.firstName?.errors?.cannotBeSpecialCharsOnly"
  [isError]="true"></app-error-message>
</div>
<div class="form-group row">
  <label class="col-3 col-form-label"
    >Last Name <span class="astric">*</span></label
  >
  <kendo-textbox
    class="col-9"
    placeholder="LastName"
    name="lastName"
    [(ngModel)]="lastName"
    formControlName="lastName"
    required
  >
  </kendo-textbox>
  <app-error-message message="Last Name is required" *ngIf="registeredUserForm.touched && registeredUserForm.controls.firstName.errors?.required"  [isError]="true"></app-error-message>

  <app-error-message message="Max length is 128 chars"
      *ngIf="!registeredUserForm?.controls?.lastName?.errors?.required && registeredUserForm?.controls?.lastName?.errors?.maxlength"
      [isError]="true"></app-error-message>
    <app-error-message message="Min length is 1 char"
      *ngIf="!registeredUserForm?.controls?.lastName?.errors?.required && registeredUserForm?.controls?.lastName?.errors?.minlength"
      [isError]="true"></app-error-message>
    <app-error-message message="Last Name can't contain space only"
      *ngIf="!registeredUserForm?.controls?.lastName?.errors?.required && registeredUserForm?.controls?.lastName?.errors?.cannotContainSpaceOnly"
      [isError]="true"></app-error-message>
    <app-error-message message="Last Name can't start with white space"
      *ngIf="!registeredUserForm?.controls?.lastName?.errors?.required && registeredUserForm?.controls?.lastName?.errors?.cannotStartWithWhiteSpace"
      [isError]="true"></app-error-message>
    <app-error-message message="Last Name can't end with white space"
      *ngIf="!registeredUserForm?.controls?.lastName?.errors?.required && registeredUserForm?.controls?.lastName?.errors?.cannotEndWithWhiteSpace"
      [isError]="true"></app-error-message>
    <app-error-message message="Last Name can't be numbers only"
      *ngIf="!registeredUserForm?.controls?.lastName?.errors?.required && registeredUserForm?.controls?.lastName?.errors?.cannotBeNumbersOnly"
      [isError]="true"></app-error-message>
    <app-error-message message="Last Name can't be special chars only"
      *ngIf="!registeredUserForm?.controls?.lastName?.errors?.required && registeredUserForm?.controls?.lastName?.errors?.cannotBeSpecialCharsOnly"
      [isError]="true"></app-error-message>
</div>
<div class="form-group row">
  <label class="col-3 col-form-label"
    >ُEmail <span class="astric">*</span></label
  >
  <kendo-textbox
    class="col-9"
    placeholder="Email"
    name="email"
    [(ngModel)]="email"
    formControlName="email"
    required
  >
  </kendo-textbox>
  <app-error-message
    [message]="emailError"
    [isError]="true"
  ></app-error-message>
</div>

<div class="form-group row">
  <label class="col-3 col-form-label"
    >Phone <span class="astric">*</span></label
  >
  <kendo-textbox
    class="col-9"
    placeholder="Phone"
    name="phone"
    [(ngModel)]="phone"
    formControlName="phone"

    required
  >
  </kendo-textbox>
  <label class="col-3 col-form-label"></label>
  <label for="phone" class="col-9 col-form-label">the phone pattern is '+000 - 0000000000'</label>
  <app-error-message
    [message]="phoneError"
    [isError]="true"
  ></app-error-message>
</div>
<div class="form-group row">
  <label class="col-3 col-form-label"
    >Region <span class="astric">*</span></label
  >
  <kendo-textbox
    class="col-9"
    placeholder="Region"
    name="region"
    [(ngModel)]="region"
    formControlName="region" 
    required
  >
  </kendo-textbox>
  <app-error-message message="Region is required" *ngIf="registeredUserForm.touched && registeredUserForm.controls.firstName.errors?.required"  [isError]="true"></app-error-message>
  <app-error-message message="Max length is 128 chars"
  *ngIf="!registeredUserForm?.controls?.region?.errors?.required && registeredUserForm?.controls?.region?.errors?.maxlength"
  [isError]="true"></app-error-message>
<app-error-message message="Min length is 1 char"
  *ngIf="!registeredUserForm?.controls?.region?.errors?.required && registeredUserForm?.controls?.region?.errors?.minlength"
  [isError]="true"></app-error-message>
<app-error-message message="Region Name can't contain space only"
  *ngIf="!registeredUserForm?.controls?.region?.errors?.required && registeredUserForm?.controls?.region?.errors?.cannotContainSpaceOnly"
  [isError]="true"></app-error-message>
<app-error-message message="Region Name can't start with white space"
  *ngIf="!registeredUserForm?.controls?.region?.errors?.required && registeredUserForm?.controls?.region?.errors?.cannotStartWithWhiteSpace"
  [isError]="true"></app-error-message>
<app-error-message message="Region Name can't end with white space"
  *ngIf="!registeredUserForm?.controls?.region?.errors?.required && registeredUserForm?.controls?.region?.errors?.cannotEndWithWhiteSpace"
  [isError]="true"></app-error-message>
<app-error-message message="Region Name can't be numbers only"
  *ngIf="!registeredUserForm?.controls?.region?.errors?.required && registeredUserForm?.controls?.region?.errors?.cannotBeNumbersOnly"
  [isError]="true"></app-error-message>
<app-error-message message="Region Name can't be special chars only"
  *ngIf="!registeredUserForm?.controls?.region?.errors?.required && registeredUserForm?.controls?.region?.errors?.cannotBeSpecialCharsOnly"
  [isError]="true"></app-error-message>
</div>
<div class="form-group row">
  <label class="col-3 col-form-label"
    >Title <span class="astric">*</span></label
  >
  <kendo-textbox
    class="col-9"
    placeholder="Title"
    name="title"
    [(ngModel)]="title"
    formControlName="title" 
    required
  >
  </kendo-textbox>
  <app-error-message message="Title is required" *ngIf="registeredUserForm.touched && registeredUserForm.controls.firstName.errors?.required"  [isError]="true"></app-error-message>
  <app-error-message message="Max length is 128 chars"
  *ngIf="!registeredUserForm?.controls?.title?.errors?.required && registeredUserForm?.controls?.title?.errors?.maxlength"
  [isError]="true"></app-error-message>
<app-error-message message="Min length is 1 char"
  *ngIf="!registeredUserForm?.controls?.title?.errors?.required && registeredUserForm?.controls?.title?.errors?.minlength"
  [isError]="true"></app-error-message>
<app-error-message message="Title can't contain space only"
  *ngIf="!registeredUserForm?.controls?.title?.errors?.required && registeredUserForm?.controls?.title?.errors?.cannotContainSpaceOnly"
  [isError]="true"></app-error-message>
<app-error-message message="Title can't start with white space"
  *ngIf="!registeredUserForm?.controls?.title?.errors?.required && registeredUserForm?.controls?.title?.errors?.cannotStartWithWhiteSpace"
  [isError]="true"></app-error-message>
<app-error-message message="Title can't end with white space"
  *ngIf="!registeredUserForm?.controls?.title?.errors?.required && registeredUserForm?.controls?.title?.errors?.cannotEndWithWhiteSpace"
  [isError]="true"></app-error-message>
<app-error-message message="Title can't be numbers only"
  *ngIf="!registeredUserForm?.controls?.title?.errors?.required && registeredUserForm?.controls?.title?.errors?.cannotBeNumbersOnly"
  [isError]="true"></app-error-message>
<app-error-message message="Title can't be special chars only"
  *ngIf="!registeredUserForm?.controls?.title?.errors?.required && registeredUserForm?.controls?.title?.errors?.cannotBeSpecialCharsOnly"
  [isError]="true"></app-error-message>
</div>

<div class="form-group row">
  <label class="col-3 col-form-label"
    >Company Type<span class="astric">*</span></label
  >
  <app-dropdown-list
    class="col-9"
    [data]="CompanyTypes"
    textField="companyTypeName"
    valueField="companyTypeId"
    [valuePrimitive]="true"
    (selectionChange)="getSelectedCompanyTypeItem($event)"
    [selectedItem]="companyId"
  >
  </app-dropdown-list>
  <app-error-message
    [message]="companyTypeError"
    [isError]="true"
  ></app-error-message>
</div>
<div class="form-group row">
  <label class="col-3 col-form-label"
    >Company <span class="astric">*</span></label
  >
  <kendo-dropdownlist  defaultItem="Select Value"  class="col-9"  [filterable]="true" [data]="CompanyList" textField="name" valueField="id"
  formControlName="companyName"  (selectionChange)="companyChange($event)" (filterChange)="click($event)"> 
</kendo-dropdownlist> 
<app-error-message
[message]="companyError"
[isError]="true"
></app-error-message> 
</div>
<div class="form-group row">
  <label class="col-3 col-form-label">CM Site<span class="astric">*</span></label>
  <kendo-dropdownlist  [disabled]="CmSiteDisabled" [defaultItem]="defaultcmSite"  class="col-9"  [filterable]="true" [data]="cmSiteListItems" textField="cmSiteName" valueField="cmSiteId"
   formControlName="cmSite" (filterChange)="handleFilterCMsite($event)">
</kendo-dropdownlist>
  <app-error-message [message]="cmSiteError" [isError]="true"></app-error-message>
</div>

<div class="form-group row">
  <label class="col-3 col-form-label">Role<span class="astric">*</span></label>
  <app-dropdown-list
    class="col-9"
    [data]="RolelistItems"
    textField="roleName"
    valueField="roleId"
    [valuePrimitive]="true"
    (selectionChange)="getSelectedRole($event)"
    [selectedItem]="role"
  >
  </app-dropdown-list>
  <app-error-message [message]="roleError" [isError]="true"></app-error-message>
</div>
<div class="form-group row">
  <label class="col-3 col-form-label"
    >Login Status<span class="astric">*</span></label
  >
  <!-- <input type="radio" name="name" [(ngModel)]="loginStatus" value="Enabled"
  kendoRadioButton />
 Enabled

 <input type="radio" name="name"  [(ngModel)]="loginStatus" value="Disabled"
 kendoRadioButton />
Disabled -->
  <app-check-box
     [text]="'Enabled'"
      [value]="checkedEnabledvalue"
      [name]="'loginStatus'"
      (change)="getCheckedValue($event)"
      [checkedStatus]="checkedStatusEnable"
     ></app-check-box>
 
  <app-check-box
    [checkedStatus]="checkedStatusDisable"
     [text]="'Disabled'"
     [value]="checkedDisabledvalue"
     [name]="'loginStatus'"  
     (change)="getCheckedValue($event)"

   ></app-check-box>
 

</div>
<app-error-message
  message="{{ error }}"
  *ngIf="this.IsError"
  [isError]="true"
></app-error-message>
</form>