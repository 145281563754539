import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  ActionTypes,
  PartExportGrid,
  PartExportGridError,
  PartExportGridSucees,
  PartLoadData,
  PartLoadDataError,
  PartLoadDataSuccess,
} from './acl.action';
import { PartSearchService } from 'src/app/core/parts/part-search/part-search.service';
import {
  BuisnessUnitLoadDataError,
  BuisnessUnitLoadDataSucees,
} from '../../admin/stores/admin.action';
import { ServiceMiddleware } from 'src/app/core/Admin/service.layer/api.services.middleware';
import { partMapFilter, partMapSort } from 'src/app/core/Acl/Parts/part.mapper';
import { HttpErrorResponse } from '@angular/common/http';
import { gridRequest } from 'src/app/core/grid.request';

@Injectable({
  providedIn: 'root',
})
export class AclEffects {
  APIURL: string;
  constructor(
    private actions$: Actions,
    private serviceMiddleware: ServiceMiddleware,
    private partSearchService: PartSearchService
  ) {}

  /*-------------------------------------------------------Part Search-----------------------------------------------------------------------*/
  getPartGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.PART_LOAD_DATA),
      switchMap((action: PartLoadData) => { 
        this.APIURL = '/cmt/acl/page';
        const request = gridRequest(
          action?.page,
          action?.size,
          partMapSort(action?.sort),
          partMapFilter(action?.filter,action.selectedFilter)
        );
        return this.serviceMiddleware.getList(this.APIURL, request).pipe(
          map((dataResponse: any) => new PartLoadDataSuccess(dataResponse)),
          catchError((errorResponse: HttpErrorResponse) =>
            of(new PartLoadDataError(errorResponse))
          )
        );
      })
    );
  });

  ExportPartGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.PART_EXPORT_DATA),
      switchMap((action: PartExportGrid) => {
        if(action.filter==undefined){
        this.APIURL = '/cmt/acl/export';
        return this.serviceMiddleware
          .exportList(this.APIURL)
          .pipe(
            map((data: any) => new PartExportGridSucees(data, action.fileName)),
            catchError((errorResponse: any) =>
              of(new PartExportGridError(errorResponse))
            )
          );
        }
        else{
          this.APIURL = '/cmt/acl/exportFilteredResult';
        
        const request = gridRequest(
          action?.page,
          action?.size,
          partMapSort(action?.sort),
          partMapFilter(action?.filter)
        );
        return this.serviceMiddleware
          .exportFilteredList(this.APIURL, request)
          .pipe(
            map((data: any) => new PartExportGridSucees(data, action.fileName)),
            catchError((errorResponse: any) =>
              of(new PartExportGridError(errorResponse))
            )
          );
        }
      })
    );
  });

  BULoadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.BU_LOAD_DATA),
      switchMap(() => {
        return this.partSearchService.exportList().pipe(
          map((data: any) => new BuisnessUnitLoadDataSucees(data)),
          catchError((errorResponse: any) =>
            of(new BuisnessUnitLoadDataError(errorResponse))
          )
        );
      })
    );
  });
}
