import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PopoverComponent, Position } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopoverContainerComponent {
  constructor() {}
  @ViewChild('popover') popover: PopoverComponent;
  @Input() description = '';
  @Input() callOut = false;
  @Input() width = 'auto';
  @Input() height = 'auto';
  @Input() position: Position = 'left';
  @Input() htmlData: any;
  @Input() showdialog: boolean = false;
  @Input() dropdownData: any;
  @Input() dropdownPlaceholder: any;
  @Input() field: any;
  @Input() fieldId: any;
  @Input() openedComponent: any;

  @Output() show_popOver = new EventEmitter<any>();
  @Output() saveFilter = new EventEmitter<any>();
  @Output() shown = new EventEmitter<any>();
  @Output() hide = new EventEmitter<any>();
  @Output() hidden = new EventEmitter<any>();
  //@Output() clickButtonEvent = new EventEmitter<any>();

  public onShow() {
    this.show_popOver.emit();
  }
  public clickButtonEvent() {}
  public onSaveFilter() {
    this.saveFilter.emit();
  }
  public onShown() {
    this.shown.emit();
  }

  public onHide() {
    this.hide.emit();
  }

  public onHidden() {
    this.hidden.emit();
  }
}
