import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { MappedDataRequest } from './upload.request';
import { IAppState, IGridResponse } from 'src/app/shared/shared.interface';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { currentActionSelector } from 'src/app/features/upload/stores/upload.selector';
import { ServiceMiddleware } from './service.middleware/api.services.middleware';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private fileId: any;

  constructor(
    private apiService: ApiService,
    private _http: HttpClient,
    private serviceMiddleware: ServiceMiddleware,
    private store: Store<IAppState>
  ) {
    this.store.select(currentActionSelector).subscribe((data) => {
      this.fileId = data.fileId;
    });
  }

  public exportNormalizedData(ticketId?: any, option?: any) {
    return this.apiService.getBlob(
      `${environment.baseUrls.serviceURL}/cmt/CmtProcessing/ticket/export/${ticketId}/${option}`
    );
  }

  public sendMappedColumnsData(mappedColumns: any, start: any) {
    const request = MappedDataRequest(mappedColumns, start, this.fileId);
    return this.apiService.post<IGridResponse<any>>(
      `${environment.baseUrls.serviceURL}/cmt/CmtProcessing/ticket/normalize`,
      request
    );
  }

  public submitTicketFile(APIURL: string, ticketId: number) {
    if (APIURL !== '') {
      return this.apiService.put(
        environment.baseUrls.serviceURL+APIURL
      ,null,true);
     }
    else {
      return this.apiService.get<any>(
        `${environment.baseUrls.serviceURL}/cmt/ticket/submitTicketWithNotification/${ticketId}/${this.fileId}`
      );
    }
  }

  public closeTicketFile(ticketId: number) {
    return this.apiService.put<any>(
      `${environment.baseUrls.serviceURL}/cmt/ticket/closeTicket/${ticketId}`,
      null
    );
  }
}
