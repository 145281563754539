import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUser, IUserState } from '../user.interface';

export const getUserState = createFeatureSelector<IUserState>('user');

export const getUser = createSelector(
  getUserState,
  (userState: IUserState) => (userState && userState.profile) || undefined
);
export const getUserName = createSelector(
  getUserState,
  (userState: IUserState) =>
    userState.profile ? userState.profile.personName : ''
);
export const ForgetPasswordSelector = createSelector(
  getUserState,
  (userState: IUserState) => userState?.ForgetPassword
);
export const UserRolesSelector = createSelector(
  getUserState,
  (userState: IUserState) => userState?.UserRoles
);
export const resetPasswordSelector = createSelector(
  getUserState,
  (userState: IUserState) => userState?.ResetPassword
);
export const getUserEmailSelector = createSelector(
  getUserState,
  (userState: IUserState) => userState?.getUserEmailByCode
);
export const getRedirectUrl = createSelector(
  getUserState,
  (userState: IUserState) =>
    userState && userState.redirectUrl ? userState.redirectUrl : ''
);
