import { GridHeaderData } from 'src/app/shared/shared.interface';

export const headers: GridHeaderData[] = [
];

export function getCbomGridHearders(
  dynamicColumns: [] = []
): GridHeaderData[] {
  return [
    ...dynamicColumns?.map((c: any) => {
      return {
        field: c.colAlias,
        title: c.partsFeatureName,
        filterable: c.isFiltered,
        sortable: true,
      };
    }),
  ];
}