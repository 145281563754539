import { ApiService } from '../../api/api.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CbomResponse } from './cbom.interfaces';

@Injectable({
  providedIn: 'root',
})
export class CbomService {

  constructor(private apiService: ApiService) {
  }

  public getCbomList(yearId?:number, manId?: number, page: number = 0, size?: number) {
    return this.apiService.get<CbomResponse>(
      `${environment.baseUrls.serviceURL}/cmt/cbomParts/report/${yearId}/cm/${manId}`);
  }

}
