export enum Dialog {
  submit = 'Submit',
  close = 'Close',
  open = 'Open',
  approve = 'Approve',
  reject = 'Reject',
  hasNotSubmitButton = 'hasSubmitButton',
  hasSubmitBtn = 'hasSubmitBtn',
  send= 'Send'
}


