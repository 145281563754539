import { actionMenu, GridHeaderData } from 'src/app/shared/shared.interface';

export const AclHeaders: GridHeaderData[] = [
  
  {
    field: 'cpn',
    title: 'CPN',
    filterable: true,
    filterColName:'cpn',
    hidden:false
  },
  {
    field: 'businessUnit',
    title: 'Business Unit',
    filterColName:'businessUnit',
    filterable: false,
    hidden:false

  },
  {
    field: 'mpn',
    title: 'MPN',
    filterColName:'mpn',
    filterable: true,
    hidden:false

  },
  {
    field: 'man',
    title: 'Manufacturer',
    filterColName:'man',
    filterable: true,
    hidden:false

  },
  {
    field: 'wabtecDescription',
    filterColName:'wabtecDescription',
    title: 'Description',
    filterable: true,
    hidden:false

  },
  {
    field: 'seDescription',
    title: 'SiliconExpert Description',
    filterColName:'seDescription',
    filterable: true,
    hidden:false

  },
  {
    field: 'seMpn',
    title: 'SiliconExpert MPN',
    filterColName:'seMpn',
    filterable: true,
    hidden:false

  },
  {
    field: 'seMan',
    title: 'SiliconExpert Manufacturer',
    filterColName:'seMan',
    filterable: true,
    hidden:false

  },
];
