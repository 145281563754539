import { Component, Input, OnInit } from '@angular/core';
import {
  LoaderThemeColor,
  LoaderType,
  LoaderSize,
} from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  // "pulsing' | 'infinite-spinner' | 'converging-spinner";
  // optional overlay
  @Input() type: LoaderType = 'infinite-spinner';
  @Input() themeColor: LoaderThemeColor = 'secondary';
  @Input() size: LoaderSize = 'large';
  @Input() loadingPanel: boolean = false;
  @Input() hasOverlay: boolean = false;
  constructor() {}
}
