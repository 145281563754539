import { DatePipe } from '@angular/common';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as saveAs from 'file-saver';
import * as _ from 'lodash';
import { IFilterModel } from '../shared/shared.interface';
import { GridDataTypes } from './Admin/admin.interfaces';

export function downLoadFile(data: any, type: string, name: string): void {
  const blob = new Blob([data], { type });
  saveAs(blob, name);
}

export function mapSort(sort?: SortDescriptor[]) {
  var kendoSort = _.isArray(sort) ? sort[0] : sort;
  var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';
  if (kendoSort?.field) {
     if(kendoSort?.field=='contractManufacturerName'){
      return { columnName: 'contractManufacturerName', direction: dir }; 
    }
    return { columnName: kendoSort?.field, direction: dir };
  } else {
    return {};
  }
}
export function MapMultipleFilter(gridFilters: any, selectedFilters?: any, gridType?: string) { 
  
  const filterArr: IFilterModel[] = [];
  let allfilters: any = [];
  if (gridFilters?.length > 0) {
    allfilters.push(gridFilters);
  }
  if (selectedFilters?.length > 0) {
    allfilters.push(selectedFilters);
  }
  if(gridFilters && gridFilters?.hasOwnProperty('filters') && gridFilters?.filters?.length > 0)
  {
    allfilters.push(gridFilters?.filters);
  }
  var filterObject: IFilterModel;
  var kendoFilter;
  var kendoFilters;
  allfilters.forEach((filterValue:any) => {
    if (filterValue?.length > 0) {
      filterValue.forEach((item: any, index: number) => {
   
        kendoFilter = _.isArray(filterValue) ? filterValue[index] : filterValue;
        kendoFilters = _.isArray(kendoFilter?.filters)
          ? kendoFilter?.filters[0]
          : kendoFilter;


          if(kendoFilters?.field=='current_manufacturer'){
            filterObject = {
              columnName: 'c.current_manufacturer',
              value: kendoFilters?.value,
            };
           }  
           else if(kendoFilters?.field=='manufacturer_pn'){
            filterObject= {
              columnName: 'c.manufacturer_pn',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='statusName'){
            filterObject= {
              columnName: 'manufacturerStatus.statusName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='distributorType'){
            filterObject= {
              columnName: 'distributorType.typeName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='commodityName'){
            filterObject= {
              columnName: 'commodity.commodityName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='roleName'){
            filterObject= {
              columnName: 'role.roleName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='status' && !gridType){
            filterObject= {
              columnName: 'userStatus.statusName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='status' && gridType == GridDataTypes.CMSITES){
            filterObject= {
              columnName: 'status',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field.includes('Date')){
            filterObject= {
              columnName: kendoFilters?.field,
              value: new DatePipe('en-US').transform( kendoFilters?.value, 'yyyy-MM-dd')?.toString(),   
            };
          }
          else if(kendoFilters?.field=='companyTypeName'){
            filterObject= {
              columnName: 'companyType.companyTypeName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='companyTypeName'){
            filterObject= {
              columnName: 'companyType.companyTypeName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='TicketType'){
            filterObject= {
              columnName: 'ticketType.ticketType',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='Assignee'){
            filterObject= {
              columnName: 'assignee.fullName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='ContractManufacturerName'){
            filterObject= {
              columnName: 'contractManufacturer.contractManufacturerName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='contractManufacturerSite'){
            filterObject= {
              columnName: 'contractManufacturerSite.cmSiteName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='address'){
            filterObject= {
              columnName: 'address',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='country'){
            filterObject= {
              columnName: 'country',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='Requester'){
            filterObject= {
              columnName: 'requester.fullName',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='TicketStatus'){
            filterObject= {
              columnName: 'ticketStatus.ticketStatus',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='Year'){
            filterObject= {
              columnName: 'year.year',
              value: kendoFilters?.value,
            };
          }
          else if(kendoFilters?.field=='LastUpdateDate'){
            filterObject= {
              columnName: 'lastUpdateDate',
              value: new DatePipe('en-US').transform( kendoFilters?.value, 'yyyy-MM-dd')?.toString(),   
            };
          }
          else if(kendoFilters?.field=='InsertionDate'){
            filterObject= {
              columnName: 'insertionDate',
              value: new DatePipe('en-US').transform( kendoFilters?.value, 'yyyy-MM-dd')?.toString(),   
            };
          }
          else{
            filterObject= {
              columnName: kendoFilters?.field,
              value: kendoFilters?.value,
            };
          }
        filterArr.push(filterObject);
      });
    }
  });
  
  return filterArr;

}
export function mapFilter(gridFilters: any) {

  var kendoFilter = _.isArray(gridFilters) ? gridFilters[0] : gridFilters;
  var kendoFilters = _.isArray(kendoFilter?.filters)
    ? kendoFilter?.filters[0]
    : kendoFilter;
    if(!kendoFilters?.field){
      return[];
    }
    else if(kendoFilters?.field?.includes('Date')){
      
      return [
        {
          columnName: kendoFilters?.field,
          value:new DatePipe('en-US').transform( kendoFilters?.value, 'yyyy-MM-dd')?.toString(),   
         },
      ];
    }
    else if(kendoFilters?.field=='contractManufacturerName'){
      return [
        {
          columnName: 'contractManufacturerName',
          value: kendoFilters?.value,   
         },
      ];
       
    }else if(kendoFilters?.field=='contractManufacturerSite'){

      return [
        {
          columnName: 'cmSiteName',
          value: kendoFilters?.value,   
         },
      ];
       
    }
    
    return [
      {
        columnName: kendoFilters?.field,
        value: kendoFilters?.value,
      },

    ]; 
  
}
// Helper
const StringIsNumber = (value:any) => isNaN(Number(value)) === true;
// Turn enum into array
export function ToArray(enumObj :any) {
  const arr = Object.keys(enumObj)
  .filter(StringIsNumber)
  .map(key => ({key : key, value: enumObj[key] }));
  return arr;
  
}

export function validateEmail(mail: string) 
{
  var reg="^[aA-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$";
 let res= String(mail).match(reg);
 return res;
 
  
}