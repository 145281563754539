import { IComponentColumn } from 'src/app/core/parts/components/components.interfaces';
import { actionMenu, GridHeaderData } from 'src/app/shared/shared.interface';
 
export const headers: GridHeaderData[] = [
  
];
export function getGridViewRows(rowdata:any){
var data:any[]=[];
 
rowdata?.map((row: any) => {
  data.push(row.data);
});
return data;
}
export function getGridViewHearders(
  dynamicColumns: IComponentColumn[] = []
): GridHeaderData[] {
  return [
    ...dynamicColumns?.map((colName: any) => {
      return {
        field: colName,
        title: colName,
        filterColName:colName,
        filterable: false,
        sortable: false,
        getCellValue: (data: any, columnIndex: any, rowIndex: any) => {
           return data[columnIndex.leafIndex];
        },
      };
    }),
  ];
}