import { AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';

/**
 * Provides a set of custom validators used by form controls.
 */
export class CustomValidators {
  /**
   * Validator that performs password validation
   * Passwords must be at between 12-64 characters with at least one number, uppercase letter and lowercase letter
   * and one special character
   */
  static password(control: AbstractControl): ValidationErrors | null {
    const regEx =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()\-_=[\]{}|;:,.<>/?])[A-Za-z\d~!@#$%^&*()\-_=[\]{}|;:,.<>/?]{12,64}$/;
    return regEx.test(control.value) ? null : { password: true };
  }

  /**
   * FormGroup validator that requires two controls to have the same values
   */
  static equalValidator(
    controlName: string,
    equalToName: string,
    errorKey: string // here you can customize validation error key
  ) {
    return (form: FormGroup) => {
      const control1 = form.get(controlName);
      const control2 = form.get(equalToName);
      if (control2?.errors && !control2?.errors.errorKey) {
        return;
      }
      if (control1?.value != control2?.value) {
        control2?.setErrors({ [errorKey]: true });
        return {
          [errorKey]: true,
        };
      } else {
        control2?.setErrors(null);
        return null;
      }
    };
  }
  /**
   * FormGroup validator that requires two controls to have different values
   */
  static notEqualValidator(
    controlName: string,
    equalToName: string,
    errorKey: string // here you can customize validation error key
  ) {
    return (form: FormGroup) => {
      const control1 = form.get(controlName);
      const control2 = form.get(equalToName);
      if (control2?.errors && !control2?.errors.errorKey) {
        return;
      }

      if (control1?.value == control2?.value) {
        control2?.setErrors({ [errorKey]: true });
        return {
          [errorKey]: true,
        };
      } else {
        control2?.setErrors(null);
        return null;
      }
    };
  }

  /**
   * Validator that performs phone number validation
   * Only Numbers and white spaces are allowed
   */
  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    const regEx = /^[\d\s]+$/;
    return regEx.test(control.value) ? null : { invalid: true };
  }

  /**
   * Validator that performs email validation
   */
  static email(control: AbstractControl): ValidationErrors | null {
    const regEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(control.value) ? null : { email: true };
  }
  static CustomEmail(control: any) {
    const regEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(control)?true:false ;
  }
  /**
   * Validator that performs only letters and spaces validation
   */
  static onlyLettersAndSpaces(
    control: AbstractControl
  ): ValidationErrors | null {
    const regEx = /^[a-zA-Z\s]*$/;
    return regEx.test(control.value) ? null : { onlyLettersAndSpaces: true };
  }

  /**
   * Validator that prevents special characters, but allows letters, spaces and numbers
   */
  static onlyLettersSpacesAndNumbers(
    control: AbstractControl
  ): ValidationErrors | null {
    const regEx = /^[a-zA-Z0-9\s]*$/;
    return regEx.test(control.value)
      ? null
      : { onlyLettersSpacesAndNumbers: true };
  }
  /**
   * Validator that prevents space only
   */
  static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {  
    if((control.value as string)  === ' '){  
        return {cannotContainSpaceOnly: true}  
    }  

    return null;  
}
 static cannotStartWithWhiteSpaceValidator(form: AbstractControl): ValidationErrors | null{
   return form.value?.startsWith(" ") ? {cannotStartWithWhiteSpace: true} : null;
  }

 static cannotEndWithWhiteSpaceValidator(form: AbstractControl): ValidationErrors | null{
    return form.value?.endsWith(' ') ? {cannotEndWithWhiteSpace: true} : null;
  }
  
  static cannotBeDollarSignValidator(form: AbstractControl): ValidationErrors | null{
    return form.value?.includes('$') ? {cannotBeDollarSign: true} : null;
  }

static cannotBeNumbersOnlyValidator(control: AbstractControl) {

    if (!(control.value)) {
      return null;
    }

    return !String(control.value)
      .match(/^[0-9.]+$/) ? null : {'cannotBeNumbersOnly': true};
  }
  static NumbersOnlyValidator(control: AbstractControl) {
    if (!(control.value)) {
      return null;
    }
     return String(control.value)
      .match(/^[0-9]+$/) ? null : {'NumbersOnly': true};
  }
  static cannotBeSpecialCharsOnlyValidator(control: AbstractControl) {
    const specialChars = /^[$&+,_:;=?@#|'<>.^*()%!-]+$/;
    if (!(control.value)) {
      return null;
    }
    return !specialChars.test(control.value) ? null : {'cannotBeSpecialCharsOnly': true};
  }
  static emailFormat(control: AbstractControl) {

    if (!(control.value)) {
      return null;
    }
    return String(control.value)
      .match("^[aA-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$") ? null : {'emailFormat': true};
  }
}
