export interface ICompanyType {
  companyTypeName: string;
  companyTypeId: number;
}

export interface IRoleList {
  roleName: string;
  roleId: number;
}

export enum LoginStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}
export enum LoginStatusChecked {
  ENABLED = 1,
  DISABLED = 2,
}

export interface IInvitedData {
  role: IRole;
  companyType: ICompanyType;
  email: string;
  companyId:number;
  companyName: string;
  invitationId: number;
  insertionDate: string;
  firstName: string;
  lastName: string;
  phone: string;
  region: string;
  title: string;
  contractManufacturerSite: string;
}
export interface IInvitedFlatObject {
  roleId: number;
  companyId:number;
  roleName: string;
  companyTypeId: number;
  companyTypeName: string;
  companyName: string;
  email: string;
  invitationId: number;
  insertionDate: string;
  firstName: string;
  lastName: string;
  phone: string;
  region: string;
  title: string;
  contractManufacturerSite: string;
}

export interface IRole {
  roleId: number;
  roleName: string;
}

export interface ICompanyType {
  companyTypeId: number;
  companyTypeName: string;
}
