import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent {
  @Input() value: any;
  @Input() name = 'radio';
  @Input() selecedItem: any;
  @Input() checkedStatus: any;
  @Input() text = '';
  @Input() radiotag = '';
  @Output() selectionChange = new EventEmitter<string>();

  constructor() {}
  getvalue($value: any) {
    this.selectionChange.emit($value);
  }
}
