<form [formGroup]="invitedUserForm">

  <div class="form-group row">
    <label class="col-3 col-form-label">First Name </label>
    <kendo-textbox class="col-9" placeholder="First Name" name="FirstName" formControlName="firstName"
      [(ngModel)]="firstName">
    </kendo-textbox>
    <app-error-message message="Max length is 16 chars"
      *ngIf="invitedUserForm?.controls?.firstName?.errors?.maxlength"
      [isError]="true"></app-error-message>
    <app-error-message message="Min length is 1 char"
      *ngIf="invitedUserForm?.controls?.firstName?.errors?.minlength"
      [isError]="true"></app-error-message>
    <app-error-message message="Name can't contain space only"
      *ngIf="!invitedUserForm?.controls?.firstName?.errors?.required && invitedUserForm?.controls?.firstName?.errors?.cannotContainSpaceOnly"
      [isError]="true"></app-error-message>
    <app-error-message message="Name can't start with white space"
      *ngIf="invitedUserForm?.controls?.firstName?.errors?.cannotStartWithWhiteSpace"
      [isError]="true"></app-error-message>
    <app-error-message message="Name can't end with white space"
      *ngIf="invitedUserForm?.controls?.firstName?.errors?.cannotEndWithWhiteSpace"
      [isError]="true"></app-error-message>
    <app-error-message message="Name can't be numbers only"
      *ngIf="invitedUserForm?.controls?.firstName?.errors?.cannotBeNumbersOnly"
      [isError]="true"></app-error-message>
    <app-error-message message="Name can't be special chars only"
      *ngIf="invitedUserForm?.controls?.firstName?.errors?.cannotBeSpecialCharsOnly"
      [isError]="true"></app-error-message>
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label">Last Name </label>
    <kendo-textbox class="col-9" placeholder="Last Name" name="lastName" formControlName="lastName"
      [(ngModel)]="lastName">
    </kendo-textbox>
    <app-error-message message="Max length is 16 chars"
      *ngIf="invitedUserForm?.controls?.lastName?.errors?.maxlength"
      [isError]="true"></app-error-message>
    <app-error-message message="Min length is 1 char"
      *ngIf=" invitedUserForm?.controls?.lastName?.errors?.minlength"
      [isError]="true"></app-error-message>
    <app-error-message message="Name can't contain space only"
      *ngIf=" invitedUserForm?.controls?.lastName?.errors?.cannotContainSpaceOnly"
      [isError]="true"></app-error-message>
    <app-error-message message="Name can't start with white space"
      *ngIf=" invitedUserForm?.controls?.lastName?.errors?.cannotStartWithWhiteSpace"
      [isError]="true"></app-error-message>
    <app-error-message message="Name can't end with white space"
      *ngIf=" invitedUserForm?.controls?.lastName?.errors?.cannotEndWithWhiteSpace"
      [isError]="true"></app-error-message>
    <app-error-message message="Name can't be numbers only"
      *ngIf=" invitedUserForm?.controls?.lastName?.errors?.cannotBeNumbersOnly"
      [isError]="true"></app-error-message>
    <app-error-message message="Name can't be special chars only"
      *ngIf=" invitedUserForm?.controls?.lastName?.errors?.cannotBeSpecialCharsOnly"
      [isError]="true"></app-error-message>
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label">Region </label>
    <kendo-textbox class="col-9" placeholder="Region" name="region" formControlName="region" [(ngModel)]="region">
    </kendo-textbox>
    <app-error-message message="Max length is 45 chars"
      *ngIf="invitedUserForm?.controls?.region?.errors?.maxlength"
      [isError]="true"></app-error-message>
    <app-error-message message="Min length is 1 char"
      *ngIf="invitedUserForm?.controls?.region?.errors?.minlength"
      [isError]="true"></app-error-message>
    <app-error-message message="Region Name can't contain space only"
      *ngIf="invitedUserForm?.controls?.region?.errors?.cannotContainSpaceOnly"
      [isError]="true"></app-error-message>
    <app-error-message message="Region Name can't start with white space"
      *ngIf="invitedUserForm?.controls?.region?.errors?.cannotStartWithWhiteSpace"
      [isError]="true"></app-error-message>
    <app-error-message message="Region Name can't end with white space"
      *ngIf="invitedUserForm?.controls?.region?.errors?.cannotEndWithWhiteSpace"
      [isError]="true"></app-error-message>
    <app-error-message message="Region Name can't be numbers only"
      *ngIf="invitedUserForm?.controls?.region?.errors?.cannotBeNumbersOnly"
      [isError]="true"></app-error-message>
    <app-error-message message="Region Name can't be special chars only"
      *ngIf="invitedUserForm?.controls?.region?.errors?.cannotBeSpecialCharsOnly"
      [isError]="true"></app-error-message>
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label">Title </label>
    <kendo-textbox class="col-9" placeholder="Title" name="title" formControlName="title" [(ngModel)]="title">
    </kendo-textbox>
    <app-error-message message="Max length is 45 chars"
      *ngIf="invitedUserForm?.controls?.title?.errors?.maxlength"
      [isError]="true"></app-error-message>
    <app-error-message message="Min length is 1 char"
      *ngIf="invitedUserForm?.controls?.title?.errors?.minlength"
      [isError]="true"></app-error-message>
    <app-error-message message="Title can't contain space only"
      *ngIf="invitedUserForm?.controls?.title?.errors?.cannotContainSpaceOnly"
      [isError]="true"></app-error-message>
    <app-error-message message="Title can't start with white space"
      *ngIf="invitedUserForm?.controls?.title?.errors?.cannotStartWithWhiteSpace"
      [isError]="true"></app-error-message>
    <app-error-message message="Title can't end with white space"
      *ngIf="invitedUserForm?.controls?.title?.errors?.cannotEndWithWhiteSpace"
      [isError]="true"></app-error-message>
    <app-error-message message="Title can't be numbers only"
      *ngIf="invitedUserForm?.controls?.title?.errors?.cannotBeNumbersOnly"
      [isError]="true"></app-error-message>
    <app-error-message message="Title can't be special chars only"
      *ngIf="invitedUserForm?.controls?.title?.errors?.cannotBeSpecialCharsOnly"
      [isError]="true"></app-error-message>
  </div>

  <div class="form-group row">
    <label class="col-3 col-form-label">Email <span class="astric">*</span></label>
    <kendo-textbox class="col-9" formControlName="email" placeholder="Email" name="Email" [(ngModel)]="Email" >
    </kendo-textbox>
    <app-error-message [message]="emailError" [isError]="true"></app-error-message>

    <app-error-message message="Max length is 64 chars"
    *ngIf="invitedUserForm?.controls?.email?.errors?.maxlength"
    [isError]="true"></app-error-message>
  
  </div>

  <div class="form-group row">
    <label class="col-3 col-form-label">Phone </label>

    <kendo-maskedtextbox class="col-9 form-control" [(ngModel)]="phone" formControlName="phone" mask="+000 - 0000000000"></kendo-maskedtextbox>
    <app-error-message message="Max length is 45 chars"
      *ngIf="invitedUserForm?.controls?.phone?.errors?.maxlength"
      [isError]="true"></app-error-message>
    
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label">Company Type<span class="astric">*</span></label>
    <app-dropdown-list class="col-9" [data]="CompanyTypes" textField="companyTypeName" valueField="companyTypeId"
      [valuePrimitive]="true" (selectionChange)="getSelectedCompanyTypeItem($event)" [selectedItem]="companyId">
    </app-dropdown-list>
    <app-error-message [message]="companyTypeError" [isError]="true"></app-error-message>
  </div>

  <div class="form-group row">
    <label class="col-3 col-form-label">Company <span class="astric">*</span></label>

    <kendo-dropdownlist defaultItem="Select Value" class="col-9" [filterable]="true" [data]="CompanyList"
      textField="name" valueField="id" formControlName="Company"  (selectionChange)="companyChange($event)"
      (filterChange)="click($event)">
    </kendo-dropdownlist>
    <app-error-message [message]="companyError" [isError]="true"></app-error-message>
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label">CM Site<span class="astric">*</span></label>
    <kendo-dropdownlist [disabled]="CmSiteDisabled" defaultItem="Select Value" class="col-9" [filterable]="true"
      [data]="cmSiteListItems" textField="cmSiteName" valueField="cmSiteId" [(ngModel)]="cmSite"
      (filterChange)="handleFilterCMsite($event)">
    </kendo-dropdownlist>
    <app-error-message [message]="cmSiteError" [isError]="true"></app-error-message>
  </div>

  <div class="form-group row">
    <label class="col-3 col-form-label">Role<span class="astric">*</span></label>
    <app-dropdown-list class="col-9" [data]="RolelistItems" textField="roleName" valueField="roleId"
      [valuePrimitive]="true" (selectionChange)="getSelectedRole($event)" [selectedItem]="role">
    </app-dropdown-list>
    <app-error-message [message]="roleError" [isError]="true"></app-error-message>
  </div>

  <app-error-message message="{{ error }}" *ngIf="this.IsError" [isError]="true"></app-error-message>


</form>