import { Action } from '@ngrx/store';
import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import {
  CardStatusEnum,
  ISubmitSupplierResponse,
} from 'src/app/core/upload/upload.interface';
import { GridHeaderData } from 'src/app/shared/shared.interface';

export enum ActionTypes {
  TICKET_HISTORY_DATA = '[CMT-Upload] TICKET_HISTORY_DATA',
  TICKET_HISTORY_DATA_SUCCESS = '[CMT-Upload] TICKET_HISTORY_DATA_SUCCESS',
  TICKET_HISTORY_DATA_ERROR = '[CMT-Upload] TICKET_HISTORY_DATA_ERROR',
  CHANGE_NORMALIZED_GRID_PAGE = '[CMT-Upload] CHANGE_NORMALIZED_GRID_PAGE',
  UPLOAD_REVIEW_GRID_DATA = '[CMT-Upload] UPLOAD_REVIEW_GRID_DATA',
  UPLOAD_REVIEW_GRID_DATA_SUCCESS = '[CMT-Upload] UPLOAD_REVIEW_GRID_DATA_SUCCESS',
  UPLOAD_REVIEW_GRID_DATA_ERROR = '[CMT-Upload] UPLOAD_REVIEW_GRID_DATA_ERROR',
  TICKET_REQUEST_DATA = '[CMT-Upload] TICKET_REQUEST_DATA',
  TICKET_REQUEST_DATA_SUCCESS = '[CMT-Upload] TICKET_REQUEST_DATA_SUCCESS',
  TICKET_REQUEST_DATA_ERROR = '[CMT-Upload] TICKET_REQUEST_DATA_ERROR',
  EXPORT_NORMALIZED_GRID_DATA = '[CMT-Upload] EXPORT_NORMALIZED_GRID_DATA',
  EXPORT_NORMALIZED_GRID_DATA_SUCCESS = '[CMT-Upload] EXPORT_NORMALIZED_GRID_DATA_SUCCESS',
  EXPORT_NORMALIZED_GRID_DATA_ERROR = '[CMT-Upload] EXPORT_NORMALIZED_GRID_DATA_ERROR',
  SET_CURRENT_ACTION_DATA = '[CMT-Upload] SET_CURRENT_ACTION_DATA',
  SET_CURRENT_EXIST_ACTION_DATA = '[EXIST-Upload] SET_CURRENT_ACTION_DATA',
  VALIDATE_FILE_STATUS = '[CMT-Upload] VALIDATE_FILE_STATUS',
  VALIDATE_FILE_STATUS_SUCCESS = '[CMT-Upload] VALIDATE_FILE_STATUS_SUCCESS',
  VALIDATE_FILE_STATUS_ERROR = '[CMT-Upload] VALIDATE_FILE_STATUS_ERROR',
  DISCARD_TICKET = '[CMT-Upload] DISCARD_REQUEST',
  DISCARD_TICKET_SUCCESS = '[CMT-Upload] DISCARD_REQUEST_SUCCESS',
  DISCARD_TICKET_ERROR = '[CMT-Upload] DISCARD_REQUEST_ERROR',
  UPLOAD_FILE = '[CMT-Upload] UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS = '[CMT-Upload] UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR = '[CMT-Upload] UPLOAD_FILE_ERROR',
  MAPPED_GRID_DATA = '[CMT-Upload] MAPPED_GRID_DATA',
  MAPPED_GRID_DATA_SUCCESS = '[CMT-Upload] MAPPED_GRID_DATA_SUCCESS',
  MAPPED_GRID_DATA_ERROR = '[CMT-Upload] MAPPED_GRID_DATA_ERROR',

  TICKET_PAGE_SIZE_CHANGED = '[CMT-Upload] TICKET_PAGE_SIZE_CHANGED',
  TICKET_PAGE_CHANGED = '[CMT-Upload] TICKET_PAGE_CHANGED',
  TICKET_SORT_CHANGED = '[CMT-Upload] TICKET_SORT_CHANGED',
  TICKET_FILTER_CHANGED = '[CMT-Upload] TICKET_FILTER_CHANGED',

  SUBMIT_TICKET_FILE = '[CMT-Upload] SUBMIT_TICKET_FILE',
  SUBMIT_TICKET_FILE_SUCCESS = '[CMT-Upload] SUBMIT_TICKET_FILE_SUCCESS',
  SUBMIT_TICKET_FILE_ERROR = '[CMT-Upload] SUBMIT_TICKET_FILE_ERROR',

  EXPORT_COMPONENT_PRICE_REVIEW = '[CMT-Upload] EXPORT_COMPONENT_PRICE_REVIEW',
  EXPORT_COMPONENT_PRICE_REVIEW_SUCCESS = '[CMT-Upload] EXPORT_COMPONENT_PRICE_REVIEW_SUCCESS',
  EXPORT_COMPONENT_PRICE_REVIEW_ERROR = '[CMT-Upload] EXPORT_COMPONENT_PRICE_REVIEW_ERROR',

  CLOSE_TICKET = '[CMT-Upload] CLOSE_TICKET',
  CLOSE_TICKET_SUCCESS = '[CMT-Upload] CLOSE_TICKET_SUCCESS',
  CLOSE_TICKET_ERROR = '[CMT-Upload] CLOSE_TICKET_ERROR',

  SUPPLIER_LOAD_DATA_GRID = '[SUPPLIER-Upload] SUPPLIER_LOAD_DATA_GRID',
  SUPPLIER_LOAD_DATA_GRID_SUCCESS = '[SUPPLIER-Upload] SUPPLIER_LOAD_DATA_GRID_SUCCESS',
  SUPPLIER_LOAD_DATA_GRID_ERROR = '[SUPPLIER-Upload] SUPPLIER_LOAD_DATA_GRID_ERROR',

  SUPPLIER_SORT_CHANGED = '[SUPPLIER-Upload] SUPPLIER_SORT_CHANGED',
  SUPPLIER_FILTER_CHANGED = '[SUPPLIER-Upload] SUPPLIER_FILTER_CHANGED',
  SUPPLIER_PAGE_CHANGED = '[SUPPLIER-Upload] SUPPLIER_PAGE_CHANGED',
  SUPPLIER_PAGE_SIZE_CHANGED = '[SUPPLIER-Upload] SUPPLIER_PAGE_SIZE_CHANGED',

  SUPPLIER_LOAD_DATA_Archived_GRID = '[SUPPLIER-Upload] SUPPLIER_LOAD_DATA_Archived_GRID',
  SUPPLIER_LOAD_DATA_Archived_GRID_SUCCESS = '[SUPPLIER-Upload] SUPPLIER_LOAD_DATA_Archived_GRID_SUCCESS',
  SUPPLIER_LOAD_DATA_Archived_GRID_ERROR = '[SUPPLIER-Upload] SUPPLIER_LOAD_DATA_Archived_GRID_ERROR',

  SUPPLIER_Archived_SORT_CHANGED = '[SUPPLIER-Upload] SUPPLIER_Archived_SORT_CHANGED',
  SUPPLIER_Archived_FILTER_CHANGED = '[SUPPLIER-Upload] SUPPLIER_Archived_FILTER_CHANGED',
  SUPPLIER_Archived_PAGE_CHANGED = '[SUPPLIER-Upload] SUPPLIER_Archived_PAGE_CHANGED',
  SUPPLIER_Archived_PAGE_SIZE_CHANGED = '[SUPPLIER-Upload] SUPPLIER_Archived_PAGE_SIZE_CHANGED',

  

  SUPPLIER_VALIDATE_FILE_STATUS = '[SUPPLIER-Upload] SUPPLIER_VALIDATE_FILE_STATUS',
  SUPPLIER_VALIDATE_FILE_STATUS_SUCCESS = '[SUPPLIER-Upload] SUPPLIER_VALIDATE_FILE_STATUS_SUCCESS',
  SUPPLIER_VALIDATE_FILE_STATUS_ERROR = '[SUPPLIER-Upload] SUPPLIER_VALIDATE_FILE_STATUS_ERROR',

  SUPPLIER_DISCARD_FILE = '[SUPPLIER-Upload] SUPPLIER_DISCARD_REQUEST',
  SUPPLIER_DISCARD_FILE_SUCCESS = '[SUPPLIER-Upload] SUPPLIER_DISCARD_REQUEST_SUCCESS',
  SUPPLIER_DISCARD_FILE_ERROR = '[SUPPLIER-Upload] SUPPLIER_DISCARD_REQUEST_ERROR',

  SUPPLIER_UPLOAD_FILE = '[SUPPLIER-Upload] SUPPLIER_UPLOAD_FILE',
  SUPPLIER_UPLOAD_FILE_SUCCESS = '[SUPPLIER-Upload] SUPPLIER_UPLOAD_FILE_SUCCESS',
  SUPPLIER_UPLOAD_FILE_ERROR = '[SUPPLIER-Upload] SUPPLIER_UPLOAD_FILE_ERROR',

  SUPPLIER_MAPPED_GRID_DATA = '[SUPPLIER-Upload] SUPPLIER_MAPPED_GRID_DATA',
  SUPPLIER_MAPPED_GRID_DATA_SUCCESS = '[SUPPLIER-Upload] SUPPLIER_MAPPED_GRID_DATA_SUCCESS',
  SUPPLIER_MAPPED_GRID_DATA_ERROR = '[SUPPLIER-Upload] SUPPLIER_MAPPED_GRID_DATA_ERROR',
  EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA = '[SUPPLIER-Upload] EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA',
  EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA_SUCCESS = '[SUPPLIER-Upload] EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA_SUCCESS',
  EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA_Error = '[SUPPLIER-Upload] EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA_Error',
  EXPORT_QUOTE_DETAILS_GRID_DATA = '[SUPPLIER-Upload] EXPORT_QUOTE_DETAILS_GRID_DATA',
  EXPORT_SUPPLIER_QUOTES_GRID_DATA = '[SUPPLIER-Upload] EXPORT_SUPPLIER_QUOTES_GRID_DATA',
  EXPORT_SUPPLIER_QUOTES_GRID_DATA_SUCCESS = '[SUPPLIER-Upload] EXPORT_SUPPLIER_QUOTES_GRID_DATA_SUCCESS',
  EXPORT_SUPPLIER_QUOTES_GRID_DATA_ERROR = '[SUPPLIER-Upload] EXPORT_SUPPLIER_QUOTES_GRID_DATA_ERROR',
  SUPPLIER_SET_CURRENT_ACTION_DATA = '[SUPPLIER-Upload] SUPPLIER_SET_CURRENT_ACTION_DATA',
  SUPPLIER_UPLOAD_REVIEW_GRID_DATA = '[SUPPLIER-Upload] SUPPLIER_UPLOAD_REVIEW_GRID_DATA',
  SUPPLIER_UPLOAD_REVIEW_GRID_DATA_SUCCESS = '[SUPPLIER-Upload] SUPPLIER_UPLOAD_REVIEW_GRID_DATA_SUCCESS',
  SUPPLIER_UPLOAD_REVIEW_GRID_DATA_ERROR = '[SUPPLIER-Upload] SUPPLIER_UPLOAD_REVIEW_GRID_DATA_ERROR',
  EXPORT_SUPPLIER_NORMALIZED_GRID_DATA = '[SUPPLIER-Upload] EXPORT_SUPPLIER_NORMALIZED_GRID_DATA',
  EXPORT_SUPPLIER_NORMALIZED_GRID_DATA_SUCCESS = '[SUPPLIER-Upload] EXPORT_SUPPLIER_NORMALIZED_GRID_DATA_SUCCESS',
  EXPORT_SUPPLIER_NORMALIZED_GRID_DATA_ERROR = '[SUPPLIER-Upload] EXPORT_SUPPLIER_NORMALIZED_GRID_DATA_ERROR',
  SUBMIT_SUPPLIER_FILE = '[SUPPLIER-Upload] SUBMIT_SUPPLIER_FILE',
  SUBMIT_SUPPLIER_FILE_SUCCESS = '[SUPPLIER-Upload] SUBMIT_SUPPLIER_FILE_SUCCESS',
  SUBMIT_SUPPLIER_FILE_ERROR = '[SUPPLIER-Upload] SUBMIT_SUPPLIER_FILE_ERROR',
  SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID = 'SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID',
  SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID_SUCCESS = 'SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID_SUCCESS',
  SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID_ERROR = 'SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID_ERROR',
  SUPPLIER_AWARDED_QUOTES_SORT_CHANGED = 'SUPPLIER_AWARDED_QUOTES_SORT_CHANGED',
  SUPPLIER_AWARDED_QUOTES_PAGE_CHANGED = 'SUPPLIER_AWARDED_QUOTES_PAGE_CHANGED',
  SUPPLIER_AWARDED_QUOTES_FILTER_CHANGED = 'SUPPLIER_AWARDED_QUOTES_FILTER_CHANGED',
  SUPPLIER_AWARDED_QUOTES_PAGE_SIZE_CHANGED = 'SUPPLIER_AWARDED_QUOTES_PAGE_SIZE_CHANGED',
  EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA = '[SUPPLIER-Upload] EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA',
  EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA_SUCCESS = '[SUPPLIER-Upload] EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA_SUCCESS',
  EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA_ERROR = '[SUPPLIER-Upload] EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA_ERROR',
  EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA = '[SUPPLIER-Upload] EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA',
  EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA_SUCCESS = '[SUPPLIER-Upload] EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA_SUCCESS',
  EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA_ERROR = '[SUPPLIER-Upload] EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA_ERROR',
  EMPTY_UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS = 'EMPTY_UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS',
  UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS = 'UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS',
  UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS_SUCCESS = 'UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS_SUCCESS',
  UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS_ERROR = 'UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS_ERROR',
  EMPTY_VALIDATE_FILE_STATUS = 'EMPTY_VALIDATE_FILE_STATUS',
  GET_SUPPLIER_AWARDED_QUOTE_PARTS_DETAILS = 'GET_SUPPLIER_AWARDED_QUOTE_PARTS_DETAILS',
  GET_SUPPLIER_AWARDED_QUOTE_PARTS_DETAILS_SUCCESS = 'GET_SUPPLIER_AWARDED_QUOTE_PARTS_DETAILS_SUCCESS',
  GET_SUPPLIER_AWARDED_QUOTE_PARTS_DETAILS_ERROR = 'GET_SUPPLIER_AWARDED_QUOTE_PARTS_DETAILS_ERROR',

  UPDATE_AWARDED_QUOTE_PARTS_BY_SELECTION = 'UPDATE_AWARDED_QUOTE_PARTS_BY_SELECTION',
  UPDATE_AWARDED_QUOTE_PARTS_BY_SELECTION_SUCCESS = 'UPDATE_AWARDED_QUOTE_PARTS_BY_SELECTION_SUCCESS',
  UPDATE_AWARDED_QUOTE_PARTS_BY_SELECTION_ERROR = 'UPDATE_AWARDED_QUOTE_PARTS_BY_SELECTION_ERROR',
  SET_AWARDED_QUOTE_DETAILS = 'SET_AWARDED_QUOTE_DETAILS',
  EMPTY_AWARDED_QUOTE_DETAILS = 'EMPTY_AWARDED_QUOTE_DETAILS',
  GET_UPDATE_EXISTING_FILE_STATUS = 'GET_UPDATE_EXISTING_FILE_STATUS',
  GET_UPDATE_EXISTING_FILE_STATUS_SUCCESS = 'GET_UPDATE_EXISTING_FILE_STATUS_SUCCESS',
  GET_UPDATE_EXISTING_FILE_STATUS_ERROR = 'GET_UPDATE_EXISTING_FILE_STATUS_ERROR',
  EMPTY_UPDATE_EXISTING_FILE_STATUS = 'EMPTY_UPDATE_EXISTING_FILE_STATUS',

  EMPTY_UPLOAD_FILE = 'EMPTY_UPLOAD_FILE'
}

export class TicketHistoryData implements Action {
  readonly type = ActionTypes.TICKET_HISTORY_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class TicketHistoryDataSuccess implements Action {
  readonly type = ActionTypes.TICKET_HISTORY_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class TicketHistoryDataError implements Action {
  readonly type = ActionTypes.TICKET_HISTORY_DATA_ERROR;
  constructor(public payload: any) {}
}

export class UploadReviewData implements Action {
  readonly type = ActionTypes.UPLOAD_REVIEW_GRID_DATA;
  constructor(public ticketId?: number, public file?: any,public fileType?:string) {}
}

export class UploadReviewDataSuccess implements Action {
  readonly type = ActionTypes.UPLOAD_REVIEW_GRID_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class UploadReviewDataError implements Action {
  readonly type = ActionTypes.UPLOAD_REVIEW_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}

export class GetFileStatus implements Action {
  readonly type = ActionTypes.VALIDATE_FILE_STATUS;
  constructor(public ticketID?: string) {}
}
export class EmptyValidateFileStatus implements Action {
  readonly type = ActionTypes.EMPTY_VALIDATE_FILE_STATUS;
  constructor() {}
}
export class EmptyUpdateExistingFileStatus implements Action {
  readonly type = ActionTypes.EMPTY_UPDATE_EXISTING_FILE_STATUS;
  constructor() {}
}
export class GetFileStatusSuccess implements Action {
  readonly type = ActionTypes.VALIDATE_FILE_STATUS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetFileStatusError implements Action {
  readonly type = ActionTypes.VALIDATE_FILE_STATUS_ERROR;
  constructor(public payload: any) {}
}

export class GetUpdateExistingFileStatus implements Action {
  readonly type = ActionTypes.GET_UPDATE_EXISTING_FILE_STATUS;
  constructor() {}
}
export class GetUpdateExistingFileStatusSuccess implements Action {
  readonly type = ActionTypes.GET_UPDATE_EXISTING_FILE_STATUS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetUpdateExistingFileStatusError implements Action {
  readonly type = ActionTypes.GET_UPDATE_EXISTING_FILE_STATUS_ERROR;
  constructor(public payload: any) {}
}

export class DiscardTicket implements Action {
  readonly type = ActionTypes.DISCARD_TICKET;
  constructor(public ticketId: string, public fileId?:any, public fileType?:string) {}
}
export class DiscardTicketSucess implements Action {
  readonly type = ActionTypes.DISCARD_TICKET_SUCCESS;
  constructor(public payload: any) {}
}
export class DiscardTicketError implements Action {
  readonly type = ActionTypes.DISCARD_TICKET_ERROR;
  constructor(public payload: any) {}
}
// upload file
export class UPLOAD_FILE implements Action {
  readonly type = ActionTypes.UPLOAD_FILE;
  constructor(public payload: any,public fileType?:string) {}
}
export class EMPTY_UPLOAD_FILE implements Action {
  readonly type = ActionTypes.EMPTY_UPLOAD_FILE;
  constructor() {}
}
export class UPLOAD_FILE_SUCCESS implements Action {
  readonly type = ActionTypes.UPLOAD_FILE_SUCCESS;
  constructor(public payload: any) {}
}
export class UPLOAD_FILE_ERROR implements Action {
  readonly type = ActionTypes.UPLOAD_FILE_ERROR;
  constructor(public payload: any) {}
}

export class ChangeNormalizedGridPage implements Action {
  readonly type = ActionTypes.CHANGE_NORMALIZED_GRID_PAGE;
  constructor(public page?: number) {}
}

export class ExportNormalizedGridData implements Action {
  readonly type = ActionTypes.EXPORT_NORMALIZED_GRID_DATA;
  constructor(
    public ticketId?: any,
    public fileId?: any,
    public option?: any,
    public fileName?: any,
    public fileType?: any
  ) {
  }
}

export class ExportNormalizedGridDataSucees implements Action {
  readonly type = ActionTypes.EXPORT_NORMALIZED_GRID_DATA_SUCCESS;

  constructor(public payload: any, public fileName: any) {}
}

export class ExportNormalizedGridDataError implements Action {
  readonly type = ActionTypes.EXPORT_NORMALIZED_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}

export class SetCurrentExistActionData implements Action {
  readonly type = ActionTypes.SET_CURRENT_EXIST_ACTION_DATA;
  constructor(public actionType?: string, public fileId?: string | null,public filetype?:string) {}
}
export class SetCurrentActionData implements Action {
  readonly type = ActionTypes.SET_CURRENT_ACTION_DATA;
  constructor(public actionType?: string, public ticketId?: string | null) {}
}
export class SetSupplierCurrentActionData implements Action {
  readonly type = ActionTypes.SUPPLIER_SET_CURRENT_ACTION_DATA;
  constructor(
    public actionType?: string,
    public quoteId?: string | null,
    public tempQuoteId?: string
  ) {}
}

export class TicketRequestData implements Action {
  readonly type = ActionTypes.TICKET_REQUEST_DATA;
  readonly status = CardStatusEnum.NEW;
  constructor() {}
}

export class TicketRequestDataSuccess implements Action {
  readonly type = ActionTypes.TICKET_REQUEST_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class TicketRequestDataError implements Action {
  readonly type = ActionTypes.TICKET_REQUEST_DATA_ERROR;
  constructor(public payload: any) {}
}

export class MappedData implements Action {
  readonly type = ActionTypes.MAPPED_GRID_DATA;
  constructor(
    public mappedColumns?: GridHeaderData[],
    public startRow?: number,
    public fileType?:string,
    public ticketId?:any
  ) {}
}

export class MappedDataSuccess implements Action {
  readonly type = ActionTypes.MAPPED_GRID_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class MappedDataError implements Action {
  readonly type = ActionTypes.MAPPED_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}

export class TicketHistoryPageChanged implements Action {
  readonly type = ActionTypes.TICKET_PAGE_CHANGED;
  constructor(public page?: number) {}
}

export class TicketHistorySortChanged implements Action {
  readonly type = ActionTypes.TICKET_SORT_CHANGED;
  constructor(public sort?: SortDescriptor[]) {}
}

export class TicketHistoryFilterChanged implements Action {
  readonly type = ActionTypes.TICKET_FILTER_CHANGED;
  constructor(public filter?: FilterDescriptor[]) {}
}

export class TicketHistoryPageSizeChanged implements Action {
  readonly type = ActionTypes.TICKET_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

export class SubmitTicketFile implements Action {
  readonly type = ActionTypes.SUBMIT_TICKET_FILE;
  constructor(public ticketId: number,public fileId?:number,public fileType?:any) {}
}

export class SubmitTicketFileSuccess implements Action {
  readonly type = ActionTypes.SUBMIT_TICKET_FILE_SUCCESS;
  constructor(public payload: any) {}
}

export class SubmitTicketFileError implements Action {
  readonly type = ActionTypes.SUBMIT_TICKET_FILE_ERROR;
  constructor(public payload: any) {}
}

export class ExportPriceReview implements Action {
  readonly type = ActionTypes.EXPORT_COMPONENT_PRICE_REVIEW;
  constructor(public ticketId?: any, public fileName?: any) {}
}

export class ExportPriceReviewSuccess implements Action {
  readonly type = ActionTypes.EXPORT_COMPONENT_PRICE_REVIEW_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}

export class ExportPriceReviewError implements Action {
  readonly type = ActionTypes.EXPORT_COMPONENT_PRICE_REVIEW_ERROR;
  constructor(public payload: any) {}
}

export class CloseTicket implements Action {
  readonly type = ActionTypes.CLOSE_TICKET;
  constructor(public ticketId: number) {}
}

export class CloseTicketSuccess implements Action {
  readonly type = ActionTypes.CLOSE_TICKET_SUCCESS;
  constructor(public payload: any) {}
}

export class CloseTicketError implements Action {
  readonly type = ActionTypes.CLOSE_TICKET_ERROR;
  constructor(public payload: any) {}
}
/*----------------------------supplier---------------------------------*/
export class GetSupplierGridData implements Action {
  readonly type = ActionTypes.SUPPLIER_LOAD_DATA_GRID;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class GetSupplierGridDataSuccess implements Action {
  readonly type = ActionTypes.SUPPLIER_LOAD_DATA_GRID_SUCCESS;
  constructor(public payload: any) {}
}

export class GetSupplierGridDataError implements Action {
  readonly type = ActionTypes.SUPPLIER_LOAD_DATA_GRID_ERROR;
  constructor(public payload: any) {}
}

export class SupplierSortChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_SORT_CHANGED;
  constructor(public page?: number) {}
}
export class SupplierPageChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_PAGE_CHANGED;
  constructor(public page?: number) {}
}
export class SupplierFilterChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_FILTER_CHANGED;
  constructor(public filter?: any) {}
}
export class SupplierPageSizeChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

export class GetSupplierGridArchivedData implements Action {
  readonly type = ActionTypes.SUPPLIER_LOAD_DATA_Archived_GRID;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class GetSupplierGridArchivedDataSuccess implements Action {
  readonly type = ActionTypes.SUPPLIER_LOAD_DATA_Archived_GRID_SUCCESS;
  constructor(public payload: any) {}
}

export class GetSupplierGridArchivedDataError implements Action {
  readonly type = ActionTypes.SUPPLIER_LOAD_DATA_Archived_GRID_ERROR;
  constructor(public payload: any) {}
}

export class SupplierArchivedSortChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_Archived_SORT_CHANGED;
  constructor(public page?: number) {}
}
export class SupplierArchivedPageChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_Archived_PAGE_CHANGED;
  constructor(public page?: number) {}
}
export class SupplierArchivedFilterChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_Archived_FILTER_CHANGED;
  constructor(public filter?: any) {}
}
export class SupplierArchivedPageSizeChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_Archived_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

export class GetSupplierAwardedQuotesGridData implements Action {
  readonly type = ActionTypes.SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}
export class GetSupplierAwardedQuotesGridDataSuccess implements Action {
  readonly type = ActionTypes.SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID_SUCCESS;
  constructor(public payload: any) {}
}

export class GetSupplierAwardedQuotesGridDataError implements Action {
  readonly type = ActionTypes.SUPPLIER_AWARDED_QUOTES_LOAD_DATA_GRID_ERROR;
  constructor(public payload: any) {}
}

export class SupplierAwardedQuotesSortChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_AWARDED_QUOTES_SORT_CHANGED;
  constructor(public page?: number) {}
}
export class SupplierAwardedQuotesPageChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_AWARDED_QUOTES_PAGE_CHANGED;
  constructor(public page?: number) {}
}
export class SupplierAwardedQuotesFilterChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_AWARDED_QUOTES_FILTER_CHANGED;
  constructor(public filter?: any) {}
}
export class SupplierAwardedQuotesPageSizeChanged implements Action {
  readonly type = ActionTypes.SUPPLIER_AWARDED_QUOTES_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}


export class ResponseUploadFile implements Action {
  readonly type = ActionTypes.SUPPLIER_UPLOAD_FILE;
  constructor(public responseFile: any, public quoteId: any) {}
}
export class ResponseUploadFileSuccess implements Action {
  readonly type = ActionTypes.SUPPLIER_UPLOAD_FILE_SUCCESS;
  constructor(public payload: any) {}
}
export class ResponseUploadFileError implements Action {
  readonly type = ActionTypes.SUPPLIER_UPLOAD_FILE_ERROR;
  constructor(public payload: any) {}
}
export class ExportQuotePartsDetailsGridData implements Action {
  readonly type = ActionTypes.EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA;
  constructor(public quoteId?: any, public fileName?: any) {}
}


export class ExportQuotePartsDetailsGridDataSuccess implements Action {
  readonly type = ActionTypes.EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}
export class ExportQuotePartsDetailsGridDataError implements Action {
  readonly type = ActionTypes.EXPORT_QUOTE_PARTS_DETAILS_GRID_DATA_Error;
  constructor(public payload: any) {}
}
export class ExportAwardedQuotePartsDetailsGridData implements Action {
  readonly type = ActionTypes.EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA;
  constructor(public quoteId?: any, public fileName?: any) {}
}
export class ExportAwardedQuotePartsDetailsGridDataSuccess implements Action {
  readonly type = ActionTypes.EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}
export class ExportAwardedQuotePartsDetailsGridDataError implements Action {
  readonly type = ActionTypes.EXPORT_AWARDED_QUOTE_PARTS_DETAILS_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}
export class ExportSupplierQuotesGridData implements Action {
  readonly type = ActionTypes.EXPORT_SUPPLIER_QUOTES_GRID_DATA;
  constructor(public fileName?: any, public payload?: any,public status?:any) {}
}
export class ExportSupplierQuotesGridDataSuccess implements Action {
  readonly type = ActionTypes.EXPORT_SUPPLIER_QUOTES_GRID_DATA_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}
export class ExportSupplierQuotesGridDataError implements Action {
  readonly type = ActionTypes.EXPORT_SUPPLIER_QUOTES_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ExportSupplierAwardedQuotesGridData implements Action {
  readonly type = ActionTypes.EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA;
  constructor(public fileName?: any, public payload?: any) {}
}
export class ExportSupplierAwardedQuotesGridDataSuccess implements Action {
  readonly type = ActionTypes.EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}
export class ExportSupplierAwardedQuotesGridDataError implements Action {
  readonly type = ActionTypes.EXPORT_SUPPLIER_AWARDED_QUOTES_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}

export class DiscardResponse implements Action {
  readonly type = ActionTypes.SUPPLIER_DISCARD_FILE;
  constructor(public fileId: any) {}
}
export class DiscardResponseSucess implements Action {
  readonly type = ActionTypes.SUPPLIER_DISCARD_FILE_SUCCESS;
  constructor(public payload: any) {}
}
export class DiscardResponseError implements Action {
  readonly type = ActionTypes.SUPPLIER_DISCARD_FILE_ERROR;
  constructor(public payload: any) {}
}

export class GetLastResponseFileStatus implements Action {
  readonly type = ActionTypes.SUPPLIER_VALIDATE_FILE_STATUS;
  constructor(public quoteId: any) {}
}
export class GetLastResponseFileStatusSuccess implements Action {
  readonly type = ActionTypes.SUPPLIER_VALIDATE_FILE_STATUS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetLastResponseFileStatusError implements Action {
  readonly type = ActionTypes.SUPPLIER_VALIDATE_FILE_STATUS_ERROR;
  constructor(public payload: any) {}
}
export class UploadResponseReviewData implements Action {
  readonly type = ActionTypes.SUPPLIER_UPLOAD_REVIEW_GRID_DATA;
  constructor(
    public QuoteId?: number,
    public fileId?: number,
    public tempResponseId?: string
  ) {}
}

export class UploadResponseReviewDataSuccess implements Action {
  readonly type = ActionTypes.SUPPLIER_UPLOAD_REVIEW_GRID_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class UploadResponseReviewDataError implements Action {
  readonly type = ActionTypes.SUPPLIER_UPLOAD_REVIEW_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}

export class MappedSupplierData implements Action {
  readonly type = ActionTypes.SUPPLIER_MAPPED_GRID_DATA;
  constructor(
    public mappedColumns?: GridHeaderData[],
    public startRow?: number,
    public tempResponseId?: string | null,
    public quoteId?: number,
    public fileId?: number
  ) {}
}

export class MappedSupplierDataSuccess implements Action {
  readonly type = ActionTypes.SUPPLIER_MAPPED_GRID_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class MappedSupplierDataError implements Action {
  readonly type = ActionTypes.SUPPLIER_MAPPED_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}
export class ExportSuppNormalizedGridData implements Action {
  readonly type = ActionTypes.EXPORT_SUPPLIER_NORMALIZED_GRID_DATA;
  constructor(
    public tempResId?: any,
    public option?: any,
    public fileName?: any
  ) {}
}

export class ExportSuppNormalizedGridDataSucees implements Action {
  readonly type = ActionTypes.EXPORT_SUPPLIER_NORMALIZED_GRID_DATA_SUCCESS;

  constructor(public payload: any, public fileName: any) {}
}

export class ExportSuppNormalizedGridDataError implements Action {
  readonly type = ActionTypes.EXPORT_SUPPLIER_NORMALIZED_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}

export class SubmitSupplierFile implements Action {
  readonly type = ActionTypes.SUBMIT_SUPPLIER_FILE;
  constructor(public supplierResponse: ISubmitSupplierResponse) {}
}

export class SubmitSupplierFileSuccess implements Action {
  readonly type = ActionTypes.SUBMIT_SUPPLIER_FILE_SUCCESS;
  constructor(public payload: any) {}
}

export class SubmitSupplierFileError implements Action {
  readonly type = ActionTypes.SUBMIT_SUPPLIER_FILE_ERROR;
  constructor(public payload: any) {}
}
export class EmptyUpdateAwardedQuotePartsAckStatus implements Action {
  readonly type = ActionTypes.EMPTY_UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS;
  constructor() {}
}


export class UpdateAwardedQuotePartsAckStatus implements Action {
  readonly type = ActionTypes.UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS;
  constructor(public quoteId: any, public status: any) {}
}

export class UpdateAwardedQuotePartsAckStatusSuccess implements Action {
  readonly type = ActionTypes.UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateAwardedQuotePartsAckStatusError implements Action {
  readonly type = ActionTypes.UPDATE_AWARDED_QUOTE_PARTS_ACK_STATUS_ERROR;
  constructor(public payload: any) {}
}

// export class GetLastFileStatus implements Action {
//   readonly type = ActionTypes.VALIDATE_FILE_STATUS;
//   constructor() {}
// }
// export class GetLastQuoteFileStatusSuccess implements Action {
//   readonly type = ActionTypes.VALIDATE_QUOTE_FILE_STATUS_SUCCESS;
//   constructor(public payload: any) {}
// }
// export class GetLastQuoteFileStatusError implements Action {
//   readonly type = ActionTypes.VALIDATE_QUOTE_FILE_STATUS_ERROR;
//   constructor(public payload: any) {}
// }


export class GetSupplierAwardedQuotePartsDetails implements Action {
  readonly type = ActionTypes.GET_SUPPLIER_AWARDED_QUOTE_PARTS_DETAILS;
  constructor(public supplierId: number,
    public quoteId: number,
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
 ) {}
}
export class GetSupplierAwardedQuotePartsDetailsSuccess implements Action {
  readonly type = ActionTypes.GET_SUPPLIER_AWARDED_QUOTE_PARTS_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetSupplierAwardedQuotePartsDetailsError implements Action {
  readonly type = ActionTypes.GET_SUPPLIER_AWARDED_QUOTE_PARTS_DETAILS_ERROR;
  constructor(public payload: any) {}
}

export class UpdateAwardedQuotePartsBySelection implements Action {
  readonly type = ActionTypes.UPDATE_AWARDED_QUOTE_PARTS_BY_SELECTION;

  constructor(public payload: any) { }
}

export class UpdateAwardedQuotePartsBySelectionSuccess implements Action {
  readonly type = ActionTypes.UPDATE_AWARDED_QUOTE_PARTS_BY_SELECTION_SUCCESS;
  constructor(public payload: any) { }
}
export class UpdateAwardedQuotePartsBySelectionError implements Action {
  readonly type = ActionTypes.UPDATE_AWARDED_QUOTE_PARTS_BY_SELECTION_ERROR;
  constructor(public payload: any) { }
}
export class SetAwardedQuoteDetails implements Action {
  readonly type = ActionTypes.SET_AWARDED_QUOTE_DETAILS;
  constructor(public supplierId: number,
    public quoteId: number,public quoteName: string) { }
}
export class EmptyAwardedQuoteDetails implements Action {
  readonly type = ActionTypes.EMPTY_AWARDED_QUOTE_DETAILS;
  constructor() { }
}

