import { ApiService } from '../../api/api.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrossSearchResponse } from './cross-search.interfaces';
import { Store } from '@ngrx/store';
import { CrossNumberSearchSelector } from 'src/app/features/parts/stores/parts.selector';
import { HttpClient } from '@angular/common/http';
import { ServiceMiddleware } from '../../Admin/service.layer/api.services.middleware';

@Injectable({
  providedIn: 'root',
})
export class CrossSearchService {
  searchText: string = '';

  constructor(
    private apiService: ApiService,
    private store: Store,
    private _http: HttpClient,
    private serviceMiddleware: ServiceMiddleware
  ) {
    this.store.select(CrossNumberSearchSelector).subscribe((data) => {
      this.searchText = data;
    });
  }

  public getCrossSearchList(page: number = 0, size?: number) {
    page += 1;
    return this.apiService.get<CrossSearchResponse>(
      `${environment.baseUrls.serviceURL}/cmt/clients/classicCrossSearch?partNumber=${this.searchText}`
    ); //&pageNumber=${page}&pageSize=${size}
  }

  public exportList() {
    return this.serviceMiddleware.exportList(
      `/cmt/clients/exportClassicCrossSearch?partNumber=${this.searchText}`
    );
  }
}
