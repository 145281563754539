import { downLoadFile } from 'src/app/core/utils';
import { getGridViewHearders, getGridViewRows } from '../pages/pivote-table/utils/headers';
import { ActionTypes } from './report.action';

export const INITIAL_ACL_STATE = {
  ExportReport: undefined,
  ExportPivoteTable: undefined,
  pivoteTable:{
    columns:[],
    data:[],
    message:undefined
  },
  SourceList: undefined
};

export function ReportsReducer(state = INITIAL_ACL_STATE, action: any): any {
  switch (action.type) {
    case ActionTypes.EXPORT_REPORT_By_ID: {
      return {
        ...state,
        ExportReport: undefined,
        loading: true,
        error: false,
      };
    }
    case ActionTypes.EXPORT_REPORT_By_ID_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        ExportReport: undefined,
        loading: false,
        error: undefined,

      };
    }
    case ActionTypes.EXPORT_REPORT_By_ID_ERROR: {
      return {
        ...state,
        ExportReport: action.payload,
        loading: true,
      };
    }

    case ActionTypes.EXPORT_PIVOTE_TABLE: {
      return {
        ...state,
        ExportPivoteTable: undefined,
        loading: true,
        error: false,
      };
    }
    case ActionTypes.EXPORT_PIVOTE_TABLE_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        ExportPivoteTable: undefined,
        loading: false,
        error: undefined,
      };
    }
    case ActionTypes.EXPORT_PIVOTE_TABLE_ERROR: {
      return {
        ...state,
        ExportPivoteTable: action.payload,
        loading: true,
      };
    }

    case ActionTypes.LOAD_PIVOTE_TABLE: {
      return {
        ...state,
        pivoteTable: {
          ...state.pivoteTable,
          message: undefined,
          error: undefined,
          data: undefined,
          loading: true,
        },
      };
    }
    case ActionTypes.LOAD_PIVOTE_TABLE_SUCCESS: {
        return {
        ...state,
        pivoteTable: {
          ...state.pivoteTable,
          headers: getGridViewHearders(action?.payload?.headers),
          data: getGridViewRows(action?.payload?.rows) ,
          message:action?.payload?.message,
          loading: false,
        },
      };
    }
    case ActionTypes.LOAD_PIVOTE_TABLE_ERROR: {
      return {
        ...state,
        pivoteTable: {
          ...state.pivoteTable,
          loading: false,
          error: action.payload,
          data: null,
         },
        }
      }
    case ActionTypes.GET_SOURCE_COLUMNS: {
      return {
        ...state,
        SourceList: {
          list: undefined,
          loading: true,
        },
        error: false,
      };
    }
    case ActionTypes.GET_SOURCE_COLUMNS_SUCCESS: {
      return {
        ...state,
        SourceList: {
          list: action.payload,
          loading: false,
        },
        error: undefined,

      };
    }
    case ActionTypes.GET_SOURCE_COLUMNS_ERROR: {
      return {
        ...state,
        SourceList: {
          list: undefined,
          loading: false,
        },
        loading: true,
        error: action.payload
      };
    }
  }
}
