import { ErrorHandler, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SetValidationError } from 'src/app/features/admin/stores/admin.action';
import { Store } from '@ngrx/store';
import { IAppState } from '../shared.interface';
@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(private store: Store<IAppState>) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
         if (error.error.validationErrors) {
           this.store.dispatch(
            new SetValidationError(error.error.validationErrors)
          );
          // alert( error.error.validationErrors);
        } else {
          console.log(error.error.message);
          // alert(error.error.message);
        }
        return throwError(error.message);
      })
    );
  }
}
