import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PopoverComponent, Position } from '@progress/kendo-angular-tooltip';
import { PopoverContainerComponent } from '../popover/popover.component';

@Component({
  selector: 'app-button-component',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppButtonComponent implements OnChanges {
  @Input() value = '';
  @Input() themeColor?: any = 'primary';
  @Input() openedComponent: any;
  @Input() disabled = false;
  @Input() disabledDropDown = false;
  @Input() buttonType = 'button';
  @Input() showPopoverOption: any = 'hover';
  @Input() position: Position = 'left';
  @Input() buttonIcon = '';
  @Input() dropDownItems: any;
  @Input() hasPopOver: boolean = false;
  @Input() showdialog: boolean = false;
  @Input() htmlData: string = '';
  @Input() popOverContent = '';
  @Output() clickButtonEvent = new EventEmitter();
  @Output() clickSaveFilter = new EventEmitter();
  @Output() clickDropoDownEvent = new EventEmitter();
  @Output() clickDropDownItemEvent = new EventEmitter();
  @ViewChild('popoverContainer') popoverContainer: PopoverContainerComponent;

  public popover: PopoverComponent;

  constructor(private cdr: ChangeDetectorRef) {
    }
  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (this.hasPopOver) {
        this.popover = this.popoverContainer?.popover;
        this.cdr.detectChanges();
      }
    }, 100);
    
  }

  public onDropDpwnClick(value: any): void {
    this.clickDropoDownEvent.emit(value);
  }

  public onDropdownItemClick(dataItem: any): void {
    if (dataItem) {
      this.clickDropDownItemEvent.emit(dataItem);
    }
  }

  public onButtonClick() {
   
    this.clickButtonEvent.emit();
  }
}
