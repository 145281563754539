
import { Action } from '@ngrx/store';
import { FilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { IFilterModel, ISortModel } from 'src/app/shared/shared.interface';


export enum ActionTypes {
  VALIDATE_ENGINEER_FILE_STATUS = 'VALIDATE_ENGINEER_FILE_STATUS',
  VALIDATE_ENGINEER_FILE_STATUS_SUCCESS = 'VALIDATE_ENGINEER_FILE_STATUS_SUCCESS',
  VALIDATE_ENGINEER_FILE_STATUS_ERROR = 'VALIDATE_ENGINEER_FILE_STATUS_ERROR',
  SET_CURRENT_ACTION_DATA = 'SET_CURRENT_ACTION_DATA',

  DISCARD_ENGINEER_FILE = 'DISCARD_ENGINEER_FILE',
  DISCARD_ENGINEER_FILE_SUCCESS = 'DISCARD_ENGINEER_FILE_SUCCESS',
  DISCARD_ENGINEER_FILE_ERROR = 'DISCARD_ENGINEER_FILE_ERROR',

  SUBMIT_ENGINEER_FILE = 'SUBMIT_ENGINEER_FILE',
  SUBMIT_ENGINEER_FILE_SUCCESS = 'SUBMIT_ENGINEER_FILE_SUCCESS',
  SUBMIT_ENGINEER_FILE_ERROR = 'SUBMIT_ENGINEER_FILE_ERROR',

  GET_UPLOADED_BOMS = 'GET_UPLOADED_BOMS',
  GET_UPLOADED_BOMS_SUCCESS = 'GET_UPLOADED_BOMS_SUCCESS',
  GET_UPLOADED_BOMS_ERROR = 'GET_UPLOADED_BOMS_ERROR',
  GET_BOM_DETAILS_GRID_BY_ID = 'GET_BOM_DETAILS_GRID_BY_ID',
  GET_BOM_DETAILS_GRID_BY_ID_SUCCESS = 'GET_BOM_DETAILS_GRID_BY_ID_SUCCESS',
  GET_BOM_DETAILS_GRID_BY_ID_ERROR = 'GET_BOM_DETAILS_GRID_BY_ID_ERROR',
   SET_BOM_DATA = 'SET_BOM_DATA',
  BOM_DELETE_RECORD = 'BOM_DELETE_RECORD',
  BOM_DELETE_RECORD_SUCCESS = 'BOM_DELETE_RECORD_SUCCESS',
  BOM_DELETE_RECORD_ERROR = 'BOM_DELETE_RECORD_ERROR',
  BOM_GRID_SORT_CHANGED = 'BOM_GRID_SORT_CHANGED',
  BOM_GRID_FiLTER_CHANGED = 'BOM_GRID_FiLTER_CHANGED',
  BOM_GRID_PAGE_CHANGED = 'BOM_GRID_PAGE_CHANGED',
  BOM_GRID_PAGE_SIZE_CHANGED = 'BOM_GRID_PAGE_SIZE_CHANGED',
  GET_ENG_BOM_DETAILS_BY_ID = 'GET_ENG_BOM_DETAILS_BY_ID',
  GET_ENG_BOM_DETAILS_BY_ID_SUCCESS = 'GET_ENG_BOM_DETAILS_BY_ID_SUCCESS',
  GET_ENG_BOM_DETAILS_BY_ID_ERROR = 'GET_ENG_BOM_DETAILS_BY_ID__ERROR',
  EXPORT_BOM_DETAILS = 'EXPORT_BOM_DETAILS',
  EXPORT_BOM_DETAILS_SUCCESS = 'EXPORT_BOM_DETAILS_SUCCESS',
  EXPORT_BOM_DETAILS_ERROR = 'EXPORT_BOM_DETAILS_ERROR',
}
export class GetLastEngineerFileStatus implements Action {
  readonly type = ActionTypes.VALIDATE_ENGINEER_FILE_STATUS;
  constructor() { }
}
export class GetLastEngineerFileStatusSuccess implements Action {
  readonly type = ActionTypes.VALIDATE_ENGINEER_FILE_STATUS_SUCCESS;
  constructor(public payload: any) { }
}
export class GetLastEngineerFileStatusError implements Action {
  readonly type = ActionTypes.VALIDATE_ENGINEER_FILE_STATUS_ERROR;
  constructor(public payload: any) { }
}

//discard engineer file 
export class DiscardEngineerFile implements Action {
  readonly type = ActionTypes.DISCARD_ENGINEER_FILE;
  constructor(public filetId: number) { }
}
export class DiscardEngineerFileSucess implements Action {
  readonly type = ActionTypes.DISCARD_ENGINEER_FILE_SUCCESS;
  constructor(public payload: any) { }
}
export class DiscardEngineerFileError implements Action {
  readonly type = ActionTypes.DISCARD_ENGINEER_FILE_ERROR;
  constructor(public payload: any) { }
}

export class SubmitEngineerFile implements Action {
  readonly type = ActionTypes.SUBMIT_ENGINEER_FILE;
  constructor(public payload: any) { }
}
export class SubmitEngineerFileSuccess implements Action {
  readonly type = ActionTypes.SUBMIT_ENGINEER_FILE_SUCCESS;
  constructor(public payload: any) { }
}
export class SubmitEngineerFileError implements Action {
  readonly type = ActionTypes.SUBMIT_ENGINEER_FILE_ERROR;
  constructor(public payload: any) { }
}


export class GetUploadedBoms implements Action {
  readonly type = ActionTypes.GET_UPLOADED_BOMS;
  constructor(public page?: number,
    public size?: number,
    public filter?: IFilterModel[],
    public sort?: SortDescriptor[]) { }
}

export class GetUploadedBomsSuccess implements Action {
  readonly type = ActionTypes.GET_UPLOADED_BOMS_SUCCESS;
  constructor(public payload: ArrayBuffer) { }
}
export class bomGridSortChanged implements Action {
  readonly type = ActionTypes.BOM_GRID_SORT_CHANGED;
  constructor(public sort?: ISortModel) { }
}

export class bomGridFilterChanged implements Action {
  readonly type = ActionTypes.BOM_GRID_FiLTER_CHANGED;
  constructor(public filter?:  IFilterModel[]) { }
}
export class bomGridPageChanged implements Action {
  readonly type = ActionTypes.BOM_GRID_PAGE_CHANGED;
  constructor(public page?: number) { }
}
export class bomGridPageSizeChanged implements Action {
  readonly type = ActionTypes.BOM_GRID_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) { }}
export class SetCurrentActionData implements Action {
  readonly type = ActionTypes.SET_CURRENT_ACTION_DATA;
  constructor(public actionType?: string, public fileId?: string | null) {}
}
export class GetUploadedBomsError implements Action {
  readonly type = ActionTypes.GET_UPLOADED_BOMS_ERROR;
  constructor(public payload: any) { }
}

export class GetBomDetailsGridById implements Action {
  readonly type = ActionTypes.GET_BOM_DETAILS_GRID_BY_ID;
  constructor(
    public parentId?: number,
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]) { }
}

export class GetBomDetailsGridByIdSuccess implements Action {
  readonly type = ActionTypes.GET_BOM_DETAILS_GRID_BY_ID_SUCCESS;
  constructor(public payload: ArrayBuffer) { }
}

export class GetBomDetailsGridByIdError implements Action {
  readonly type = ActionTypes.GET_BOM_DETAILS_GRID_BY_ID_ERROR;
  constructor(public payload: any) { }
}

export class SetBomData implements Action {
  readonly type = ActionTypes.SET_BOM_DATA;
  constructor(public bomName?: string, public bomId?: number | null) {}
}

export class bomDelete implements Action {
  readonly type = ActionTypes.BOM_DELETE_RECORD;
  constructor(public bomId: number) {}
}

export class bomDeleteSuccess implements Action {
  readonly type = ActionTypes.BOM_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class bomDeleteError implements Action {
  readonly type = ActionTypes.BOM_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class getEngBomDetailsById implements Action {
  readonly type = ActionTypes.GET_ENG_BOM_DETAILS_BY_ID;
  constructor(public whereUsed: number) {}
}

export class getEngBomDetailsByIdSuccess implements Action {
  readonly type = ActionTypes.GET_ENG_BOM_DETAILS_BY_ID_SUCCESS;
  constructor(public payload: any) {}
}

export class getEngBomDetailsByIdError implements Action {
  readonly type = ActionTypes.GET_ENG_BOM_DETAILS_BY_ID_ERROR;
  constructor(public payload: any) {}
}


export class exportBomDetails implements Action {
  readonly type = ActionTypes.EXPORT_BOM_DETAILS;
  constructor(public payload: any,public bomId?: any, public fileName?: any) {}
}

export class exportBomDetailsSucces implements Action {
  readonly type = ActionTypes.EXPORT_BOM_DETAILS_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}

export class exportBomDetailsError implements Action {
  readonly type = ActionTypes.EXPORT_BOM_DETAILS_ERROR;
  constructor(public payload: any) {}
}

