import {
  IFilterModel,
  IGridCustomRequest,
  IGridQuoteDetailsRequest,
  IGridRequest,
  ISortModel,
} from 'src/app/shared/shared.interface';
import { IMappedDataRequest } from './quotes.interfaces';

export function gridRequestAlerts(
  page?: number,
  size?: number,
  sortByModel?: ISortModel,
  filterModel?: IFilterModel[]
) {
  const request: IGridRequest = {
    filterModel: filterModel ? filterModel : [],
    page: page ? page : 0,
    size: size ? size : 10,
    sortByModel: sortByModel
      ? sortByModel
      : { columnName: 'alertTitle', direction: 'asc' },
  };

  return request;
}
//(mappedColumns, start, this.fileId,tempQuoteId)
export function MappedDataRequest(
  mappedColumns: any,
  startRow: number,
  fileId: number,
  tempQuoteId: string
) {
  const request: IMappedDataRequest = {
    mappedColumns: mappedColumns,
    startRow: startRow,
    fileId: fileId + '',
    tempQuoteId: tempQuoteId,
  };

  return request;
}

export function gridRequestQuoteDetails(
  page?: number,
  size?: number,
  sortByModel?: ISortModel,
  filterModel?: IFilterModel[],
  quoteId?: number
) {
  const request: IGridQuoteDetailsRequest = {
    filterModel: filterModel ? filterModel : [],
    quoteId: quoteId ? quoteId : 0,
    page: page ? page : 0,
    size: size ? size : 10,
    sortByModel: sortByModel ? sortByModel : { columnName: 'cpn', direction: 'asc' },
  };

  return request;
}
