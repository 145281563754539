import { actionMenu, GridHeaderData } from 'src/app/shared/shared.interface';
import { AddInvitationComponent } from '../pages/add-invitation/add-invitation.component';
import { EditRegisteredComponent } from '../pages/edit-registered/edit-registered.component';

export const headers: GridHeaderData[] = [
  {
    field: 'firstName',
    title: 'First Name',
    filterable: true,
    filterColName:'firstName'
  },
  {
    field: 'lastName',
    title: 'Last Name',
    filterable: true,
    filterColName:'lastName'
  },
  {
    field: 'email',
    title: 'Email',
    filterable: true,
    filterColName:'email'
  },
  {
    field: 'phone',
    title: 'Phone',
    filterable: true,
    filterColName:'phone'
  },
  {
    field: 'companyName',
    title: 'Company',
    filterable: true,
    filterColName:'companyName'
  },
  {
    field: 'contractManufacturerSite',
    title: 'Site Name',
    filterable: true,
    filterColName:'cmSiteName'
  },
  {
    field: 'region',
    title: 'Region',
    filterable: true,
    filterColName:'region'
  },

  {
    field: 'roleName',
    title: 'Role',
    filterable: true,
    filterColName:'roleName'

  },
  {
    field: 'title',
    title: 'Title',
    filterable: true,
    filterColName:'title'

  },
  {
    field: 'companyTypeName',
    title: 'Company Type',
    filterable: true,
    filterColName:'companyTypeName'

  },
  {
    field: 'insertionDate',
    title: 'Insertion Date',
    filterable: true,
    filterColName:'insertionDate',
    isDate:true,
  },
  {
    actions: [
      {
        componentData: {
          componentTilte: 'Edit User',
          componentName: AddInvitationComponent,
          open: true,
        },
        actionType: actionMenu.edit,
      },
      {
        componentData: {},
        actionType: actionMenu.delete,
      },
      {
        componentData: {},
        actionType: actionMenu.resend,
      },
    ],
    title: 'Actions',
    field: 'Actions',
  },
];

export const registeredUserHaders: GridHeaderData[] = [
  {
    field: 'firstName',
    title: 'First Name',
    filterable: true,
    filterColName:'firstName'
  },
  {
    field: 'lastName',
    title: 'Last Name',
    filterable: true,
    filterColName:'lastName',
  },

  {
    field: 'email',
    title: 'Email',
    filterable: true,
    filterColName:'email',
  },
  {
    field: 'phone',
    title: 'Phone',
    filterable: true,
    filterColName:'phone',

  },
  {
    field: 'region',
    title: 'Region',
    filterable: true,
    filterColName: 'region',

  },
  {
    field: 'title',
    title: 'Title',
    filterable: true,
    filterColName: 'title',

  },
  {
    field: 'companyName',
    title: 'Company',
    filterable: true,
    filterColName: 'companyName',

  },
  {
    field: 'cmSiteName',
    title: 'Site Name',
    filterable: true,
    filterColName:'cmSiteName'
  },
  {
    field: 'companyTypeName',
    title: 'Company Type',
    filterable: true,
    filterColName: 'companyTypeName',

  },
  {
    field: 'roleName',
    title: 'Role',
    filterable: true,
    filterColName: 'roleName',

  },
  {
    field: 'status',
    title: 'Login Status',
    filterable: true,
    filterColName: 'status',

  },
  {
    actions: [
      {
        componentData: {
          componentTilte: 'Edit User',
          componentName: EditRegisteredComponent,
          open: true,
        },
        actionType: actionMenu.edit,
      },
      {
        componentData: {},
        actionType: actionMenu.delete,
      },
    ],
    title: 'Actions',
    field: 'Actions',
  },
];
export function uploadedGridDDL(features: any = []): GridHeaderData[] {
  return [
    {
      field: 'UnMapped',
      title: 'UnMapped',
      required: false,
      index: -1,
    },
    ...features?.map((c: any) => {
      return {
        index: c.index,
        mappedIndexes: c.index != null ? [c.index] : [],
        field: c.featureId,
        title: c.featureName,
        required: c.required,
      };
    }),
  ];
}