<kendo-dropdownlist
  [data]="data"
  [defaultItem]="defaultItem"
  [textField]="textField"
  [valueField]="valueField"
  [valuePrimitive]="valuePrimitive"
  [filterable]="filterable"
  (filterChange)="handleFilter($event)"
  [itemDisabled]="itemDisabled"
  (selectionChange)="handleSelectionChange($event)"
  (valueChange)="handleValueChange($event)"
   [(ngModel)]="selectedItem"
>
</kendo-dropdownlist>
