import * as _ from 'lodash';
import { IContractManu } from './contract-manu.interfaces';
import { SortDescriptor } from '@progress/kendo-data-query';

export function flatCmData(data: IContractManu[]): IContractManu[] {
  let flattedData: IContractManu[] = [];
  data?.forEach((element) => {
    var elem: IContractManu = {
      contractManufacturerId: element.contractManufacturerId,
      contractManufacturerName: element.contractManufacturerName,
    };
    flattedData.push(elem);
  });
  return flattedData;
}
