import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import {
  actionMenu,
  actions,
  ColumnType,
  GridHeaderData,
} from 'src/app/shared/shared.interface';
import {
  ICurrencyExchange,
  IYearCurrencyExchange,
} from './year-currencies.interface';

export function flatExchangeData(data: IYearCurrencyExchange[]) {
  let gridData: any[] = [];
  var _temp: any = {};
  if (_.isArray(data)) {
    data?.forEach((row) => {
      (_temp['yearId'] = row.yearId),
        (_temp['Year'] = row.year),
        (_temp['isLocked'] = row.isLocked),
        row.currencyExchanges.forEach((exchange) => {
          _temp[exchange.currencyName] = exchange.exchangeValue;
          _temp[exchange.currencyName + 'Id'] = exchange.currencyId;
          _temp[exchange.currencyName + 'exchange_Id'] =
            exchange.yearCurrencyId;
        });

      gridData.push(cloneDeep(_temp));
    });
  } 
  return gridData;
}

export function getDynamicHeaders(
  gridData: any[],
  addComponent: any,
  title?: string
) {
  let headers: GridHeaderData[] = [];
  var row = gridData.length ? gridData[0] : {};
  if (row) {
    Object?.keys(row)?.forEach((key) => {
      if (key.indexOf('Id') < 0 && key.indexOf('isLocked') < 0) {
        headers.push({
          sortable:false,
          title: key,
          field: key,
        });
      }
    });
  }
  const actions: actions[] = [
    {
      componentData: {
        componentTilte: title,
        componentName: addComponent,
        open: true,
        
      },
      actionType: actionMenu.edit,
    },
    {
      componentData: {},
      actionType: actionMenu.delete,
    },
  ];
  headers.push({
    title: 'is Locked',
    field: 'isLocked',
    type: ColumnType.CHECKBOX,
    sortable:false
  });
  headers.push({ title: 'Actions', field: 'Actions', actions: actions });

  return headers;
}

export function revertToAPIRequest(flattedData: any) {
  let currencyExchanges: ICurrencyExchange[] = [];
  let currencyData;
  let keys = Object.keys(flattedData);
  keys = keys.sort();
  let prevKey = keys[0];
  keys.forEach((key) => {
    if (key.includes(prevKey)) {
      currencyData = Object.keys(flattedData).filter(
        (x) =>
          x.includes(prevKey) &&
          x !== 'year' &&
          x !== 'yearId' &&
          x !== 'isLocked'
      );
      if (currencyData.length === 3) {
        currencyExchanges.push({
          currencyName: currencyData[0],
          exchangeValue: flattedData[currencyData[0]],
          currencyId: flattedData[currencyData[1]],
          yearCurrencyId: flattedData[currencyData[2]],
        });
      }
    }
    prevKey = key;
  });
  const seen = new Set();
  const filteredArr = currencyExchanges.filter((el) => {
    const duplicate = seen.has(el.currencyId);
    seen.add(el.currencyId);
    return !duplicate;
  });
  return {
    year: flattedData.Year,
    isLocked: flattedData.isLocked,
    yearId: flattedData.yearId,
    currencyExchanges: filteredArr,
  };
}
