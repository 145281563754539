import { Injectable, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthTokenService } from './auth-token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private authTokenService: AuthTokenService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      this.authTokenService.getValidAccessToken() ||
      this.authTokenService.getValidRefreshToken()
    ) {
      return true;
    }
    
    this.router.navigateByUrl('/login');
    return false;
  }
  canLoad(route: Route, segments: UrlSegment[]) {
    if (
      this.authTokenService.getValidAccessToken() ||
      this.authTokenService.getValidRefreshToken()
    ) {
      return true;
    }
    this.router.navigateByUrl('/login');
    return false;
  }
}
