import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  ICompanyType,
  IRoleList,
} from 'src/app/core/Admin/users/invited-users/invited-users.interfaces';
import { validateEmail } from 'src/app/core/utils';
import {
  GetCMSiteName,
  GetCompanyByTypeName,
  GetCompanyTypeList,
  GetRoleList,
  InvitedAdd,
  InvitedEdit,
  InvitedUsersLoadData,
  SetValidationError,
} from 'src/app/features/admin/stores/admin.action';
import {
  CompanySelector,
  CompanyTypeSelector,
  GetCmSiteSelector,
  GetValidationErrorSelector,
  InvitedGridDataSelector,
  InvitedselectedFiltersSelector,
  RoleSelector,
  selectedFiltersSelector,
} from 'src/app/features/admin/stores/admin.selector';
import { CustomValidators } from 'src/app/shared/classes/custom-validator';
import { ProviderClass } from 'src/app/shared/classes/ProviderClass';
import { DialogShareService } from 'src/app/shared/components/Modals/dialog.service';
import { Dialog } from 'src/app/shared/models/model.interface';
import {
  actionComponentData,
  IAppState,
} from 'src/app/shared/shared.interface';

@Component({
  selector: 'app-add-invitation',
  templateUrl: './add-invitation.component.html',
  styleUrls: ['./add-invitation.component.scss'],
})
export class AddInvitationComponent implements OnInit, OnDestroy {
  CompanyList: any[] = [];
  saveData: boolean = false;
  IsDisabled: boolean = false;
  CmSiteDisabled: boolean = true;


  RolelistItems: IRoleList[] = [];
  // InvitedUserForm;
  CompanyTypes: ICompanyType[] = [];
  emailError: string = '';
  companyTypeError: string = '';
  companyError: string = '';
  roleError: string = '';
  error: any;
  IsError: boolean = false;
  subscription: Subscription = new Subscription();
  Email: string = '';
  InvitationId: number = 0;
  roleName: string = '';
  roleId: any;
  companyTypeId: any;
  role: number = 0;
  companyId: number = 0;
  companyType: string = '';
  Company: any;
  firstName: string = '';
  lastName: string = '';
  phone: string = '';
  region: string = '';
  title: string = '';
  gridFilters = {
    filter: undefined,
    sort: undefined,
    page: 0,
    pageSize: 10,
  };
  selectedData: actionComponentData = {};
  edit: boolean = false;
  cmSiteListItemsSource: any;
  cmSiteListItems: any;
  cmSiteError: string = '';
  WabtecType: string = 'Wabtec';
  siliconExpertType: string = 'siliconexpert';
  cmSite: any;
  invitedUserForm: FormGroup;

  constructor(private fb: FormBuilder,
    public dataIn: ProviderClass,
    private dialogService: DialogShareService,
    private store: Store<IAppState>
  ) {
    this.invitedUserForm = this.fb.group({
      firstName: ['', [CustomValidators.cannotContainSpace,
      CustomValidators.cannotStartWithWhiteSpaceValidator,
      CustomValidators.cannotEndWithWhiteSpaceValidator,
      CustomValidators.cannotBeSpecialCharsOnlyValidator,
      CustomValidators.cannotBeNumbersOnlyValidator,
      Validators.minLength(1),
      Validators.maxLength(16)]],
      lastName: ['', [CustomValidators.cannotContainSpace,
      CustomValidators.cannotStartWithWhiteSpaceValidator,
      CustomValidators.cannotEndWithWhiteSpaceValidator,
      CustomValidators.cannotBeSpecialCharsOnlyValidator,
      CustomValidators.cannotBeNumbersOnlyValidator,
      Validators.minLength(1),
      Validators.maxLength(16)]],
      region: ['', [CustomValidators.cannotContainSpace,
      CustomValidators.cannotStartWithWhiteSpaceValidator,
      CustomValidators.cannotEndWithWhiteSpaceValidator,
      CustomValidators.cannotBeSpecialCharsOnlyValidator,
      CustomValidators.cannotBeNumbersOnlyValidator,
      Validators.minLength(1),
      Validators.maxLength(45)]],
      email:['',Validators.maxLength(64)],
      title: ['', [CustomValidators.cannotContainSpace,
      CustomValidators.cannotStartWithWhiteSpaceValidator,
      CustomValidators.cannotEndWithWhiteSpaceValidator,
      CustomValidators.cannotBeSpecialCharsOnlyValidator,
      CustomValidators.cannotBeNumbersOnlyValidator,
      Validators.minLength(1),
      Validators.maxLength(45)]],
      phone: ['', [Validators.maxLength(45)]],
      Company:['',Validators.required],
    });
    this.loadData();

  }
  CompanyTypeplaceHolder: ICompanyType = {
    companyTypeId: 0,
    companyTypeName: 'Select Company Type',
  };
  RoleplaceHolder: IRoleList = {
    roleId: 0,
    roleName: 'Select Role',
  };
  ngOnInit(): void {

    this.selectedData = this.dataIn;
    if (this.selectedData?.data) {
      this.getEditData();
      this.store.dispatch(new GetRoleList(this.selectedData.data.companyTypeName));
      this.edit = true;
    }
    this.startSubscribtion();
  }
  startSubscribtion() {
    this.subscription.add(this.subscribeToDialog());
    this.subscription.add(this.subscribeCompanyTypeListItems());
    this.subscription.add(this.subscribeRoleListItems());
    this.subscription.add(this.subscribeCompanyListItems());
    this.subscription.add(this.subscribeToFilterSelector());
    this.subscription.add(this.subscribeToAddEditSelector());
    this.subscription.add(this.subscribeToCMSiteSelector());
  }
  subscribeToFilterSelector() {
    return this.store.select(InvitedselectedFiltersSelector).subscribe((data) => {
      if ((data: any) => data) {
        this.gridFilters.filter = data.filter;
        this.gridFilters.sort = data.sort;
        this.gridFilters.pageSize = data.pageSize;
        this.gridFilters.page = data.page;
      }
    });
  }
  getSelectedCompanyTypeItem($event: any) {
    this.CompanyList = [];
    this.Company = '';
    this.companyId = $event.companyTypeId;
    this.companyType = $event.companyTypeName;
    this.role = 0;
    // if (this.companyType.toUpperCase() === this.WabtecType.toUpperCase()) {
    //   this.Company = 'wabtec';
    //   this.IsDisabled = true;
    //   this.cmSite = '';
    //   this.CmSiteDisabled = true;
    // }
    // if (this.companyType.toUpperCase() === this.siliconExpertType.toUpperCase()) {
    //   this.Company = 'SiliconExpert';
    //   this.IsDisabled = true;
    //   this.cmSite = '';
    //   this.CmSiteDisabled = true;
    // }
    if(this.companyId== 1){
      this.Company=this.companyType;
      this.invitedUserForm.controls['Company'].setValue(this.companyType);

      this.IsDisabled=true;
      this.cmSite = '';
      this.CmSiteDisabled = true;
    }
    else {
      if (this.companyType === 'Contract Manufacturer') {
        this.CmSiteDisabled = false;

      }
      else {
        this.IsDisabled = false;
        this.CmSiteDisabled = true;
        this.cmSite = '';
      }
    }

    this.store.dispatch(new GetRoleList(this.companyType));

  }
  itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index === 1; // disable the 3rd item
  }
  getSelectedRole($event: any) {
    this.role = $event.roleId;
  }
  getEditData() {
    (this.Email = this.selectedData.data.email),
      (this.InvitationId = this.selectedData.data.invitationId),
      (this.companyId = this.selectedData.data.companyTypeId),
      (this.role = this.selectedData.data.roleId),
      (this.Company = this.selectedData.data.companyName);
      this.invitedUserForm.controls['Company'].setValue(this.selectedData?.data?.companyName);

    if (this.selectedData.data.companyTypeName === 'Contract Manufacturer' && this.companyId) {

      this.store.dispatch(new GetCMSiteName(this.selectedData.data.companyId));

    }
    (this.cmSite = this.selectedData.data.contractManufacturerSite);
    (this.region = this.selectedData.data.region);
    (this.title = this.selectedData.data.title);
    (this.phone = this.selectedData.data.phone);
    (this.firstName = this.selectedData.data.firstName);
    (this.lastName = this.selectedData.data.lastName);

    if (this.Company === 'wabtec') {
      this.IsDisabled = true;
    }
    else if (this.selectedData.data.companyTypeName === 'Contract Manufacturer') {
      this.CmSiteDisabled = false;

    }

  }
  loadData() {
    this.store.dispatch(new GetCompanyTypeList());
  }
  subscribeToAddEditSelector() {
    this.store.dispatch(new SetValidationError(''));

    return this.store.select(InvitedGridDataSelector).subscribe((res: any) => {

      if ((res?.add?.invitationId || res?.edit?.invitationId) && this.saveData) {
        this.saveData = false;
        this.store.dispatch(
          new InvitedUsersLoadData(
            this.gridFilters.page,
            this.gridFilters.pageSize,
            this.gridFilters.filter,
            this.gridFilters.sort
          )
        );
        this.dialogService.sendMessage(Dialog.close);
      }

      else if (this.saveData) {
        this.store.select(GetValidationErrorSelector).subscribe((error) => {
          if (error.error != undefined) {
            this.error = error.error;
            this.IsError = error.isError;
          }
        });
      }
    });
  }
  subscribeCompanyTypeListItems() {
    return this.store.select(CompanyTypeSelector).subscribe((res) => {
      if (res?.length) {
        this.CompanyTypes = res;
        this.CompanyTypes = this.CompanyTypes.slice();
      }
    });
  }
  subscribeRoleListItems() {
    return this.store.select(RoleSelector).subscribe((res) => {
      if (res?.length === 1) {
        this.RolelistItems = res;
        this.RolelistItems = this.RolelistItems.slice();
        this.role = res[0].roleId;
      }
      else if (res?.length > 1) {
        if (!this.edit) {
          this.role = 0;
        }
        this.RolelistItems = res;
        this.RolelistItems = this.RolelistItems.slice();
      }
    });
  }
  subscribeToCMSiteSelector() {
    this.store.select(GetCmSiteSelector).subscribe((response) => {

      if (response.data?.length) {
        this.cmSiteListItemsSource = response.data;
        this.cmSiteListItemsSource = this.cmSiteListItemsSource.sort((a: any, b: any) =>
          a.cmSiteName.localeCompare(b.cmSiteName)
        );
        this.cmSiteListItems = this.cmSiteListItemsSource.slice();
      }
    });
  }
  companyChange(value: any): void {
    this.store.dispatch(new GetCMSiteName(value.id));
  }
  handleFilterCMsite(value: string) {

    this.cmSiteListItems = this.cmSiteListItemsSource.filter(
      (s: any) =>
        s.cmSiteName
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  }
  click(value: any) {
    if (value.length >= 2) {
      return this.store.dispatch(
        new GetCompanyByTypeName(this.companyId, value)
      );
    } else {
      this.CompanyList = [];
    }
  }
  subscribeCompanyListItems() {
    return this.store.select(CompanySelector).subscribe((res) => {
      if (res?.length) {
        this.CompanyList = res;
      }
    });
  }
  valueChange(value: string): void {
    return this.store.dispatch(new GetCompanyByTypeName(this.companyId, value));
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  subscribeToDialog() {
    return this.dialogService.getMessage().subscribe((data) => {
      if (data.res == Dialog.submit) {
        this.selectedData = {};
        this.save();
      }
    });
  }
  save() {
    if (this.validate()) {
      this.saveData = true;
      let newPhone = null;

      if (this.phone != '') {
        newPhone = '+' + this.phone?.substring(0, 3).trim() + ' - ' + this.phone?.substring(3, this.phone?.length).trim();
      }
    this.Company=this.invitedUserForm.controls['Company'].value?.id?this.invitedUserForm.controls['Company'].value?.name:this.invitedUserForm.controls['Company'].value;
      let obj = {
        invitationId: this.InvitationId,
        email: this.Email,
        companyTypeId: this.companyId,
        companyName: this.Company.id ? this.Company.name : this.Company,
        roleId: this.role,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: newPhone,
        region: this.region,
        title: this.title,
      } as any;

      if (this.companyId && this.Company) {
        obj.cmSiteName = this.cmSite?.cmSiteName ? this.cmSite?.cmSiteName : this.cmSite ? this.cmSite : ''
      }
      if (this.edit) {
        this.store.dispatch(
          new InvitedEdit(obj)
        );
      } else {
        this.store.dispatch(
          new InvitedAdd(obj)
        );
      }
    }
    else {
      this.store.dispatch(new SetValidationError(''));
      this.error = '';
      this.IsError = false;
    }
  }
  validate() {
    let validate = true;

    this.emailError = '';
    this.companyTypeError = '';
    this.companyError = '';
    this.roleError = '';
    this.cmSiteError = '';
    if (this.companyId === null || this.companyId === 0) {
      this.companyTypeError = 'Company Type is required';
      validate = false;
    }
    this.Company=this.invitedUserForm.controls['Company'].value?.id?this.invitedUserForm.controls['Company'].value?.name:this.invitedUserForm.controls['Company'].value;
    if (this.Company === '') {
      this.companyError = 'Company Name is required';
      validate = false;
    } else if (this.Company?.length < 1) {
      this.companyError = 'Company Name lenght should be 1 character at least';
      validate = false;
    }
    if (this.companyType === 'Contract Manufacturer' && this.Company && !this.cmSite) {
      this.cmSiteError = 'CM Site is required';
      validate = false;
    }
    if(this.Company && this.cmSite && this.cmSite.length > 100){
      this.cmSiteError = 'Max length is 100 chars';
      validate = false;
    }

    if (this.role == null || this.role == 0) {
      this.roleError = 'Role Name is required';
      validate = false;
    }
    if (this.Email === '') {
      this.emailError = 'email is required';
      validate = false;
    }
    if (!validateEmail(this.Email)) {

      this.emailError = 'email is not valid';
      validate = false;
    }
    return this.invitedUserForm?.valid && validate;
  }
}
