import { Action } from '@ngrx/store';
import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import {
  LoaResponseDTO,
  QuoteUpdateObj,
  QuoteUploadOption,
} from 'src/app/core/Admin/quotes/quotes.interfaces';
import { exportNormalizedGridOptions as ExportNormalizedGridOptionsEnum } from 'src/app/core/Admin/quotes/quotes.interfaces';
import { GridHeaderData } from 'src/app/shared/shared.interface';

export enum ActionTypes {
  QUOTE_LOAD_DATA = 'QUOTE_LOAD_DATA_RECORD',
  QUOTE_LOAD_DATA_SUCCESS = 'QUOTE_LOAD_DATA_RECORD_SUCCESS',
  QUOTE_LOAD_DATA_ERROR = 'QUOTE_LOAD_DATA_RECORD_ERROR',

  UPDATE_COM_ID = 'UPDATE_COM_ID',
  UPDATE_COM_ID_SUCCESS = 'UPDATE_COM_ID_SUCCESS',
  UPDATE_COM_ID_ERROR = 'UPDATE_COM_ID_ERROR',

  EMPTY_FILE = 'EMPTY_FILE',

  SAVE_UPLOADED_FILE = 'SAVE_UPLOADED_FILE',
  SAVE_UPLOADED_FILE_SUCCESS = 'SAVE_UPLOADED_FILE_SUCCESS',
  SAVE_UPLOADED_FILE_ERROR = 'SAVE_UPLOADED_FILE_ERROR',

  UPDATE_QUOTE_PART_DETAILS = 'UPDATE_QUOTE_PART_DETAILS',
  UPDATE_QUOTE_PART_DETAILS_SUCCESS = 'UPDATE_QUOTE_PART_DETAILS_SUCCESS',
  UPDATE_QUOTE_PART_DETAILS_ERROR = 'UPDATE_QUOTE_PART_DETAILS_ERROR',


  PRICE_HISTORY_LOAD_DATA = 'PRICE_HISTORY_LOAD_DATA',
  PRICE_HISTORY_LOAD_DATA_SUCCESS = 'PRICE_HISTORY_LOAD_DATA_SUCCESS',
  PRICE_HISTORY_LOAD_DATA_ERROR = 'PRICE_HISTORY_LOAD_DATA_ERROR',

  QUOTE_MAN_LOAD_FILTERED_DATA = 'QUOTE_MAN_LOAD_FILTERED_DATA',
  QUOTE_MAN_LOAD_FILTERED_DATA_SUCCESS = 'QUOTE_MAN_LOAD_FILTERED_DATA_SUCCESS',
  QUOTE_MAN_LOAD_FILTERED_DATA_ERROR = 'QUOTE_MAN_LOAD_FILTERED_DATA_ERROR',

  QUOTE_MAN_LOAD_DATA = 'QUOTE_MAN_LOAD_DATA',
  QUOTE_MAN_LOAD_DATA_SUCCESS = 'QUOTE_MAN_LOAD_DATA_SUCCESS',
  QUOTE_MAN_LOAD_DATA_ERROR = 'QUOTE_MAN_LOAD_DATA_ERROR',

  CONTRACT_MAN_SITES_LOAD_DATA = 'CONTRACT_MAN_LOAD_DATA',
  CONTRACT_MAN_SITES_LOAD_DATA_SUCCESS = 'CONTRACT_MAN_LOAD_DATA_SUCCESS',
  CONTRACT_MAN_SITES_LOAD_DATA_ERROR = 'CONTRACT_MAN_LOAD_DATA_ERROR',

  QUOTE_DIS_LOAD_DATA = 'QUOTE_DIS_LOAD_DATA',
  QUOTE_DIS_LOAD_DATA_SUCCESS = 'QUOTE_DIS_LOAD_DATA_SUCCESS',
  QUOTE_DIS_LOAD_DATA_ERROR = 'QUOTE_DIS_LOAD_DATA_ERROR',

  QUOTE_MAN_SELECT_DATA = 'QUOTE_MAN_SELECT_DATA',
  QUOTE_MAN_SELECT_DATA_SUCCESS = 'QUOTE_MAN_SELECT_DATA_SUCCESS',
  QUOTE_MAN_SELECT_DATA_ERROR = 'QUOTE_MAN_SELECT_DATA_ERROR',

  QUOTE_DIS_SELECT_DATA = 'QUOTE_DIS_SELECT_DATA',
  QUOTE_DIS_SELECT_DATA_SUCCESS = 'QUOTE_DIS_SELECT_DATA_SUCCESS',
  QUOTE_DIS_SELECT_DATA_ERROR = 'QUOTE_DIS_SELECT_DATA_ERROR',

  GENERATE_LOAD_FILE = 'GENERATE_LOAD_FILE',
  GENERATE_LOAD_FILE_SUCCESS = 'GENERATE_LOAD_FILE_SUCCESS',
  GENERATE_LOAD_FILE_ERROR = 'GENERATE_LOAD_FILE_ERROR',

  DELETE_LOA = 'DELETE_LOA',
  DELETE_LOA_SUCCESS = 'DELETE_LOA_SUCCESS',
  DELETE_LOA_ERROR = 'DELETE_LOA_ERROR',

  UPDATE_QUOTE = 'UPDATE_QUOTE',
  UPDATE_QUOTE_SUCCESS = 'UPDATE_QUOTE_SUCCESS',
  UPDATE_QUOTE_ERROR = 'UPDATE_QUOTE_ERROR',

  QUOTE_DELETE_RECORD = 'QUOTE_DELETE_RECORD',
  QUOTE_DELETE_RECORD_SUCCESS = 'QUOTE_DELETE_RECORD_SUCCESS',
  QUOTE_DELETE_RECORD_ERROR = 'QUOTE_DELETE_RECORD_ERROR',

  QUOTE_Update_Active = 'QUOTE_Update_Active',
  QUOTE_Update_Active_SUCCESS = 'QUOTE_Update_Active_SUCCESS',
  QUOTE_Update_Active_ERROR = 'QUOTE_Update_Active_ERROR',
  

  QUOTE_DELETE_EMPTY_SELECTOR = 'QUOTE_DELETE_EMPTY_SELECTOR',

  GET_QUOTE_BY_ID = 'GET_QUOTE_BY_ID',
  GET_QUOTE_BY_ID_SUCCESS = 'GET_QUOTE_BY_ID_SUCCESS',
  GET_QUOTE_BY_ID_ERROR = 'GET_QUOTE_BY_ID_ERROR',

  COL_LOAD_DATA = 'COL_LOAD_DATA',
  COL_LOAD_DATA_SUCCESS = 'COL_LOAD_DATA_SUCCESS',
  COL_LOAD_DATA_ERROR = 'COL_LOAD_DATA_ERROR',

  QUOTE_EXPORT_DATA = 'QUOTE_EXPORT_DATA',
  QUOTE_EXPORT_DATA_SUCCESS = 'QUOTE_EXPORT_DATA_SUCCESS',
  QUOTE_EXPORT_DATA_ERROR = 'QUOTE_EXPORT_DATA_ERROR',

  QUOTE_PRICE_HISTORY_EXPORT_DATA = 'QUOTE_PRICE_HISTORY_EXPORT_DATA',
  QUOTE_PRICE_HISTORY_EXPORT_DATA_SUCCESS = 'QUOTE_PRICE_HISTORY_EXPORT_DATA_SUCCESS',
  QUOTE_PRICE_HISTORY_EXPORT_DATA_ERROR = 'QUOTE_PRICE_HISTORY_EXPORT_DATA_ERROR',

  PRICE_HISTORY_EXPORT_DATA = 'PRICE_HISTORY_EXPORT_DATA',
  PRICE_HISTORY_EXPORT_DATA_SUCCESS = 'PRICE_HISTORY_EXPORT_DATA_SUCCESS',
  PRICE_HISTORY_EXPORT_DATA_ERROR = 'PRICE_HISTORY_EXPORT_DATA_ERROR',

  QUOTE_PAGE_CHANGED = 'QUOTE_PAGE_CHANGED',
  QUOTE_PAGE_SIZE_CHANGED = 'QUOTE_PAGE_SIZE_CHANGED',
  QUOTE_SORT_CHANGED = 'QUOTE_SORT_CHANGED',
  QUOTE_FILTER_CHANGED = 'QUOTE_FILTER_CHANGED',

  QUOTE_DETAIL_PAGE_CHANGED = 'QUOTE_DETAIL_PAGE_CHANGED',
  QUOTE_DETAIL_PAGE_SIZE_CHANGED = 'QUOTE_DETAIL_PAGE_SIZE_CHANGED',
  QUOTE_DETAIL_SORT_CHANGED = 'QUOTE_DETAIL_SORT_CHANGED',
  QUOTE_DETAIL_FILTER_CHANGED = 'QUOTE_DETAIL_FILTER_CHANGED',

  GET_QUOTE_DETAILS_RECORDS_BY_ID = 'GET_QUOTE_DETAILS_RECORDS_BY_ID',
  GET_QUOTE_DETAILS_RECORDS_BY_ID_SUCCESS = 'GET_QUOTE_DETAILS_RECORDS_BY_ID_SUCCESS',
  GET_QUOTE_DETAILS_RECORDS_BY_ID_ERROR = 'GET_QUOTE_DETAILS_RECORDS_BY_ID_ERROR',

  UPDATE_QUOTE_DATA = 'UPDATE_QUOTE_DATA',
  UPDATE_QUOTE_DATA_SUCCESS = 'UPDATE_QUOTE_DATA_SUCCESS',
  UPDATE_QUOTE_DATA_ERROR = 'UPDATE_QUOTE_DATA_ERROR',

  SEND_REMINDER_MAIL = 'SEND_REMINDER_MAIL',
  SEND_REMINDER_MAIL_SUCCESS = 'SEND_REMINDER_MAIL_SUCCESS',
  SEND_REMINDER_MAIL_ERROR = 'SEND_REMINDER_MAIL_ERROR',

  QUOTE_MAN_FILTER_CHANGED = 'QUOTE_MAN_FILTER_CHANGED',
  CONTRACT_MAN_FILTER_CHANGED = 'CONTRACT_MAN_FILTER_CHANGED',
  QUOTE_DETAILS_COLUMNS_FILTER_CHANGED = 'QUOTE_DETAILS_COLUMNS_FILTER_CHANGED',
  QUOTE_DIS_FILTER_CHANGED = 'QUOTE_DIS_FILTER_CHANGED',
  EXPORT_QUOTE_DETAILS_GRID_DATA = 'EXPORT_QUOTE_DETAILS_GRID_DATA',
  EXPORT_QUOTE_DETAILS_GRID_DATA_SUCCESS = 'EXPORT_QUOTE_DETAILS_GRID_DATA_SUCCESS',
  EXPORT_QUOTE_DETAILS_GRID_DATA_ERROR = 'EXPORT_QUOTE_DETAILS_GRID_DATA_ERROR',

  EXPORT_QUOTE_DETAILS_GRID_DATA_CMTEXRRATE = 'EXPORT_QUOTE_DETAILS_GRID_DATA_CMTEXRRATE',
  EXPORT_QUOTE_DETAILS_GRID_DATA_CMTEXRRATE_SUCCESS = 'EXPORT_QUOTE_DETAILS_GRID_DATA_CMTEXRRATE_SUCCESS',
  EXPORT_QUOTE_DETAILS_GRID_DATA_CMTEXRRATE_ERROR = 'EXPORT_QUOTE_DETAILS_GRID_DATA_CMTEXRRATE_ERROR',

  REVIEW_QUOTE_DATA_SUCCESS = 'REVIEW_QUOTE_DATA_SUCCESS',
  REVIEW_QUOTE_DATA_ERROR = 'REVIEW_QUOTE_DATA_ERROR',
  UPLOAD_Quote_FILE = 'UPLOAD_Quote_FILE',
  UPLOAD_Quote_FILE_SUCCESS = 'UPLOAD_Quote_FILE_SUCCESS',
  UPLOAD_Quote_FILE_ERROR = 'UPLOAD_Quote_FILE_ERROR',
  MAP_Quote_GRID_DATA = 'MAP_Quote_GRID_DATA',
  MAPPED_QUOTE_DATA_SUCCESS = 'MAPPED_QUOTE_DATA_SUCCESS',
  MAPPED_QUOTE_DATA_ERROR = 'MAPPED_QUOTE_DATA_ERROR',
  SET_CURRENT_QUOTE_ACTION_DATA = 'SET_CURRENT_QUOTE_ACTION_DATA',
  SET_PREVIOUS_QUOTE_ACTION_DATA = 'SET_PREVIOUS_QUOTE_ACTION_DATA',
  VALIDATE_QUOTE_FILE_STATUS = 'VALIDATE_QUOTE_FILE_STATUS',
  VALIDATE_QUOTE_FILE_STATUS_SUCCESS = 'VALIDATE_QUOTE_FILE_STATUS_SUCCESS',
  VALIDATE_QUOTE_FILE_STATUS_ERROR = 'VALIDATE_QUOTE_FILE_STATUS_ERROR',
  DISCARD_QUOTE = 'DISCARD_QUOTE',
  DISCARD_QUOTE_SUCCESS = 'DISCARD_QUOTE_SUCCESS',
  DISCARD_QUOTE_ERROR = 'DISCARD_QUOTE_ERROR',

  SUBMIT_QUOTE_FILE = 'SUBMIT_QUOTE_FILE',
  SUBMIT_QUOTE_FILE_SUCCESS = 'SUBMIT_QUOTE_FILE_SUCCESS',
  SUBMIT_QUOTE_FILE_ERROR = 'SUBMIT_QUOTE_FILE_ERROR',

  CHANGE_NORMALIZED_GRID_PAGE = '[Upload] CHANGE_NORMALIZED_GRID_PAGE',
  EXPORT_NORMALIZED_GRID = '[Upload] EXPORT_NORMALIZED_GRID',
  EXPORT_NORMALIZED_GRID_DATA = 'EXPORT_NORMALIZED_GRID_DATA',
  EXPORT_NORMALIZED_GRID_DATA_SUCCESS = 'EXPORT_NORMALIZED_GRID_DATA_SUCCESS',
  EXPORT_NORMALIZED_GRID_DATA_ERROR = 'EXPORT_NORMALIZED_GRID_DATA_ERROR',
  EXPORT_QUOTE_DETAILS_GRID_DATA_Error = 'EXPORT_QUOTE_DETAILS_GRID_DATA_Error',
  REVIEW_QUOTE_DATA = 'REVIEW_QUOTE_DATA',

  AWARD_BY_SELECTION = 'AWARD_BY_SELECTION',
  AWARD_BY_SELECTION_SUCCESS = 'AWARD_BY_SELECTION_SUCCESS', 
  AWARD_BY_SELECTION_ERROR = 'AWARD_BY_SELECTION_ERROR', 

  ACK_BY_SELECTION = 'ACK_BY_SELECTION',
  ACK_BY_SELECTION_SUCCESS = 'ACK_BY_SELECTION_SUCCESS', 
  ACK_BY_SELECTION_ERROR = 'ACK_BY_SELECTION_ERROR', 

  RESET_AWARD_BY_SELECTION_STATE = "RESET_AWARD_BY_SELECTION_STATE",

  GET_ALL_FILTERED_RESPONSE_IDS = 'GET_ALL_FILTERED_RESPONSE_IDS',
  GET_ALL_FILTERED_RESPONSE_IDS_SUCCESS = 'GET_ALL_FILTERED_RESPONSE_IDS_SUCCESS', 
  GET_ALL_FILTERED_RESPONSE_IDS_ERROR = 'GET_ALL_FILTERED_RESPONSE_IDS_ERROR', 

  CANCEL_ACK_BY_SELECTION = 'CANCEL_ACK_BY_SELECTION',
  CANCEL_ACK_BY_SELECTION_SUCCESS = 'CANCEL_ACK_BY_SELECTION_SUCCESS',
  CANCEL_ACK_BY_SELECTION_ERROR = 'CANCEL_ACK_BY_SELECTION_ERROR',
  GENERATE_LOA_BY_SELECTION = 'GENERATE_LOA_BY_SELECTION',
  GENERATE_LOA_BY_SELECTION_SUCCESS = 'GENERATE_LOA_BY_SELECTION_SUCCESS',
  GENERATE_LOA_BY_SELECTION_ERROR = 'GENERATE_LOA_BY_SELECTION_ERROR',
  CREATE_LOA = 'CREATE_LOA',
  EMPTY_LOA = 'EMPTY_LOA',
  EMPTY_GET_LOA = 'EMPTY_GET_LOA',
  EMPTY_GENERATE_LOA = 'EMPTY_GENERATE_LOA',
  CREATE_LOA_SUCCESS = 'CREATE_LOA_SUCCESS',
  CREATE_LOA_ERROR = 'CREATE_LOA_ERROR',
  GET_LOA = 'GET_LOA',
  GET_LOA_SUCCESS = 'GET_LOA_SUCCESS',
  GET_LOA_ERROR = 'GET_LOA_ERROR',
  QUOTE_DETAILS_COLUMNS_LOAD_DATA = 'QUOTE_DETAILS_COLUMNS_LOAD_DATA',
  QUOTE_DETAILS_COLUMNS_LOAD_DATA_SUCCESS = 'QUOTE_DETAILS_COLUMNS_LOAD_DATA_SUCCESS',
  QUOTE_DETAILS_COLUMNS_LOAD_DATA_ERROR = 'QUOTE_DETAILS_COLUMNS_LOAD_DATA_ERROR',
  MAP_ACTIVE_QUOTE_DATA = 'MAP_ACTIVE_QUOTE_DATA',
  MAP_ACTIVE_QUOTE_DATA_SUCCESS = 'MAP_ACTIVE_QUOTE_DATA_SUCCESS',
  MAP_ACTIVE_QUOTE_DATA_ERROR = 'MAP_ACTIVE_QUOTE_DATA_ERROR',
  NORMALIZE_ACTIVE_QUOTE_DATA= 'NORMALIZE_ACTIVE_QUOTE_DATA',
  NORMALIZE_ACTIVE_QUOTE_DATA_SUCCESS= 'NORMALIZE_ACTIVE_QUOTE_DATA_SUCCESS',
  NORMALIZE_ACTIVE_QUOTE_DATA_ERROR= 'NORMALIZE_ACTIVE_QUOTE_DATA_ERROR',

  EXPORT_NORMALIZED_ACTIVE_QUOTE_GRID_DATA = 'EXPORT_NORMALIZED_ACTIVE_QUOTE_GRID_DATA',
  EXPORT_NORMALIZED_ACTIVE_QUOTE_GRID_DATA_SUCCESS = 'EXPORT_NORMALIZED_ACTIVE_QUOTE_GRID_DATA_SUCCESS',
  EXPORT_NORMALIZED_ACTIVE_QUOTE_GRID_DATA_ERROR = 'EXPORT_NORMALIZED_ACTIVE_QUOTE_GRID_DATA_ERROR',
}

export class QuoteLoadData implements Action {
  readonly type = ActionTypes.QUOTE_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class QuoteLoadDataSucees implements Action {
  readonly type = ActionTypes.QUOTE_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class QuoteLoadDataError implements Action {
  readonly type = ActionTypes.QUOTE_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}
export class GetQuoteDetailsById implements Action {
  readonly type = ActionTypes.GET_QUOTE_DETAILS_RECORDS_BY_ID;
  constructor(
    public quoteId?: number,
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class GetQuoteDetailsByIdSuccess implements Action {
  readonly type = ActionTypes.GET_QUOTE_DETAILS_RECORDS_BY_ID_SUCCESS;
  constructor(public payload: any) {}
}
export class GetQuoteDetailsByIdError implements Action {
  readonly type = ActionTypes.GET_QUOTE_DETAILS_RECORDS_BY_ID_ERROR;
  constructor(public payload: any) {}
}

export class GetPriceHistory implements Action {
  readonly type = ActionTypes.PRICE_HISTORY_LOAD_DATA;
  constructor(public payload: any) {}
}

export class GetPriceHistorySuccess implements Action {
  readonly type = ActionTypes.PRICE_HISTORY_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class GetPriceHistoryError implements Action {
  readonly type = ActionTypes.PRICE_HISTORY_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ExportQuoteDetailsGridData implements Action {
  readonly type = ActionTypes.EXPORT_QUOTE_DETAILS_GRID_DATA;
  constructor(public payload?: any, public quoteId?: any, public fileName?: any) {}
}
export class ExportQuoteDetailsGridDataSuccess implements Action {
  readonly type = ActionTypes.EXPORT_QUOTE_DETAILS_GRID_DATA_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}
export class ExportQuoteDetailsGridDataError implements Action {
  readonly type = ActionTypes.EXPORT_QUOTE_DETAILS_GRID_DATA_Error;
  constructor(public payload: any) {}
}

export class ExportQuoteDetailsGridDataCMTExRate implements Action {
  readonly type = ActionTypes.EXPORT_QUOTE_DETAILS_GRID_DATA_CMTEXRRATE;
  constructor(public payload?: any, public quoteId?: any, public fileName?: any) {}
}
export class ExportQuoteDetailsGridDataCMTExRateSuccess implements Action {
  readonly type = ActionTypes.EXPORT_QUOTE_DETAILS_GRID_DATA_CMTEXRRATE_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}
export class ExportQuoteDetailsGridCMTExRateError implements Action {
  readonly type = ActionTypes.EXPORT_QUOTE_DETAILS_GRID_DATA_CMTEXRRATE_ERROR;
  constructor(public payload: any) {}
}

export class AwardBySelection implements Action {
  readonly type = ActionTypes.AWARD_BY_SELECTION;
  constructor(public payload?: any) {}
}
export class AwardBySelectionSuccess implements Action {
  readonly type = ActionTypes.AWARD_BY_SELECTION_SUCCESS;
  constructor(public payload: any) {}
}
export class AwardBySelectionError implements Action {
  readonly type = ActionTypes.AWARD_BY_SELECTION_ERROR;
  constructor(public payload: any) {}
}
export class EmptyUploadFileObject implements Action {
  readonly type = ActionTypes.EMPTY_FILE;
  constructor() {}
}
export class SaveUploadedFile implements Action {
  readonly type = ActionTypes.SAVE_UPLOADED_FILE;
  constructor(public fileId?: number,public quoteId?: number,public status?: number) {}
}
export class SaveUploadedFileSuccess implements Action {
  readonly type = ActionTypes.SAVE_UPLOADED_FILE_SUCCESS;
  constructor(public payload: any) {}
}
export class SaveUploadedFileError implements Action {
  readonly type = ActionTypes.SAVE_UPLOADED_FILE_ERROR;
  constructor(public payload: any) {}
}


//update quote part details
export class UpdateQuotePartDetails implements Action {
  readonly type = ActionTypes.UPDATE_QUOTE_PART_DETAILS;
  constructor(public payload: any) {}
}
export class UpdateQuotePartDetailsSuccess implements Action {
  readonly type = ActionTypes.UPDATE_QUOTE_PART_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}
export class UpdateQuotePartDetailsError implements Action {
  readonly type = ActionTypes.UPDATE_QUOTE_PART_DETAILS_ERROR;
  constructor(public payload: any) {}
}

export class GenerateLoa implements Action {
  readonly type = ActionTypes.GENERATE_LOAD_FILE;
  constructor(public payload?: any) {}
}
export class GenerateLoaSuccess implements Action {
  readonly type = ActionTypes.GENERATE_LOAD_FILE_SUCCESS;
  constructor(public payload: any) {}
}
export class GenerateLoaError implements Action {
  readonly type = ActionTypes.GENERATE_LOAD_FILE_ERROR;
  constructor(public payload: any) {}
}

export class DeleteLoa implements Action {
  readonly type = ActionTypes.DELETE_LOA;
  constructor(public loaId?: number) {}
}
export class DeleteLoaSuccess implements Action {
  readonly type = ActionTypes.DELETE_LOA_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteLoaError implements Action {
  readonly type = ActionTypes.DELETE_LOA_ERROR;
  constructor(public payload: any) {}
}

export class DeleteQuotEemptySelector implements Action {
  readonly type = ActionTypes.QUOTE_DELETE_EMPTY_SELECTOR;
  constructor() {}
}
export class DeleteQuote implements Action {
  readonly type = ActionTypes.QUOTE_DELETE_RECORD;
  constructor(public QuoteId?: number) {}
}
export class DeleteQuoteSuccess implements Action {
  readonly type = ActionTypes.QUOTE_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteQuoteError implements Action {
  readonly type = ActionTypes.QUOTE_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class UpdateQuoteActive implements Action {
  readonly type = ActionTypes.QUOTE_Update_Active;
  constructor(public quoteActiveDTO: any) {}
}
export class UpdateQuoteActiveSuccess implements Action {
  readonly type = ActionTypes.QUOTE_Update_Active_SUCCESS;
  constructor(public quoteActiveDTO: any) {}
}
export class UpdateQuoteActiveError implements Action {
  readonly type = ActionTypes.QUOTE_Update_Active_ERROR;
  constructor(public payload: any) {}
}

export class AckBySelection implements Action {
  readonly type = ActionTypes.ACK_BY_SELECTION;
  constructor(public payload?: any) {}
}
export class AckBySelectionSuccess implements Action {
  readonly type = ActionTypes.ACK_BY_SELECTION_SUCCESS;
  constructor(public payload: any) {}
}
export class AckBySelectionError implements Action {
  readonly type = ActionTypes.ACK_BY_SELECTION_ERROR;
  constructor(public payload: any) {}
}

export class UpdateCommId implements Action {
  readonly type = ActionTypes.UPDATE_COM_ID;
  constructor(public payload: any) {}
}
export class UpdateCommIdSuccess implements Action {
  readonly type = ActionTypes.UPDATE_COM_ID_SUCCESS;
  constructor(public payload: any) {}
}
export class UpdateCommIdError implements Action {
  readonly type = ActionTypes.UPDATE_COM_ID_ERROR;
  constructor(public payload: any) {}
}



export class CancelAckBySelection implements Action {
  readonly type = ActionTypes.CANCEL_ACK_BY_SELECTION;
  constructor(public payload?: any) {}
}
export class CancelAckBySelectionSuccess implements Action {
  readonly type = ActionTypes.CANCEL_ACK_BY_SELECTION_SUCCESS;
  constructor(public payload: any) {}
}
export class CancelAckBySelectionError implements Action {
  readonly type = ActionTypes.CANCEL_ACK_BY_SELECTION_ERROR;
  constructor(public payload: any) {}
}
export class GenerateLOABySelection implements Action {
  readonly type = ActionTypes.GENERATE_LOA_BY_SELECTION;
  constructor(public payload?: any) {}
}
export class GenerateLOABySelectionSuccess implements Action {
  readonly type = ActionTypes.GENERATE_LOA_BY_SELECTION_SUCCESS;
  constructor(public payload: any) {}
}
export class GenerateLOABySelectionError implements Action {
  readonly type = ActionTypes.GENERATE_LOA_BY_SELECTION_ERROR;
  constructor(public payload: any) {}
}

export class ResetAwardBySelectionState implements Action {
  readonly type = ActionTypes.RESET_AWARD_BY_SELECTION_STATE;
  constructor() {}
}

export class GetAllFilteredResponseIds implements Action {
  readonly type = ActionTypes.GET_ALL_FILTERED_RESPONSE_IDS;
  constructor( public quoteId?: number, public filter?: FilterDescriptor[]) {}
}
export class GetAllFilteredResponseIdsSuccess implements Action {
  readonly type = ActionTypes.GET_ALL_FILTERED_RESPONSE_IDS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetAllFilteredResponseIdsError implements Action {
  readonly type = ActionTypes.GET_ALL_FILTERED_RESPONSE_IDS_ERROR;
  constructor(public payload: any) {}
}

// manufacturer in quote

export class manLoadData implements Action {
  readonly type = ActionTypes.QUOTE_MAN_LOAD_DATA;
  constructor(public payload: any) {}
}

export class manLoadDataSucees implements Action {
  readonly type = ActionTypes.QUOTE_MAN_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class manLoadDataError implements Action {
  readonly type = ActionTypes.QUOTE_MAN_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}
export class contractManSitesLoadData implements Action {
  readonly type = ActionTypes.CONTRACT_MAN_SITES_LOAD_DATA;
  constructor(public cmName: string = '') {}
}

export class contractManSitesLoadDataSucess implements Action {
  readonly type = ActionTypes.CONTRACT_MAN_SITES_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class contractManSitesLoadDataError implements Action {
  readonly type = ActionTypes.CONTRACT_MAN_SITES_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class manLoadFilteredData implements Action {
  readonly type = ActionTypes.QUOTE_MAN_LOAD_FILTERED_DATA;
  constructor(public manName?: string) {}
}

export class manLoadFilteredDataSuccess implements Action {
  readonly type = ActionTypes.QUOTE_MAN_LOAD_FILTERED_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class manLoadFilteredDataError implements Action {
  readonly type = ActionTypes.QUOTE_MAN_LOAD_FILTERED_DATA_ERROR;
  constructor(public payload: any) {}
}

export class manSelectData implements Action {
  readonly type = ActionTypes.QUOTE_MAN_SELECT_DATA;
  constructor(public supplierId?: number,public isAdded?: boolean, public quoteId?: number) {}
}

export class manSelectDataSuccess implements Action {
  readonly type = ActionTypes.QUOTE_MAN_SELECT_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class manSelectDataError implements Action {
  readonly type = ActionTypes.QUOTE_MAN_SELECT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class disSelectData implements Action {
  readonly type = ActionTypes.QUOTE_DIS_SELECT_DATA;
  constructor(public supplierId?: number,public isAdded?: boolean, public quoteId?: number) {}
}

export class disSelectDataSuccess implements Action {
  readonly type = ActionTypes.QUOTE_DIS_SELECT_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class disSelectDataError implements Action {
  readonly type = ActionTypes.QUOTE_DIS_SELECT_DATA_ERROR;
  constructor(public payload: any) {}
}

// distributor in quote
// manufacturer in quote
export class updateQuote implements Action {
  readonly type = ActionTypes.UPDATE_QUOTE;
  constructor(public payload: QuoteUpdateObj) {}
}

export class updateQuoteSucees implements Action {
  readonly type = ActionTypes.UPDATE_QUOTE_SUCCESS;
  constructor(public payload: any) {}
}

export class updateQuoteError implements Action {
  readonly type = ActionTypes.UPDATE_QUOTE_ERROR;
  constructor(public payload: any) {}
}

export class getQuoteById implements Action {
  readonly type = ActionTypes.GET_QUOTE_BY_ID;
  constructor(public quoteId: number) {}
}

export class getQuoteByIdSucees implements Action {
  readonly type = ActionTypes.GET_QUOTE_BY_ID_SUCCESS;
  constructor(public payload: any) {}
}

export class getQuoteByIdError implements Action {
  readonly type = ActionTypes.GET_QUOTE_BY_ID_ERROR;
  constructor(public payload: any) {}
}

export class disLoadData implements Action {
  readonly type = ActionTypes.QUOTE_DIS_LOAD_DATA;
  constructor(public payload: any) {}
}

export class disLoadDataSucees implements Action {
  readonly type = ActionTypes.QUOTE_DIS_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class disLoadDataError implements Action {
  readonly type = ActionTypes.QUOTE_DIS_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class colLoadData implements Action {
  readonly type = ActionTypes.COL_LOAD_DATA;
  constructor() {}
}

export class colLoadDataSucees implements Action {
  readonly type = ActionTypes.COL_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class colLoadDataError implements Action {
  readonly type = ActionTypes.COL_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class quoteDetailsColumnsLoadData implements Action {
  readonly type = ActionTypes.QUOTE_DETAILS_COLUMNS_LOAD_DATA;
  constructor(public colName: string = '') {}
}

export class quoteDetailsColumnsLoadDataSucess implements Action {
  readonly type = ActionTypes.QUOTE_DETAILS_COLUMNS_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class quoteDetailsColumnsLoadDataError implements Action {
  readonly type = ActionTypes.QUOTE_DETAILS_COLUMNS_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class QuoteExportData implements Action {
  readonly type = ActionTypes.QUOTE_EXPORT_DATA;
  constructor(public fileName?: any, public payload?: any) {}
}
export class QuoteExportDataSucees implements Action {
  readonly type = ActionTypes.QUOTE_EXPORT_DATA_SUCCESS;
  constructor(public payload: any, public fileName?: any) {}
}

export class QuoteExportDataError implements Action {
  readonly type = ActionTypes.QUOTE_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class QuoteExporPriceHistorytData implements Action {
  readonly type = ActionTypes.QUOTE_PRICE_HISTORY_EXPORT_DATA;
  constructor(public payload?: any,public fileName?:string) {}
}
export class QuoteExporPriceHistorytDataSucees implements Action {
  readonly type = ActionTypes.QUOTE_PRICE_HISTORY_EXPORT_DATA_SUCCESS;
  constructor(public payload: any, public fileName?: any) {}
}

export class QuoteExporPriceHistorytDataError implements Action {
  readonly type = ActionTypes.QUOTE_PRICE_HISTORY_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class PriceHistoryExportData implements Action {
  readonly type = ActionTypes.PRICE_HISTORY_EXPORT_DATA;
  constructor(public payload: any) {}
}
export class PriceHistoryExportDataSucees implements Action {
  readonly type = ActionTypes.PRICE_HISTORY_EXPORT_DATA_SUCCESS;
  constructor(public payload: any, public fileName?: any) {}
}

export class PriceHistoryExportDataError implements Action {
  readonly type = ActionTypes.PRICE_HISTORY_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class QuoteSortChanged implements Action {
  readonly type = ActionTypes.QUOTE_SORT_CHANGED;
  constructor(public sort?: SortDescriptor) {
  }
}
export class QuotePageChanged implements Action {
  readonly type = ActionTypes.QUOTE_PAGE_CHANGED;
  constructor(public page?: number) {}
}
export class QuoteFilterChanged implements Action {
  readonly type = ActionTypes.QUOTE_FILTER_CHANGED;
  constructor(public filter?: any) {}
}
export class QuotePageSizeChanged implements Action {
  readonly type = ActionTypes.QUOTE_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

export class QuoteDetailSortChanged implements Action {
  readonly type = ActionTypes.QUOTE_DETAIL_SORT_CHANGED;
  constructor(public page?: number) {}
}
export class QuoteDetailPageChanged implements Action {
  readonly type = ActionTypes.QUOTE_DETAIL_PAGE_CHANGED;
  constructor(public page?: number) {}
}
export class QuoteDetailFilterChanged implements Action {
  readonly type = ActionTypes.QUOTE_DETAIL_FILTER_CHANGED;
  constructor(public filter?: any) {}
}
export class QuoteDetailPageSizeChanged implements Action {
  readonly type = ActionTypes.QUOTE_DETAIL_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

export class updateQuoteData implements Action {
  readonly type = ActionTypes.UPDATE_QUOTE_DATA;

  constructor(public quoteDTO?: any) {}
}
export class updateQuoteDataSuccess implements Action {
  readonly type = ActionTypes.UPDATE_QUOTE_DATA_SUCCESS;
  constructor(public quoteDTO?: any) {}
}
export class updateQuoteDataError implements Action {
  readonly type = ActionTypes.UPDATE_QUOTE_DATA_ERROR;
  constructor(public payload?: any) {}
}

export class sendReminderMail implements Action {
  readonly type = ActionTypes.SEND_REMINDER_MAIL;

  constructor(public payload: any) { }
}
export class sendReminderMailSuccess implements Action {
  readonly type = ActionTypes.SEND_REMINDER_MAIL_SUCCESS;
  constructor(public payload: any) { }
}
export class sendReminderMailError implements Action {
  readonly type = ActionTypes.SEND_REMINDER_MAIL_ERROR;
  constructor(public payload: any) { }
}

export class QuoteManFilterChanged implements Action {
  readonly type = ActionTypes.QUOTE_MAN_FILTER_CHANGED;
  constructor(public filter?: any) {}
}

export class ContractManFilterChanged implements Action {
  readonly type = ActionTypes.CONTRACT_MAN_FILTER_CHANGED;
  constructor(public filter?: any) {}
}

export class QuoteDetailsColumnsFilterChanged implements Action {
  readonly type = ActionTypes.QUOTE_DETAILS_COLUMNS_FILTER_CHANGED;
  constructor(public filter?: any) {}
}
export class QuoteDisFilterChanged implements Action {
  readonly type = ActionTypes.QUOTE_DIS_FILTER_CHANGED;
  constructor(public filter?: any) {}
}
export class ReviewQuoteData implements Action {
  readonly type = ActionTypes.REVIEW_QUOTE_DATA;
  constructor(public tempQuoteId?: any, public fileId?: any) {}
}


export class ReviewQuoteDataSuccess implements Action {
  readonly type = ActionTypes.REVIEW_QUOTE_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class ReviewQuoteDataError implements Action {
  readonly type = ActionTypes.REVIEW_QUOTE_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class MapActiveQuoteData implements Action {
  readonly type = ActionTypes.MAP_ACTIVE_QUOTE_DATA;
  constructor(public quoteId?: any, public fileId?: any) {}
}
export class MapActiveQuoteDataSuccess implements Action {
  readonly type = ActionTypes.MAP_ACTIVE_QUOTE_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class MapActiveQuoteDataError implements Action {
  readonly type = ActionTypes.MAP_ACTIVE_QUOTE_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class UPLOAD_Quote_FILE implements Action {
  readonly type = ActionTypes.UPLOAD_Quote_FILE;
  constructor(public payload: any) {}
}
export class UPLOAD_Quote_FILE_SUCCESS implements Action {
  readonly type = ActionTypes.UPLOAD_Quote_FILE_SUCCESS;
  constructor(public payload: any) {}
}
export class UPLOAD_Quote_FILE_ERROR implements Action {
  readonly type = ActionTypes.UPLOAD_Quote_FILE_ERROR;
  constructor(public payload: any) {}
}

export class MappedQuoteData implements Action {
  readonly type = ActionTypes.MAP_Quote_GRID_DATA;
  constructor(
    public mappedColumns?: GridHeaderData[],
    public startRow?: number,
    public tempQuoteId?: string | null
  ) {}
}

export class MappedQuoteDataSuccess implements Action {
  readonly type = ActionTypes.MAPPED_QUOTE_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class MappedQuoteDataError implements Action {
  readonly type = ActionTypes.MAPPED_QUOTE_DATA_ERROR;
  constructor(public payload: any) {}
}

export class SetCurrentQuoteActionData implements Action {
  readonly type = ActionTypes.SET_CURRENT_QUOTE_ACTION_DATA;
  constructor(public actionType?: string, public tempQuoteId?: string | null) {}
}

export class SetpreviousQuoteActionData implements Action {
  readonly type = ActionTypes.SET_PREVIOUS_QUOTE_ACTION_DATA;
  constructor(public fileId?: string, public tempQuoteId?: string | null, public quoteId?: string|null,public quoteName? :string|null) {}
}
export class DiscardQuote implements Action {
  readonly type = ActionTypes.DISCARD_QUOTE;
  constructor(public fileId: any) {}
}
export class DiscardQuoteSucess implements Action {
  readonly type = ActionTypes.DISCARD_QUOTE_SUCCESS;
  constructor(public payload: any) {}
}
export class DiscardQuoteError implements Action {
  readonly type = ActionTypes.DISCARD_QUOTE_ERROR;
  constructor(public payload: any) {}
}

export class GetLastQuoteFileStatus implements Action {
  readonly type = ActionTypes.VALIDATE_QUOTE_FILE_STATUS;
  constructor() {}
}
export class GetLastQuoteFileStatusSuccess implements Action {
  readonly type = ActionTypes.VALIDATE_QUOTE_FILE_STATUS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetLastQuoteFileStatusError implements Action {
  readonly type = ActionTypes.VALIDATE_QUOTE_FILE_STATUS_ERROR;
  constructor(public payload: any) {}
}

export class ChangeNormalizedGridPage implements Action {
  readonly type = ActionTypes.CHANGE_NORMALIZED_GRID_PAGE;
  constructor(public page?: number) {}
}

export class ExportNormalizedGridData implements Action {
  readonly type = ActionTypes.EXPORT_NORMALIZED_GRID_DATA;
  constructor(
    public tempQuoteId?: any,
    public fileId?: any,
    public fileName?: any,
    public exportOption?: ExportNormalizedGridOptionsEnum
  ) {}
}

export class ExportNormalizedGridDataSucees implements Action {
  readonly type = ActionTypes.EXPORT_NORMALIZED_GRID_DATA_SUCCESS;

  constructor(public payload: any, public fileName: any) {}
}

export class ExportNormalizedGridDataError implements Action {
  readonly type = ActionTypes.EXPORT_NORMALIZED_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ExportNormalizedActiveQuoteGridData implements Action {
  readonly type = ActionTypes.EXPORT_NORMALIZED_ACTIVE_QUOTE_GRID_DATA;
  constructor(
    public quoteId?: any,
    public tempId?: any,
    public fileName?: any,
    public exportOption?: ExportNormalizedGridOptionsEnum
  ) {}
}

export class ExportNormalizedActiveQuoteGridDataSuccess implements Action {
  readonly type = ActionTypes.EXPORT_NORMALIZED_ACTIVE_QUOTE_GRID_DATA_SUCCESS;

  constructor(public payload: any, public fileName: any) {}
}

export class ExportNormalizedActiveQuoteGridDataError implements Action {
  readonly type = ActionTypes.EXPORT_NORMALIZED_ACTIVE_QUOTE_GRID_DATA_ERROR;
  constructor(public payload: any) {}
}

export class SubmitQuoteFile implements Action {
  readonly type = ActionTypes.SUBMIT_QUOTE_FILE;
  constructor(public payload: any) {}
}

export class SubmitQuoteFileSuccess implements Action {
  readonly type = ActionTypes.SUBMIT_QUOTE_FILE_SUCCESS;
  constructor(public payload: any) {}
}

export class SubmitQuoteFileError implements Action {
  readonly type = ActionTypes.SUBMIT_QUOTE_FILE_ERROR;
  constructor(public payload: any) {}
}

//normalize active quote data to update
export class NormalizeActiveQuoteData implements Action {
  readonly type = ActionTypes.NORMALIZE_ACTIVE_QUOTE_DATA;
  constructor(
    public fileId?: number | null,
    public quoteId?: number | null,
    public tempQuoteId?: string | null,
    public mappedColumns?: any[],
    public startRow?: number,
    public status?: QuoteUploadOption| null,

  ) {}
}

export class NormalizeActiveQuoteDataSuccess implements Action {
  readonly type = ActionTypes.NORMALIZE_ACTIVE_QUOTE_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class NormalizeActiveQuoteDataError implements Action {
  readonly type = ActionTypes.NORMALIZE_ACTIVE_QUOTE_DATA_ERROR;
  constructor(public payload: any) {}
}

/////////////////////////////////LOA////////////////////////////////////////////
export class createLOA implements Action {
  readonly type = ActionTypes.CREATE_LOA;

  constructor(public payload?: any) { }
}

export class createLOASuccess implements Action {
  readonly type = ActionTypes.CREATE_LOA_SUCCESS;
  constructor(public payload: any) { }
}
export class createLOAError implements Action {
  readonly type = ActionTypes.CREATE_LOA_ERROR;
  constructor(public payload: any) { }
}
export class emptyLOA implements Action {
  readonly type = ActionTypes.EMPTY_LOA;

  constructor() { }
}

export class emptyGetLoaDTO implements Action {
  readonly type = ActionTypes.EMPTY_GET_LOA;

  constructor() { }
}
export class emptyGenerateLoaDTO implements Action {
  readonly type = ActionTypes.EMPTY_GENERATE_LOA;

  constructor() { }
}

export class getLOA implements Action {
  readonly type = ActionTypes.GET_LOA;

  constructor(public payload: LoaResponseDTO) { }
}
export class getLOASuccess implements Action {
  readonly type = ActionTypes.GET_LOA_SUCCESS;
  constructor(public payload: any) { }
}
export class getLOAError implements Action {
  readonly type = ActionTypes.GET_LOA_ERROR;
  constructor(public payload: any) { }
}