import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IQuoteState } from 'src/app/core/Admin/quotes/quotes.interfaces';

export const getRFQState = createFeatureSelector<IQuoteState>('quote');

export const QuoteGridDataSelector = createSelector(
  getRFQState,
  (state) => state?.quoteGrid
);
export const QuoteDetailsGridDataSelector = createSelector(
  getRFQState,
  (state) => state?.quoteDetailsGrid
);
export const PriceHistoryGridDataSelector = createSelector(
  getRFQState,
  (state) => state?.priceHistoryGrid
);
export const exportQuoteDetailsGridDataSelector = createSelector(
  getRFQState,
  (state) => state?.quoteDetailsGrid?.export
);
export const exportQuoteDetailsFilterGridSelector = createSelector(
  getRFQState,
  (state) => state?.filter
);
export const ManGridDataSelector = createSelector(
  getRFQState,
  (state) => state?.manGrid
);
export const SendGridDataSelector = createSelector(
  getRFQState,
  (state) => state?.updateQuote
);
export const SendReminderMailSelector = createSelector(
  getRFQState,
  (state) => state?.sendReminderMail
);
export const GetQuoteDataSelector = createSelector(
  getRFQState,
  (state) => state?.getQuoteById
);
export const manFilterDataSelector = createSelector(
  getRFQState,
  (state) => state?.manFilter
);
export const contractManFilterDataSelector = createSelector(
  getRFQState,
  (state) => state?.cmFilter
);
export const manSelectDataSelector = createSelector(
  getRFQState,
  (state) => state?.manCheckedrows
);
export const colFilterDataSelector = createSelector(
  getRFQState,
  (state) => state?.colFilter
);
export const disFilterDataSelector = createSelector(
  getRFQState,
  (state) => state?.disFilter
);
export const DisGridDataSelector = createSelector(
  getRFQState,
  (state) => state?.disGrid
);
export const cmGridDataSelector = createSelector(
  getRFQState,
  (state) => state?.cmGrid
);

export const ColGridDataSelector = createSelector(
  getRFQState,
  (state) => state?.colGrid
);
export const QuoteGridDFilterSelector = createSelector(
  getRFQState,
  (state) => state?.filter
);

export const QuoteGridSortSelector = createSelector(
  getRFQState,
  (state) => state?.sort
);

export const QuoteGridCurrentPageSelector = createSelector(
  getRFQState,
  (state) => state?.quoteGrid?.currentPage
);

export const QuoteGridCurrentPageSizeSelector = createSelector(
  getRFQState,
  (state) => state?.quoteGrid?.pageSize
);

export const InvitedselectedFiltersSelector = createSelector(
  QuoteGridDFilterSelector,
  QuoteGridSortSelector,
  QuoteGridCurrentPageSelector,
  QuoteGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);
/**--------------------------------------------Quote--------------------------------------------------- */
export const currentQuoteActionSelector = createSelector(
  getRFQState,
  (state) => state?.currentQuoteAction
);
export const previousQuoteActionSelector = createSelector(
  getRFQState,
  (state) => state?.previousQuoteAction
);
export const submitQuoteActionSelector = createSelector(
  getRFQState,
  (state) => state?.submitQuoteAction
);
export const submitUploadedFileSelector = createSelector(
  getRFQState,
  (state) => state?.saveUploadedFile
);
export const validateQuoteFileSelector = createSelector(
  getRFQState,
  (state) => state?.validateQuoteFile
);

export const uploadReviewQuoteGridSelector = createSelector(
  getRFQState,
  (state) => state?.reviewQuoteGrid
);
export const mapActiveQuoteGridSelector = createSelector(
  getRFQState,
  (state) => state?.mapActiveQuoteGrid
);

export const uploadQuoteFileSelector = createSelector(
  getRFQState,
  (state) => state?.uploadQuoteFile
);

export const discardQuoteSelector = createSelector(
  getRFQState,
  (state) => state?.discardQuote
);
export const normalizationResultSelector = createSelector(
  getRFQState,
  (state) => state?.normalizationResult
);
export const UpdatePartsResultSelector = createSelector(
  getRFQState,
  (state) => state?.updatePartDetails
);

export const normalizationResultLoadingSelector = createSelector(
  getRFQState,
  (state) => {
    return {
      loading: state?.normalizationResult?.loading,
      isNormalized: state?.normalizationResult?.isNormalized,
    };
  }
);
export const UpdateQuoteDataSelector = createSelector(
  getRFQState,
  (state) => state?.quoteData
);
export const GridActionPopupDataSelector = createSelector(
  getRFQState,
  (state) => state?.gridActionPopupData
);
export const generateLOAResultSelector = createSelector(
  getRFQState,
  (state) => state?.gridActionPopupData
);
export const SelectAllResponsesSelector = createSelector(
  getRFQState,
  (state) => state?.selectAllResponses
);
export const createLOASelector = createSelector(
  getRFQState,
  (state) => state?.loaResponseDTO
);
export const getLOASelector = createSelector(
  getRFQState,
  (state) => state?.getLoadDTO
);
export const GenerateLOASelector = createSelector(
  getRFQState,
  (state) => state?.generateLoa
);
export const DeleteQuoteSelector = createSelector(
  getRFQState,
  (state) => state?.quoteGrid.delete
);
export const UpdateQuoteActiveSelector = createSelector(
  getRFQState,
  (state) => state?.quoteGrid.active
);