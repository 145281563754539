import { partMapSort } from 'src/app/core/Acl/Parts/part.mapper';
import { downLoadFile } from 'src/app/core/utils';
import { AclHeaders } from '../pages/utils/headers';
import { ActionTypes } from './acl.action';

export const INITIAL_ACL_STATE = {
  partGrid: {
    columns: AclHeaders,
    currentPage: 0,
    data: [
      {
        wabtec_acl_id: '',
        cpn: '',
        mpn: '',
        man: '',
        businessUnit: '',
        wabtecDescription: '',
        seMpn: '',
        seMan: '',
        seDescription: '',
      },
    ],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort: {},
    export: {},
    edit: {},
    delete: 0,
  },
};

export function AclReducer(state = INITIAL_ACL_STATE, action: any): any {
  // part grid data
  switch (action.type) {
    case ActionTypes.UPDATE_GRID_COLUMNS: {
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          columns: action.payload,
        },
      };
    }

    case ActionTypes.PART_LOAD_DATA: {
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.PART_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          data: action.payload.data,
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }

    case ActionTypes.PART_LOAD_DATA_ERROR: {
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.PART_PAGE_CHANGED: {
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          currentPage: action.payload,
        },
      };
    }

    case ActionTypes.PART_FILTER_CHANGED: { 
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          filter: action?.filter,
        },
      };
    }

    case ActionTypes.PART_SORT_CHANGED: {
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          sort: partMapSort(action?.payload),
        },
      };
    }

    case ActionTypes.PART_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          pageSize: action.payload,
        },
      };
    }

    case ActionTypes.PART_EXPORT_DATA: {
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.PART_EXPORT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.PART_EXPORT_DATA_ERROR: {
      return {
        ...state,
        partGrid: {
          ...state.partGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
  }
}
