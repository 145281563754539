import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionsLayout } from '@progress/kendo-angular-dialog';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { buttonInfo, DialogType } from 'src/app/shared/shared.interface';
import { AppButtonComponent } from '../../button/button.component';

@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss'],
})
export class DialogMessageComponent {
  public type: DialogType;
  public message: string;
  public buttons: buttonInfo[];

  public isClosed: boolean = true;
  public actionsLayout: ActionsLayout = 'normal';
  public subscribtion = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService
  ) {
    this.startSubscribtion();
  }

  startSubscribtion() {
    this.subscribtion.add(this.subscribeToDialog());
    this.subscribtion.add(this.subscribeToRouteChanges());
  }

  subscribeToRouteChanges() {
    return this.route.paramMap.subscribe(() => {
      this.isClosed = true;
    });
  }

  subscribeToDialog() {
    return this.dialogService.dialogData.subscribe((data) => {
      if (data) {
        this.isClosed = false;
        this.type = data.type;
        this.message = data.message;
        this.buttons = data.buttons;
      }
    });
  }

  public closeDialog() {
    this.isClosed = true;
  }

  public clickButton(item: buttonInfo) {
    const button = this.buttons.find((c) => c === item);
    if (button) {
      if (button.click) {
        button.click();
      }

      if (button.closeDialogAfterClick !== false) {
        this.isClosed = true;
      }
    }
  }
}
