import { flatAlertSearchData } from 'src/app/core/parts/alerts/alertdetails.mapper';
import { flatCrossSearchData } from 'src/app/core/parts/cross-search/cross-search.mapper';
import { flatPartSearchData } from 'src/app/core/parts/part-search/part-search.mapper';
import { downLoadFile } from 'src/app/core/utils';
import { getCbomGridHearders } from '../pages/cbom/utils/headers';
import { getGridViewHearders } from '../pages/components/utils/headers';
import { ActionTypes } from './parts.action';

export const INITIAL_PARTS_STATE = {
  partNumberSearch: '',
  partSearchGrid: {
    currentPage: 1,
    data: [
      {
        //ComID: '',
        PartNumber: '',
        Manufacturer: '',
        PlName: '',
        Description: '',
        Datasheet: '',
        RoHS: '',
        RoHSVersion: '',
        TaxPath: '',
      },
    ],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    export: {},
  },
  crossSearchText: '',
  crossSearchGrid: {
    currentPage: 1,
    data: [
      {
        //ComID: '',
        PartNumber: '',
        Manufacturer: '',
        Lifecycle: '',
        Datasheet: '',
        Description: '',
        RoHSStatus: '',
        CrossPartNumber: '',
        CrossManufacturer: '',
        CrossLifecycle: '',
        CrossRoHSStatus: '',
        Type: '',
        MPartNumber: '',
        MManfacturer: '',
        aml: '',
        acl: ''
      },
    ],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    export: {},
  },
  alertData: {
    alertTitle: '',
    alertId: 0,
  },
  componentsSelectedYear: '',
  loadedComponent: {},
  alertDetailsData: {},
  componentsGrid: {
    currentPage: 0,
    data: [],
    headers: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort:{},
    export: {},
  },
  contractManufacturer: {
    data: [],
    error: undefined,
    loading: false,
  },
  cbomGrid: {
    currentPage: 0,
    data: [],
    headers: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
    sort:{},
    export: {},
  },
  alertGrid: {
    currentPage: 1,
    data: [],
    headers: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    export: {},
    add: undefined,
    edit: undefined,
    delete: 0,
  },
  p5alertGrid: {
    currentPage: 1,
    data: [],
    headers: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    export: {},
    add: undefined,
    edit: undefined,
    delete: 0,
  },
  partDetailsRecordGrid: {
    currentPage: 1,
    data: [],
    headers: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
  },
  alertDetailsGrid: {
    currentPage: 0,
    data: [],
    filter: [],
    sort: {
      columnName: "status",
      direction: "asc"
    },
    headers: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    export: {},
    sendselected: {},
    add: undefined,
    edit: undefined,
    delete: 0,
  },
  p5alertDetailsGrid: {
    currentPage: 0,
    data: [],
    filter: [],
    sort: [],
    headers: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    export: {},
    sendselected: {},
    add: undefined,
    edit: undefined,
    delete: 0,
  },
  p5AlertPartDetails: {
    p5AlertId: 0,
    p5AlertPriority: "",
    p5AlertManufacture: "",
    p5AlertPart: "",
    p5AlertSubject: "",
    p5AlertSeManufacturer: "",
    p5AlertSePartNumber: "",
    p5AlertLastShipDate: "",
    p5AlertLtbDate: "",
    p5AlertStatusName: "",
    AlertType: "",
    error: undefined,
    loading: false,
  },
  cmtAlertPartDetails: {
    alertPartListId: 0,
    comId: 0,
    componentDataId: 0,
    currentManufacturer: "",
    manufacturerPn: "",
    cpn: "",
    asm: 0,
    snappedPrice: "",
    snappedQuantity: 0,
    snappedLeadTimeMin: "",
    snappedLeadTimeMax: "",
    newPrice: "",
    newQuantity: "",
    newLeadTimeMin: "",
    newLeadTimeMax: "",
    priceDiff: "",
    quantityDiff: "",
    leadTimeDiff: "",
    status: "",
    impacted: false,
    snappedLeadTimeAvg: 0,
    newLeadTimeAvg: 0,
    alert: 0,
    alertPartListComments: [],
    priority: {
      alertPriorityId: 1,
      alertPriorityName: "High"
    },
    error: undefined,
    loading: false,
  },
  users: {
    data: [],
    error: undefined,
    loading: false,
  }
};

export function PartsReducer(state = INITIAL_PARTS_STATE, action: any): any {
  switch (action.type) {
    /////////////////////////////////////////////Part Search/////////////////////////////////////////
    case ActionTypes.PART_SEARCH_UPDATE_SEARCH_TEXT: {
      return {
        ...state,
        partNumberSearch: action.filterText == null ? '' : action.filterText,
        partSearchGrid: {
          ...state.partSearchGrid,
          currentPage: 0,
        },
      };
    }

    case ActionTypes.PART_SEARCH_LOAD_DATA: {
      return {
        ...state,
        partSearchGrid: {
          ...state.partSearchGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.PART_SEARCH_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        partSearchGrid: {
          ...state.partSearchGrid,
          data: flatPartSearchData(action.payload.Result),
          total: action.payload.TotalItems?.split(',')[0]?.replace(
            'Total Items: ',
            ''
          ),
          loading: false,
        },
      };
    }
    case ActionTypes.PART_SEARCH_LOAD_DATA_ERROR: {
      return {
        ...state,
        partSearchGrid: {
          ...state.partSearchGrid,
          loading: false,
          error: action.payload,
          data: null,
          total: 0,
        },
      };
    }
    case ActionTypes.PART_SEARCH_PAGE_CHANGED: {
      return {
        ...state,
        partSearchGrid: {
          ...state.partSearchGrid,
          currentPage: action.payload,
        },
      };
    }
    case ActionTypes.PART_SEARCH_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        partSearchGrid: {
          ...state.partSearchGrid,
          pageSize: action.payload,
        },
      };
    }
    case ActionTypes.PART_SEARCH_EXPORT_DATA: {
      return {
        ...state,
        partSearchGrid: {
          ...state.partSearchGrid,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.PART_SEARCH_EXPORT_DATA_SUCCESS: {
      downLoadFile(
        action.payload,
        'application/ms-excel',
        'PartSearch.xlsx'
      );
      return {
        ...state,
        partSearchGrid: {
          ...state.partSearchGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.PART_SEARCH_EXPORT_DATA_ERROR: {
      return {
        ...state,
        partSearchGrid: {
          ...state.partSearchGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.PART_SEARCH_CLEAR_GRID: {
      return {
        ...state,
        partSearchGrid: {
          ...state.partSearchGrid,
          data: null,
          total: 0,
        },
      };
    }

    /////////////////////////////////////////////Cross Search/////////////////////////////////////////
    case ActionTypes.CROSS_SEARCH_UPDATE_SEARCH_TEXT: {
      return {
        ...state,
        crossSearchText: action.filterText == null ? '' : action.filterText,
        crossSearchGrid: {
          ...state.crossSearchGrid,
          currentPage: 0,
        },
      };
    }

    case ActionTypes.CROSS_SEARCH_LOAD_DATA: {
      return {
        ...state,
        crossSearchGrid: {
          ...state.crossSearchGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.CROSS_SEARCH_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        crossSearchGrid: {
          ...state.crossSearchGrid,
          data: flatCrossSearchData(action.payload?.Result?.CrossData[0]),
          //total: action.payload.TotalItems?.split(',')[0]?.replace("Total Items: ",''),
          loading: false,
        },
      };
    }
    case ActionTypes.CROSS_SEARCH_LOAD_DATA_ERROR: {
      return {
        ...state,
        crossSearchGrid: {
          ...state.crossSearchGrid,
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    }

    /*-------------------alerts--------------------------------*/
    case ActionTypes.ALERT_LOAD_DATA: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.ALERT_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          data: action.payload.data,
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.ALERT_LOAD_DATA_ERROR: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.P5_ALERT_LOAD_DATA: {
      return {
        ...state,
        p5alertGrid: {
          ...state.p5alertGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.P5_ALERT_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        p5alertGrid: {
          ...state.p5alertGrid,
          data: action.payload.data,
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.P5_ALERT_LOAD_DATA_ERROR: {
      return {
        ...state,
        p5alertGrid: {
          ...state.p5alertGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.PART_DETAILS_RECORD_LOAD_DATA: {
      return {
        ...state,
        partDetailsRecordGrid: {
          ...state.partDetailsRecordGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.PART_DETAILS_RECORD_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        partDetailsRecordGrid: {
          ...state.partDetailsRecordGrid,
          lifeCycleRiskSummaryDetails: action.payload.lifeCycleRiskSummaryDetails,
          partDataDetails:action.payload.partDataDetails,
          priceHistoryDetails:action.payload.priceHistoryDetails,
          loading: false,
        },
      };
    }
    case ActionTypes.PART_DETAILS_RECORD_LOAD_DATA_ERROR: {
      return {
        ...state,
        partDetailsRecordGrid: {
          ...state.partDetailsRecordGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.ALERT_ADD_RECORD: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          add: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.ALERT_ADD_RECORD_SUCCESS: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          add: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.ALERT_ADD_RECORD_ERROR: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          add: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.ALERT_EDIT_RECORD: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          edit: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.ALERT_EDIT_RECORD_SUCCESS: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          edit: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.ALERT_EDIT_RECORD_ERROR: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          edit: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.GET_ALERT_RECORD_BY_ID: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GET_ALERT_RECORD_BY_ID_SUCCESS: {
      return {
        ...state,
        alertDetailsData: action.payload,
        loading: false,
      };
    }
    case ActionTypes.GET_ALERT_RECORD_BY_ID_ERROR: {
      return {
        ...state,
        alertDetailsData: action.payload,
        loading: false,
      };
    }

    case ActionTypes.GET_P5_ALERT_RECORD_BY_ID: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GET_P5_ALERT_RECORD_BY_ID_SUCCESS: {
      return {
        ...state,
        p5alertDetailsGrid: action.payload,
        loading: false,
      };
    }
    case ActionTypes.GET_P5_ALERT_RECORD_BY_ID_ERROR: {
      return {
        ...state,
        p5alertDetailsGrid: action.payload,
        loading: false,
      };
    }

    case ActionTypes.ALERT_DELETE_RECORD: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          delete: undefined,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.ALERT_DELETE_RECORD_SUCCESS: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          delete: 'deleted',
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.ALERT_DELETE_RECORD_ERROR: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          delete: action.payload,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.ALERT_PAGE_CHANGED: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          currentPage: action.payload,
        },
      };
    }
    case ActionTypes.ALERT_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        alertGrid: {
          ...state.alertGrid,
          pageSize: action.payload,
        },
      };
    }

    /*----------------------------------------alert details------------------------------*/
    case ActionTypes.CROSS_SEARCH_EXPORT_DATA: {
      return {
        ...state,
        crossSearchGrid: {
          ...state.crossSearchGrid,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.CROSS_SEARCH_EXPORT_DATA_SUCCESS: {
      downLoadFile(
        action.payload,
        'application/ms-excel',
        'CrossSearch.xlsx'
      );
      return {
        ...state,
        crossSearchGrid: {
          ...state.crossSearchGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.CROSS_SEARCH_EXPORT_DATA_ERROR: {
      return {
        ...state,
        crossSearchGrid: {
          ...state.crossSearchGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.CROSS_SEARCH_CLEAR_GRID: {
      return {
        ...state,
        crossSearchGrid: {
          ...state.crossSearchGrid,
          data: null,
          total: 0,
        },
      };
    }
    case ActionTypes.ALERT_Detials_PAGE_CHANGED: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          currentPage: action.page,
        },
      };
    }

    case ActionTypes.ALERT_Details_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          pageSize: action.pageSize,
        },
      };
    }

    case ActionTypes.Alert_Details_FiLTER_CHANGED: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          filter: action?.filter?.filters,
        },
      };
    }

    case ActionTypes.Alert_Details_SORT_CHANGED: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          sort: action?.sort,
        },
      };
    }

    case ActionTypes.P5_ALERT_Details_PAGE_CHANGED: {
      return {
        ...state,
        p5alertDetailsGrid: {
          ...state.p5alertDetailsGrid,
          currentPage: action.page,
        },
      };
    }

    case ActionTypes.P5_ALERT_Details_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        p5alertDetailsGrid: {
          ...state.p5alertDetailsGrid,
          pageSize: action.pageSize,
        },
      };
    }

    case ActionTypes.p5_Alert_Details_FILTER_CHANGED: {
      return {
        ...state,
        p5alertDetailsGrid: {
          ...state.p5alertDetailsGrid,
          filter: action?.filter?.filters,
        },
      };
    }

    case ActionTypes.p5_Alert_Details_SORT_CHANGED: {
      return {
        ...state,
        p5alertDetailsGrid: {
          ...state.p5alertDetailsGrid,
          sort: action?.sort,
        },
      };
    }
    ///////////////////////////////////////////// Components /////////////////////////////////////////
    case ActionTypes.COMPONENTS_SELECTED_YEAR_UPDATE: {
      return {
        ...state,
        selectedYear: action.filterText == null ? '' : action.filterText,
        componentsGrid: {
          ...state.componentsGrid,
          currentPage: 0,
        },
      };
    }

    case ActionTypes.COMPONENTS_LOAD_DATA: {
      return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.COMPONENTS_LOAD_DATA_SUCCESS: {
       return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          headers: getGridViewHearders(action.payload.header),
          data: action.payload.data,
          currentPage: action.payload.currentPage,
          offset: action.payload.offset,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
          loading: false,
        },
      };
    }
    case ActionTypes.COMPONENTS_LOAD_DATA_ERROR: {
      return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          loading: false,
          error: action.payload,
          data: null,
          total: action.payload,
        },
      };
    }
    case ActionTypes.COMPONENTS_PAGE_CHANGED: {
       return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          currentPage: action.page,
        },
      };
    }
    case ActionTypes.COMPONENTS_FILTER_CHNAGED: {
      
       return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          filter: action?.filter?.filters,
        },
      };
    }
    case ActionTypes.COMPONENTS_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          pageSize: action.pageSize,
        },
      };
    }
    case ActionTypes.COMPONENTS_SORT_CHANGED: {
 
      return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          sort: action?.sort,
        },
      };
    }
    case ActionTypes.COMPONENTS_EXPORT_DATA: {
      return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.COMPONENTS_EXPORT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.COMPONENTS_EXPORT_DATA_ERROR: {
      return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.COMPONENTS_CLEAR_GRID: {
      return {
        ...state,
        componentsGrid: {
          ...state.componentsGrid,
          data: null,
          total: 0,
        },
      };
    }
    case ActionTypes.LOAD_COMPONENT: {
      return {
        ...state,
        loadedComponent: {
          ...state.loadedComponent,
        },
      };
    }
    case ActionTypes.LOAD_COMPONENT_SUCCESS: {
      return {
        ...state,
        loadedComponent: {
          ...state.loadedComponent,
        },
      };
    }
    case ActionTypes.LOAD_COMPONENT_ERROR: {
      return {
        ...state,
        loadedComponent: {
          ...state.loadedComponent,
        },
      };
    }
    ///////////////////////////////alert detials//////////////////////////////////
    case ActionTypes.EXPORT_ALL_ALERT_DATA: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.EXPORT_ALL_ALERT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.EXPORT_ALL_ALERT_DATA_ERROR: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.SEND_SELECTED_ALERT: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.SEND_SELECTED_ALERT_SUCCESS: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          sendselected: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.SEND_SELECTED_ALERT_ERROR: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.ALERT_DETAILS_LOAD_DATA: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.SET_ALERT_DATA: {
      return {
        ...state,
        alertData: {
          ...state.alertData,
          alertTitle: action.alertTitle,
          alertId: action.alertId,
          isError: true,
        },
      };
    }
    case ActionTypes.ALERT_DETAILS_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          data:flatAlertSearchData(action?.payload?.data),
          currentPage: action?.payload?.currentPage,
          offset: action?.payload?.offset,
          pageSize: action?.payload?.pageSize,
          total: action?.payload?.total,
          loading: false,
        },
      };
    }
    case ActionTypes.ALERT_DETAILS_LOAD_DATA_ERROR: {
      return {
        ...state,
        alertDetailsGrid: {
          ...state.alertDetailsGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    ///////////////////////////////////////////// CBOM ////////////////////////////////////////
    case ActionTypes.CBOM_LOAD_DATA: {
      return {
        ...state,
        cbomGrid: {
          ...state.cbomGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.CBOM_LOAD_DATA_SUCCESS: { 
      return {
        ...state,
        cbomGrid: {
          ...state.cbomGrid,
          headers: getCbomGridHearders(action.payload.header),
          data: action?.payload?.data,
          currentPage: action?.payload?.currentPage,
          offset: action?.payload?.offset,
          pageSize: action?.payload?.pageSize,
          total: action?.payload?.total,
          loading: false,
        },
      };
    }
    case ActionTypes.CBOM_LOAD_DATA_ERROR: {
      return {
        ...state,
        cbomGrid: {
          ...state.cbomGrid,
          loading: false,
          error: action.payload,
          data: null,
          total: 0,
        },
      };
    }
    case ActionTypes.CBOM_PAGE_CHANGED: { 
       return {
       ...state,
       cbomGrid: {
         ...state.cbomGrid,
         currentPage: action.page,
       },
     };
   }
   case ActionTypes.CBOM_FILTER_CHNAGED: {
      return {
       ...state,
       cbomGrid: {
         ...state.cbomGrid,
         filter: action?.filter?.filters,
       },
     };
   }
   case ActionTypes.CBOM_PAGE_SIZE_CHANGED: {
     return {
       ...state,
       cbomGrid: {
         ...state.cbomGrid,
         pageSize: action.pageSize,
       },
     };
   }
   case ActionTypes.CBOM_SORT_CHANGED: {
    return {
      ...state,
      cbomGrid: {
        ...state.cbomGrid,
        sort: action?.sort,
      },
    };
  }
    case ActionTypes.GET_CONTRACT_MANFACTURER: {
      return {
        ...state,
        contractManufacturer: {
          ...state.contractManufacturer,
          loading: true,
        },
      };
    }
    case ActionTypes.GET_CONTRACT_MANFACTURER_SUCCESS: {
      return {
        ...state,
        contractManufacturer: {
          ...state.contractManufacturer,
          data: action.payload,
          loading: false,
        },
      };
    }
    case ActionTypes.GET_CONTRACT_MANFACTURER_ERROR: {
      return {
        ...state,
        contractManufacturer: {
          ...state.contractManufacturer,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.CBOM_CLEAR_GRID: {
      return {
        ...state,
        cbomGrid: {
          ...state.cbomGrid,
          data: null,
          total: 0,
        },
      };
    }
    case ActionTypes.PART_CBOM_EXPORT_DATA: {
      return {
        ...state,
        cbomGrid: {
          ...state.cbomGrid,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.PART_CBOM_EXPORT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        cbomGrid: {
          ...state.cbomGrid,
          export: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.PART_CBOM_EXPORT_DATA_ERROR: {
      return {
        ...state,
        cbomGrid: {
          ...state.cbomGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.GET_CMT_ALERT_PART_DETAILS_BY_ID: {
      return {
        ...state,
        cmtAlertPartDetails: {
          ...state.cmtAlertPartDetails,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.GET_CMT_ALERT_PART_DETAILS_BY_ID_SUCCESS: {
      return {
        ...state,
        cmtAlertPartDetails: {
          ...action?.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.GET_CMT_ALERT_PART_DETAILS_BY_ID_ERROR: {
      return {
        ...state,
        cmtAlertPartDetails: {
          ...state.cmtAlertPartDetails,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.GET_P5_ALERT_PART_DETAILS_BY_ID: {
      return {
        ...state,
        p5AlertPartDetails: {
          ...state.p5AlertPartDetails,
          loading: true,
          error: false,
        },
      };
    }

    case ActionTypes.GET_P5_ALERT_PART_DETAILS_BY_ID_SUCCESS: {
      return {
        ...state,
        p5AlertPartDetails: {
          ...action?.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case ActionTypes.GET_P5_ALERT_PART_DETAILS_BY_ID_ERROR: {
      return {
        ...state,
        p5AlertPartDetails: {
          ...state.p5AlertPartDetails,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.ADD_CMT_ALERT_PART_COMMENT: {
      return {
        ...state,
        partAlertComment: {
          loading: true,
          error: false,
        }
      };
    }

    case ActionTypes.ADD_CMT_ALERT_PART_COMMENT_SUCCESS: {
      return {
        ...state,
        partAlertComment: {
          created: true,
          loading: false,
          error: undefined,
        }

      };
    }

    case ActionTypes.ADD_CMT_ALERT_PART_COMMENT_ERROR: {
      return {
        ...state,
        partAlertComment: {
          loading: false,
          error: action.payload
        }
      };
    }

    case ActionTypes.GET_USERS: {
      return {
        ...state,
        users: {
          loading: true,
          error: false,
        }
      };
    }

    case ActionTypes.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          data: action.payload,
          loading: false,
          error: undefined,
        }

      };
    }

    case ActionTypes.GET_USERS_ERROR: {
      return {
        ...state,
        users: {
          loading: false,
          error: action.payload
        }
      };
    }
    case ActionTypes.GET_PRIORITIES: {
      return {
        ...state,
        priorities: {
          loading: true,
          error: false,
        }
      };
    }

    case ActionTypes.GET_PRIORITIES_SUCCESS: {
      return {
        ...state,
        priorities: {
          data: action.payload,
          loading: false,
          error: undefined,
        }

      };
    }

    case ActionTypes.GET_PRIORITIES_ERROR: {
      return {
        ...state,
        priorities: {
          loading: false,
          error: action.payload
        }
      };
    }
    case ActionTypes.EDIT_ALERT_DETAILS: {
      return {
        ...state,
        editAlertDetails: {
          loading: true,
          error: false,
        }
      };
    }

    case ActionTypes.EDIT_ALERT_DETAILS_SUCCESS: {
      return {
        ...state,
        editAlertDetails: {
          data: action.payload,
          loading: false,
          error: undefined,
        }

      };
    }

    case ActionTypes.EDIT_ALERT_DETAILS_ERROR: {
      return {
        ...state,
        editAlertDetails: {
          loading: false,
          error: action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
}
