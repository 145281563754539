import { Component, Input } from '@angular/core';
import { FilterService } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
@Component({
  selector: 'app-custom-dropdown-grid-filter',
  templateUrl: './custom-dropdown-grid-filter.component.html',
  styleUrls: ['./custom-dropdown-grid-filter.component.scss'],
})
export class CustomDropdownGridFilterComponent {
  @Input() filter: FilterDescriptor;
  @Input() filterService: FilterService;
  @Input() field: string;

  public selectedValue: any;

  public data: Array<{ text: string, value: boolean }> = [
    { text: 'Active', value: true },
    { text: 'Archived', value: false }
  ];


  constructor() {}

  ngOnInit(): void {
    if (this.filter && this.filter.value !== undefined) {
      this.selectedValue = this.filter.value;
    }
  }
  public onChange(value: any): void {

    this.selectedValue = value; 
    const filter: FilterDescriptor = value !== null
    ? { field: this.field, operator: 'eq', value: value.value }
    : { field: '', operator: 'eq', value: null };

  this.filterService.filter({
    filters: filter ? [filter] : [],
    logic: 'and'
  });
  }
}
