import { isDate } from 'lodash';
import {
  actionMenu,
  ColumnType,
  GridHeaderData,
} from 'src/app/shared/shared.interface';

export const headers: GridHeaderData[] = [
  {
    field: 'quoteName',
    title: 'Quote Name',
    type: ColumnType.LINKWITHNAVIGATIONROUTE,
    filterable: true,
    filterColName:'quoteName',
  },
  {
    field: 'noOfParts',
    title: '# of Parts',
    filterable: true,
    filterColName: 'noOfParts',

  },
    {
    field: 'rfqInDate',
    title: 'RFQ in Date',
    filterable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName: 'rfqInDate',
    getCellValue: (data: any) => {
      if (data?.rfqInDate !== null && data?.rfqInDate !== '0001-01-01T00:00:00') {
        return data?.rfqInDate;
      }
      return null;
    },  
  },
  {
    field: 'dueDate',
    title: 'Due Date',
    filterable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName: 'dueDate',
    getCellValue: (data: any) => {
      if (data?.dueDate !== null && data?.dueDate !== '0001-01-01T00:00:00') {
        return data?.dueDate;
      }
      return null;
    },  
  },
  {
    field: 'operationStatus',
    title: 'Status',
    filterable: true,
    filterColName: 'operationStatus',

  },
  {
    field: 'priority',
    title: 'priority',
    filterable: true,
    filterColName: 'priority',

  },
  // {
  //   field: 'minPrice',
  //   title: 'Min Price (USD $)',
  //   filterable: true,
  //   filterColName: 'minPrice',
  // },
  // {
  //   field: 'maxPrice',
  //   title: 'Max Price (USD $)',
  //   filterable: true,
  //   filterColName: 'maxPrice',
  // },
  {
    field: 'estimateTotalPrice',
    title: 'Est. Total Price',
    filterable: true,
    filterColName: 'estimateTotalPrice',
  },
  // {
  //   field: 'minLeadTime',
  //   title: 'Min. LT (Days)',
  //   filterable: true,
  //   filterColName: 'minLeadTime',
  // },
  // {
  //   field: 'maxLeadTime',
  //   title: 'Max. LT (Days)',
  //   filterable: true,
  //   filterColName: 'maxLeadTime',
  // },
  {
    field: 'noOfResponses',
    title: '# of Responses',
    filterable: true,
    filterColName: 'noOfResponses',
  },
  {
    field: 'active',
    title: 'Archive Status',
    filterable: true,
    filterColName: 'active',
    isbool: true,
    getCellValue: (data: any) => {
      if (data?.active != null) {
        return data?.active ? 'Active' : 'Archived';
      }
      return null;
    },
  },
  {
    // actions: [
    //   {
    //     componentData: {},
    //     actionType: actionMenu.download,
    //   },
    // ],
    title: 'Price Table',
    field: 'Actions',
    getCellActions: (data: any, column: any) => {
      if (column?.title === 'Price Table') {
        return [
          {
            componentData: {},
            actionType: actionMenu.download,
          },
        ];
      }
      else if(column?.title === 'Actions'){
        if (data?.active) {
          return [
              {
                componentData: {},
                  actionType: actionMenu.delete,
                  tooltip: 'Click to delete this quote',
              },
              {
                componentData: {},
                  actionType: actionMenu.archive,
                  tooltip: 'Click to Archive this quote',
              },
          ];
        }
        else
        return [
          {
            componentData: {},
              actionType: actionMenu.delete,
              tooltip: 'Click to delete this quote',
          },
          {
            componentData: {},
              actionType: actionMenu.unarchive,
              tooltip: 'Click to unArchive this quote',
          }
        ];

      }
    },
  },
  {
    title: 'Actions',
    field: 'Actions',

  },
];
export const priceHistoryHeaders: GridHeaderData[] = [
  {
    field: 'rowId',
    title: 'Row No',
    filterable: false,
    sortable: false,
  },
  
  {
    field: 'cpn',
    title: 'CPN',
    filterable: false,
    sortable: false,
    filterColName:'cpn',
  },
  {
    field: 'mpn',
    title: 'MPN',
    filterable: false,
    sortable: false,
    filterColName:'mpn',

  },
  {
    field: 'mfr',
    title: 'Manufacturer',
    filterable: false,
    sortable: false,
    filterColName:'mfr',

  },
  {
    field: 'siteName',
    title: 'Site Name',
    filterable: true,
    filterColName: 'SiteName',
  },
  {
    field: 'country',
    title: 'Country',
    filterable: true,
    filterColName: 'Country',
  },
  {
    field: 'address',
    title: 'Address',
    filterable: true, 
    filterColName: 'Address',
  },
  {
    field: 'contractManufacturerName',
    title: 'Contract Manufacturer Name',
    filterable: true,
    filterColName: 'ContractManufacturerName',
  },
  {
    field: 'rfqTo',
    title: 'RFQ To',
    filterable: false,
    sortable: false,
    filterColName:'rfqTo',
  },
  
  {
    field: 'responderTypeName',
    title: 'Responder Type',
    filterable: false,
    sortable: false,
    filterColName:'responderTypeName',
  },
  {
    field: 'rfqStatus',
    title: 'Status',
    filterable: false,
    sortable: false,
    filterColName:'rfqStatus',

  },
  {
    field: 'price',
    title: 'Price (USD $)',
    filterable: false,
    sortable: false,
    filterColName:'price',
    getCellValue: (data: any) => {
       
      return data?.price != null ? '$' + data?.price.toString() : null;
    },
  },
  {
    field: 'seAveragePrice',
    title: 'SE AVG Price',
    filterable: false,
    filterColName:'seAveragePrice',
    sortable: false,
    getCellValue: (data: any) => {
      return data?.seAveragePrice != null ? '$' + data?.seAveragePrice.toString() : null;
    },
  },
  {
    field: 'seMinPrice',
    title: 'SE Min Price',
    filterColName:'seMinPrice',
    filterable: false,
    sortable: false,
    getCellValue: (data: any) => {
      return data?.seMinPrice != null ? '$' + data?.seMinPrice.toString() : null;
    },
  },
  {
    field: 'leadTime',
    title: 'Lead time (days)',
    filterColName:'leadTime',
    filterable: false,
    sortable: false,
  },
  {
    field: 'seMinLeadTime',
    title: 'SE Min LT',
    filterColName:'seMinLeadTime',
    filterable: false,
    sortable: false,
  },
  {
    field: 'seMaxLeadTime',
    title: 'SE Max LT',
    filterColName:'seMaxLeadTime',
    filterable: false,
    sortable: false,
  },
  {
    field: 'moq',
    title: 'MOQ',
    filterColName:'moq',
    filterable: false,
    sortable: false,
  },
  {
    field: 'lastResponseDate',
    title: 'Lasted Response',
    filterable: false,
    sortable: false,
    type: ColumnType.DATE,
    filterColName:'lastResponseDate',
    isDate:true,
    getCellValue: (data: any) => {
      if (data?.lastResponseDate !== null && data?.lastResponseDate !== '0001-01-01T00:00:00') {
        return data?.lastResponseDate;
      }
      return null;
    },  
     
  },
  {
    field: 'expirationDate',
    title: 'Expiration Date',
    filterable: false,
    sortable: false,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'expirationDate',
    getCellValue: (data: any) => {
      if (data?.expirationDate !== null && data?.expirationDate !== '0001-01-01T00:00:00') {
        return data?.expirationDate;
      }
      return null;
    },      
     
  },
  // {
  //   field: 'rebate',
  //   title: 'Rebate',
  //   filterable: false,
  //   sortable: false,
  //   filterColName:'rebate',
  // },
  {
    field: 'awarded',
    title: 'Awarded',
    filterable: false,
    sortable: false,
    filterColName:'awarded',

    getCellValue: (data: any) => {
      if (data?.awarded != null) {
        return data?.awarded ? 'Yes' : 'No';
      }
      return null;
    },
  },
  {
    field: 'awardedNotificationDate',
    title: 'Awarded Notification Date',
    filterable: false,
    sortable: false,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'awardedNotificationDate',
    getCellValue: (data: any) => {
      if (data?.awardedNotificationDate !== null && data?.awardedNotificationDate !== '0001-01-01T00:00:00') {
        return data?.awardedNotificationDate;
      }
      return null;
    },       
  },
  // {
  //   field: 'awardedByName',
  //   title: 'Awarded By',
  //   filterable: true,
  //   sortable: true,
  // },
  {
    field: 'ackStatus',
    title: 'ACK status',
    filterable: false,
    sortable: false,
    filterColName:'ackStatus',

  },
  {
    field: 'ackNotification',
    title: 'ACK Notifications',
    filterable: false,
    sortable: false,
    filterColName:'ackNotification',

  },
  {
    field: 'ackDate',
    title: 'ACK Date',
    filterable: false,
    sortable: false,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'ackDate',
    getCellValue: (data: any) => {
      if (data?.ackDate !== null && data?.ackDate !== '0001-01-01T00:00:00') {
        return data?.ackDate;
      }
      return null;
    },       
  },
  // {
  //   field: 'canceledByName',
  //   title: 'Canceled by',
  //   filterable: true,
  //   sortable: true,
  // },
  {
    field: 'email',
    title: 'Ref. Email',
    filterable: false,
    sortable: false,
    filterColName:'email',

  },
];

export const QuoteDetailsHeaders: GridHeaderData[] = [
  {
    field: 'rowId',
    title: 'Row No',
    filterable: true,
    sortable: true,
    filterColName:'rowId',

  },
  {
    field: 'noOfResponses',
    title: 'No. responses',
    type: ColumnType.LINKWITHNAVIGATIONROUTE,
    filterable: true,
    sortable: true,
    filterColName:'noOfResponses',

  },
  {
    field: 'cpn',
    title: 'CPN',
    filterable: true,
    sortable: true,
    filterColName:'cpn',

  },
  {
    field: 'mpn',
    title: 'MPN',
    filterable: true,
    sortable: true,
    filterColName:'mpn',

  },
  {
    field: 'mfr',
    title: 'Manufacturer',
    filterable: true,
    sortable: true,
    filterColName:'mfr',

  },
  {
    field: 'description',
    title: 'Description',
    filterable: true,
    sortable: true,
    filterColName:'description',

  }, 
  {
    field: 'siteName',
    title: 'CM Site Name',
    filterable: true,
    filterColName: 'SiteName',
  },
  {
    field: 'country',
    title: 'Country',
    filterable: true,
    filterColName: 'Country',
  },
  {
    field: 'contractManufacturerName',
    title: 'CM',
    filterable: true,
    filterColName: 'ContractManufacturerName',
  },
  {
    field: 'eau',
    title: 'EAU',
    filterable: true,
    filterColName: 'eau',
  },
  {
    field: 'rfqTo',
    title: 'RFQ To',
    filterable: true,
    sortable: true,
    filterColName:'rfqTo',

  },  
  {
    field: 'responderTypeName',
    title: 'Responder Type',
    filterable: true,
    sortable: true,
    filterColName:'responderTypeName',

  }, 
  {
    field: 'rfqStatus',
    title: 'Status',
    filterable: true,
    sortable: true,
    filterColName:'rfqStatus',

  },
  {
    field: 'currentUnitPrice',
    title: 'Current Unit Price (USD $)',
    filterable: true,
    sortable: true,
    filterColName:'currentUnitPrice',

    getCellValue: (data: any) => {
      return data?.currentUnitPrice != null ? '$' + data?.currentUnitPrice.toString() : null;
    },
  },
  {
    field: 'supplierPrice',
    title: 'Supplier Price',
    filterable: true,
    sortable: true,
    filterColName:'supplierPrice',

    // getCellValue: (data: any) => {
    //   return data?.supplierPrice != null ? '$' + data?.supplierPrice.toString() : null;
    // },
  },
  {
    field: 'supplierCurrency',
    title: 'Supplier Currency',
    filterable: true,
    sortable: true,
    filterColName:'supplierCurrency',
  },
  {
    field: 'suppRateToUSD',
    title: 'Supplier Exchange rate to USD',
    filterable: true,
    sortable: true,
    filterColName:'suppRateToUSD',
  },
  {
    field: 'price',
    title: 'Price in USD',
    filterable: true,
    sortable: true,
    filterColName:'price',
    getCellValue: (data: any) => {
      return data?.price != null ? '$' + data?.price.toString() : null;
    },
  },
  {
    field: 'inflationDeflation',
    title: 'Inflation / Deflation',
    filterable: true,
    sortable: true,
    filterColName:'inflationDeflation',
  },
  {
    field: 'bestPrice',
    title: 'Best Price',
    filterable: true,
    sortable: true,
    filterColName:'bestPrice',
  },
  {
    field: 'totalSpend',
    title: 'Total Spend',
    filterable: true,
    sortable: true,
    filterColName:'totalSpend',
  },
  {
    field: 'supplierTotalSpend',
    title: 'Supplier Total Spend',
    filterable: true,
    sortable: true,
    filterColName:'supplierTotalSpend',
  },
  {
    field: 'spendDiff',
    title: 'Spend Diff',
    filterable: true,
    sortable: true,
    filterColName:'spendDiff',
  },  
  {
    field: 'uploadedRemarks',
    title: 'Uploaded Remarks',
    filterable: true,
    sortable: true,
    filterColName:'uploadedRemarks',
  },
  {
    field: 'commodity',
    title: 'Commodity',
    filterable: true,
    sortable: true,
    filterColName:'commodity',
  },
  {
    field: 'commodityType',
    title: 'Commodity Type',
    filterable: true,
    sortable: true,
    filterColName:'commodityType',
  },  
  {
    field: 'correctedMPN',
    title: 'Corrected MPN',
    filterable: true,
    sortable: true,
    filterColName:'correctedMPN',
  }, 
  {
    field: 'seTaxonomy',
    title: 'SE Taxonomy Path',
    filterable: true,
    sortable: true,
    filterColName:'seTaxonomy',
  },
  {
    field: 'leadTime',
    title: 'Supplier Lead time (weeks)',
    filterable: true,
    sortable: true,
    filterColName:'leadTime',
  }, 
  {
    field: 'moq',
    title: 'Supplier MOQ',
    filterable: true,
    sortable: true,
    filterColName:'moq',
  }, 
  {
    field: 'supplierCustomSource',
    title: 'Supplier Custom Source',
    filterable: true,
    sortable: true,
    filterColName:'supplierCustomSource',
  },
  {
    field: 'supplierRemarks',
    title: 'Supplier Remarks',
    filterable: true,
    sortable: true,
    filterColName:'supplierRemarks',
  },
  {
    field: 'expirationDate',
    title: 'Expire Date',
    filterable: true,
    sortable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'expirationDate',  
    getCellValue: (data: any) => {
      if (data?.expirationDate !== null && data?.expirationDate !== '0001-01-01T00:00:00') {
        return data?.expirationDate;
      }
      return null;
    },     
  },
  {
    field: 'lastResponseDate',
    title: 'Latest Response Date',
    filterable: true,
    sortable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'lastResponseDate',   
    getCellValue: (data: any) => {
      if (data?.lastResponseDate !== null && data?.lastResponseDate !== '0001-01-01T00:00:00') {
        return data?.lastResponseDate;
      }
      return null;
    },  
  },
  {
    field: 'email',
    title: 'Ref. Email',
    filterable: true,
    sortable: true,
    filterColName:'email',
  },
  {
    field: 'awarded',
    title: 'Awarded',
    filterable: true,
    sortable: true,
    filterColName:'awarded',

    getCellValue: (data: any) => {
      if (data?.awarded != null) {
        return data?.awarded ? 'Yes' : 'No';
      }
      return null;
    },
  },
  {
    field: 'awardedNotificationDate',
    title: 'Awarded Notification Date',
    filterable: true,
    sortable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'awardedNotificationDate',
    getCellValue: (data: any) => {
      if (data?.awardedNotificationDate !== null && data?.awardedNotificationDate !== '0001-01-01T00:00:00') {
        return data?.awardedNotificationDate;
      }
      return null;
    },  
     
  },
  {
    field: 'awardedByName',
    title: 'Awarded By',
    filterable: true,
    sortable: true,
    filterColName:'awardedByName',
  },
  {
    field: 'ackStatus',
    title: 'ACK status',
    filterable: true,
    sortable: true,
    filterColName:'ackStatus',

  },
  {
    field: 'acknotification',
    title: 'ACK Notifications',
    filterable: true,
    sortable: true,
    filterColName:'acknotification',
    getCellValue: (data: any) => {
      if (data?.acknotification != null) {
        return data?.acknotification ? 'Yes' : 'No';
      }
      return null;
    },
  },
  {
    field: 'ackDate',
    title: 'ACK Date',
    filterable: true,
    sortable: true,
    type: ColumnType.DATE,
    isDate:true,
    filterColName:'ackDate',
    getCellValue: (data: any) => {
      if (data?.ackDate !== null && data?.ackDate !== '0001-01-01T00:00:00') {
        return data?.ackDate;
      }
      return null;
    },       
  },
  {
    field: 'canceledByName',
    title: 'Canceled by',
    filterable: true,
    sortable: true,
    filterColName:'canceledByName',

  },  
  /*{
    field: 'address',
    title: 'Address',
    filterable: true, 
    filterColName: 'Address',
  }, */ 
  {
    field: 'seAveragePrice',
    title: 'SE AVG Price',
    filterable: true,
    sortable: true,
    filterColName:'seAveragePrice',

    getCellValue: (data: any) => {
      return data?.seAveragePrice != null ? '$' + data?.seAveragePrice.toString() : null;
    },
  },
  {
    field: 'seMinPrice',
    title: 'SE Min Price',
    filterable: true,
    sortable: true,
    filterColName:'seMinPrice',

    getCellValue: (data: any) => {
      return data?.seMinPrice != null ? '$' + data?.seMinPrice.toString() : null;
    },
  },  
  {
    field: 'seMinLeadTime',
    title: 'SE Min Lead Time',
    filterable: true,
    sortable: true,
    filterColName:'seMinLeadTime',
  },
  {
    field: 'seMaxLeadTime',
    title: 'SE Max Lead Time',
    filterable: true,
    sortable: true,
    filterColName:'seMaxLeadTime',
  },
  {
    field: 'seLifecycleStatus',
    title: 'Se Life cycle',
    filterable: true,
    sortable: true,
    filterColName:'seLifecycleStatus',
  },
  {
    field: 'serohs',
    title: 'SE ROHS',
    filterable: true,
    sortable: true,
    filterColName:'serohs',
  },
  {
    field: 'sereach',
    title: 'SE REACH',
    filterable: true,
    sortable: true,
    filterColName:'sereach',
  },
  {
    field: 'seInventory',
    title: 'SE Inventory',
    filterable: true,
    sortable: true,
    filterColName:'seInventory',
  },
  {
    field: 'secoo',
    title: 'COO',
    filterable: true,
    sortable: true,
    filterColName:'secoo',
  },
  {
    field: 'sehtusaCode',
    title: 'HTUSA Code',
    filterable: true,
    sortable: true,
    filterColName:'sehtusaCode',
  },
  {
    field: 'seChinaTariffImpact',
    title: 'China Tariff impact',
    filterable: true,
    sortable: true,
    filterColName:'seChinaTariffImpact',
  },
  {
    field: 'seLifecycleRiskGrade',
    title: 'SE Lifecycle Risk Grade',
    filterable: true,
    sortable: true,
    filterColName:'seLifecycleRiskGrade',
  },
  {
    field: 'seeol',
    title: 'SE Estimated Years to EOL',
    filterable: true,
    sortable: true,
    filterColName:'seeol',
  },
  {
    field: 'seCounterfeitRisk',
    title: 'SE Counterfeit Risk ',
    filterable: true,
    sortable: true,
    filterColName:'seCounterfeitRisk',
  },
  {
    field: 'seDatasheet',
    title: 'SE Datasheet',
    filterable: true,
    sortable: true,
    type:ColumnType.LINK,
    filterColName:'seDatasheet',
  },
  
  // {
  //   field: 'rebate',
  //   title: 'Rebate',
  //   filterable: true,
  //   sortable: true,
  //   filterColName:'rebate',

  // },     
  
];

export const Manheaders: GridHeaderData[] = [
  {
    field: 'manName',
    title: 'Manufacturer',
    filterable: true,
  },
  {
    field: 'statusName',
    title: 'Status',
    filterable: true,
  },
];
export const ManLOAheaders: GridHeaderData[] = [
  {
    field: 'contractManufacturerName',
    title: 'CM Manufacturer',
    filterable: true,
  },
  {
    field: 'cmSiteName',
    title: 'Site Name',
    filterable: true,
  },
];


export const Disheaders: GridHeaderData[] = [
  {
    field: 'distName',
    title: 'Distributor Name',
    filterable: true,
  },
  {
    field: 'typeName',
    title: 'Type',
    filterable: true,
  },
];
export const Colheaders: GridHeaderData[] = [
  {
    field: 'columnName',
    title: 'Column Name',
    filterable: true,
  },
];
export const EngBomDetailsheaders: GridHeaderData[] = [
  {
    field: 'whereUsed',
    title: 'Where Used',
    filterable: false,
    sortable: false
  },
];
export function uploadedQuoteGridDDL(features: any = []): GridHeaderData[] {
  return [
    {
      field: 'UnMapped',
      title: 'UnMapped',
      required: false,
      index: -1,
    },
    ...features?.map((c: any) => {
      return {
        index: c.index,
        mappedIndexes: c.index != null ? [c.index] : [],
        field: c.columnId,
        title: c.featureName,
        required: c?.required,
      };
    }),
  ];
}

export function uploadedQuoteGridColumns(
  dynamicColumns: any = []
): GridHeaderData[] { 
  return [
    {
      field: 'row',
      title: 'Row',
      filterable: false,
      sortable: false,
      reorderable: false,
      dropdown: false,
      getCellValue: (data: any) => {
        return data?.rowIndex + 1;
      },
    },
    ...dynamicColumns?.map((c: any) => {
      return {
        field: `col${c.columnIndex}`,
        title: c.value,
        filterable: false,
        sortable: false,
        reorderable: false,
        dropdown: true,
        getCellValue: (data: any, column: any, columnIndex: any) => {
          if (data && data.cells && columnIndex) {
            return data.cells.find(
              (c: any) => c.columnIndex === columnIndex - 1
            )?.value;
          }
        },
        getSelectedValue: (data: any, columnIndex: any) => {
          if (data && columnIndex) {
            const value = data.find((c: any) => c.index === columnIndex - 1)
              ? data.find((c: any) => c.index === columnIndex - 1)
              : data.find((c: any) => c.index === -1);
            return value;
          }
        },
      };
    }),
  ];  
}
