export enum actionType {
    ADMIN = 'admin',
    SUPPLIER = 'supplier'
}

export interface IGenerateLoa {
    LoaID :number[],
    Shared :boolean,
    Tempelete:string
}



export interface LoaUpdateStatusDTO {
    loaUpdateDTOs: LoaUpdateDTO[];
}

export interface LoaUpdateDTO {
    manId: number;
    loaId: number;
    supplierId: number;
    partId: number;
}


export interface ExportLoaFileDTO {
    supplierId: number;
    loaId: number;
    manId: number;
    fileName: string;
}