import { IAppState, IGridResponse } from '../../../shared/shared.interface';
import { ApiService } from '../../api/api.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PartSearchResponse } from './part-search.interfaces';
import { Store } from '@ngrx/store';
import { PartNumberSearchSelector } from 'src/app/features/parts/stores/parts.selector';
import { HttpClient } from '@angular/common/http';
import { ServiceMiddleware } from '../../Admin/service.layer/api.services.middleware';

@Injectable({
  providedIn: 'root',
})
export class PartSearchService {
  searchText: string = '';

  constructor(
    private apiService: ApiService,
    private store: Store<IAppState>,
    private _http: HttpClient,
    private serviceMiddleware: ServiceMiddleware
  ) {
    this.store.select(PartNumberSearchSelector).subscribe((data) => {
      this.searchText = data;
    });
  }

  public getPartSearchList(page: number = 0, size?: number) {
    page += 1;
    return this.apiService.get<IGridResponse<PartSearchResponse>>(
      `${environment.baseUrls.serviceURL}/cmt/clients/classicKeywordSearch?partNumber=${this.searchText}&pageNumber=${page}&pageSize=${size}`
    );
  }

  public exportList() {
    return this.serviceMiddleware.exportList(
      `/cmt/clients/exportClassicKeywordSearch?partNumber=${this.searchText}`
    );
  }
}
