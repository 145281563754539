import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { IAlertDetails, IAlertDetailsData, IAlerts, IPriority } from './alerts.interfaces';
export function flatAlertSearchData(data: IAlertDetails[]): IAlertDetailsData[] {
    let flattedData: IAlertDetailsData[] = [];
    data?.forEach((element) => {
        var elem: IAlertDetailsData = {
            status: element?.status,
            asm: element?.asm,
            currentManufacturer: element?.currentManufacturer,
            cpn: element?.cpn,
            alertPartListId: element?.alertPartListId,
            leadTimeDiff: element?.leadTimeDiff,
            manufacturerPn: element?.manufacturerPn,
            newLeadTimeAvg: element?.newLeadTimeAvg,
            priority: element?.priority?.alertPriorityName,
            newPrice: element?.newPrice,
            newQuantity: element?.newQuantity,
            priceDiff: element?.priceDiff,
            quantityDiff: element?.quantityDiff,
            snappedLeadTimeAvg: element?.snappedLeadTimeAvg,
            snappedPrice: element?.snappedPrice,
            snappedQuantity: element?.snappedQuantity,
        };
        flattedData.push(elem);
    });
    return flattedData;
}



export function mapP5AlertsSort(sort?: SortDescriptor[]) {
    var kendoSort = _.isArray(sort) ? sort[0] : sort;
    var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';

    switch (kendoSort?.field) {
        case 'p5AlertStatusName':
            return { columnName: 'p5AlertStatus.p5AlertStatusName', direction: dir };
        case 'Alert Type':
            return {
                columnName: 'p5AlertCategory.p5AlertCategoryName',
                direction: dir,
            };
        default:
            return { columnName: kendoSort?.field, direction: dir };
    }
}
export function mapCmtAlertsSort(sort?: SortDescriptor[]) {
    var kendoSort = _.isArray(sort) ? sort[0] : sort;
    var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc'
    if (!kendoSort?.field) {
        return { columnName: "status", direction: "asc" };
    }
    else {
        return { columnName: kendoSort?.field, direction: dir };
    }
}
export function mapAlertsFilter(gridFilters: any) {
    var kendoFilter = _.isArray(gridFilters) ? gridFilters[0] : gridFilters;
    var kendoFilters = _.isArray(kendoFilter?.filters)
        ? kendoFilter?.filters[0]
        : kendoFilter?.filters;
    if (!kendoFilters?.field) {
        return [];
    }
    switch (kendoFilters?.field) {
        case 'p5AlertStatusName':
            return [
                {
                    columnName: 'p5AlertStatus.p5AlertStatusName',
                    value: kendoFilters?.value,
                },
            ];
        case 'Alert Type':
            return [
                {
                    columnName: 'p5AlertCategory.p5AlertCategoryName',
                    value: kendoFilters?.value,
                },
            ];
       
        default:
            return [
                {
                    columnName: kendoFilters?.field,
                    value: kendoFilters?.value,
                },
            ];
    }
}
