<kendo-dialog *ngIf="!isClosed" (close)="closeDialog()">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em">
      <span class="k-icon k-i-warning"></span> {{ type }}
    </div>
  </kendo-dialog-titlebar>
  <p style="margin: 30px; text-align: center">
    {{ message }}
  </p>

  <kendo-dialog-actions [layout]="actionsLayout">
    <app-button-component
      *ngFor="let item of buttons"
      [buttonType]="'button'"
      [value]="item.value"
      [themeColor]="item.color || 'primary'"
      (clickButtonEvent)="clickButton(item)"
    >
    </app-button-component>
  </kendo-dialog-actions>
</kendo-dialog>
