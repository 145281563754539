import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { buttonInfo, DialogType } from "../shared.interface";

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _dialogData = new BehaviorSubject<any>('initial');

  public dialogData = this._dialogData.asObservable();

  constructor() {}

  public display(type: DialogType, message: string | null, buttons: buttonInfo[]): void {
    this._dialogData.next({ 
        type: type, 
        message: message,
        buttons: buttons
    });
  }
}
