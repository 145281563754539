import { UserEffects } from 'src/app/core/user/store/user.effects';
import { AclEffects } from 'src/app/features/acl/stores/acl.effect';
import { ReprtsEffects } from 'src/app/features/Reports/stores/report.effect';
import { AdminEffects } from 'src/app/features/admin/stores/admin.effect';
import { PartsEffects } from 'src/app/features/parts/stores/parts.effect';
import { UploadEffects } from 'src/app/features/upload/stores/upload.effect';
import { QuoteEffects } from 'src/app/features/admin/pages/quotes/stores/quotes.effect';
import { EngineerEffects } from 'src/app/features/engineer/stores/engineer.effect';

export const effects = [
  UserEffects,
  AdminEffects,
  UploadEffects,
  PartsEffects,
  AclEffects,
  ReprtsEffects,
  QuoteEffects,
  EngineerEffects
];
