import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { ISortModel } from 'src/app/shared/shared.interface';
import { IManufacturesFlatObject } from '../manufacturer/manufacturer.interfaces';
import {
  IDistributor,
  IDistributorsFlatObject,
} from './distributor.interfaces';

export function flatDisData(data: IDistributor[]): IDistributorsFlatObject[] {
  let flattedData: IDistributorsFlatObject[] = [];
  data?.forEach((element) => {
    var elem: IDistributorsFlatObject = {
      distId: element.distId,
      distName: element.distName,
      distTypeId: element.distributorType.distTypeId,
      distributorType: element.distributorType.typeName,
    };
    flattedData.push(elem);
  });
  return flattedData;
}

export function mapKendoSortToAPISort(sort?: SortDescriptor[]) {
  var kendoSort = _.isArray(sort) ? sort[0] : sort;
  var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';
  switch (kendoSort?.field) {
    case 'distributorType':
      return { columnName: 'distributorType.typeName', direction: dir };
    default:
      return {
        columnName: 'distName',
        direction: dir,
      };
  }
}

export function mapKendoFilterToAPI(gridFilters: any) {
  var kendoFilter = _.isArray(gridFilters) ? gridFilters[0] : gridFilters;
  var kendoFilters = _.isArray(kendoFilter?.filters)
    ? kendoFilter?.filters[0]
    : kendoFilter;
  switch (kendoFilters?.field) {
    case 'distributorType':
      return [{
        columnName: 'distributorType.typeName',
        value: kendoFilters?.value,
      }];
    case 'distName':
      return [{
        columnName: 'distName',
        value: kendoFilters?.value,
      }];
    default:
      return [];
  }
}
